import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select } from "@mui/material";
import MainCard from "../../UI/MainCard";
import NavigationPanel from "../../UI/NavigationPanel";
import { DEFAULT_THEME } from "../../utils/useThemes";
import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { t } from "i18next";
import { FREIGHT_AREAS, ROUTES } from "../../utils/constants";
import CustomDataGrid from "../../UI/CustomDataGrid";
import DeleteIcon from '@mui/icons-material/Delete';
import FreightAreaService from "../../services/FreightAreaService";
import { hidePopup, setMessageAndButtonText } from "../../redux/popup";

const styles = {
    subTitle: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    strongText: {
        fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
        fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
        fontSize: DEFAULT_THEME.typography.fontSize.regular
    },
    lightText: {
        fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
        fontWeight: DEFAULT_THEME.typography.fontWeight.regular,
        fontSize: DEFAULT_THEME.typography.fontSize.light
    },
    infoButtonIcon: {
        minHeight: '100%',
        minWidth: '100%'
    },
    bottomGrid: {
        mt: '30px'
    }
}


const OperationSettings = () => {

    const [panels,] = useState([t('adminPanel'), t('operationSettings')])
    const [freightArea, setFreightArea] = useState('')
    const [users, setUsers] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [params, setParams] = useState({ page: 0, limit: 8 })
    const dispatch = useDispatch()

    // initialize service
    const freightAreaService = new FreightAreaService()

    useEffect(() => {

        const updatedParams = { ...params, area: freightArea };
        setParams(updatedParams);
        getFreightAreas(updatedParams);

    }, [freightArea]);

    const getActionColumnDetail = (id) => {
        return (
            <Grid container item justifyContent={"space-evenly"}>
                <Grid item>
                    <IconButton
                        aria-label="delete"
                        title={t('delete')}
                        style={{
                            color: '#E45B5B'
                        }}
                        onClick={() => dispatch(setMessageAndButtonText({
                            'title': t("deleteTitle"),
                            'text': t("deleteText"),
                            'noButtonText': t("commonCancelButton"),
                            noButtonAction: () => {
                                dispatch(hidePopup())
                            },
                            'yesButtonText': t("commonDelete"),
                            yesButtonAction: () => {
                                deleteRow(id);
                                dispatch(hidePopup())
                            },
                            "isErrorMessage": false
                        }))}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Grid>
            </Grid>

        );
    };

    const deleteRow = async (id) => {
        const result = await freightAreaService.deleteFreightArea(id);

        if (result) {
            const newRows = users.filter((row) => row.id !== id);
            setUsers(newRows);
        }
    };

    const getFreightAreas = async (params) => {
        const result = await freightAreaService.getFreightAreas(params)

        if (result) {
            setUsers(result.data)
            setTotalCount(result.count)
        }
    }

    //örnek column oluşturma
    const columns = [
        {
            width: 80,
            renderCell: (params) => getActionColumnDetail(params.row.id)
        },
        {
            field: 'firstName',
            headerName: t('firstName2'),
            minWidth: 150,
            renderCell: (params) => params.row.responsible?.firstName
        },
        {
            field: 'lastName',
            headerName: t('lastName'),
            minWidth: 150,
            renderCell: (params) => params.row.responsible?.lastName
        },
        {
            field: 'email',
            headerName: 'Email',
            minWidth: 220,
            renderCell: (params) => params.row.responsible?.email
        },
        {
            field: 'type',
            headerName: 'Kullanıcı Tipi',
            minWidth: 220,
            renderCell: (params) => t(`${params.row.responsible?.type}`)
        },
        {
            field: 'freightArea',
            headerName: t('freightArea'),
            minWidth: 220,
            renderCell: (params) => t(`${params.row.area}`)
        },
    ];

    return (
        <MainCard isSingleCard={true}>

            <Grid container item xs={12} sx={{ ...styles.subTitle }} alignItems='center'>
                <NavigationPanel panels={panels}></NavigationPanel>
            </Grid>

            <Grid container item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: '30px' }}>
                <Grid item sx={{ pr: '20px', minWidth: '13rem' }}>
                    {/* FREIGHT AREA */}
                    <FormControl fullWidth>
                        <InputLabel id="freight-area-label">{t('freightArea')}</InputLabel>
                        <Select
                            value={freightArea}
                            label={t('freightArea')}
                            onChange={(e) => setFreightArea(e.target.value)}
                        >
                            {
                                Object.keys(FREIGHT_AREAS).map((area, index) => (
                                    <MenuItem key={index} value={area}>{t(FREIGHT_AREAS[area])}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <Grid item container sx={{ ...styles.bottomGrid }} justifyContent='space-between'>
                <Grid item xs={12}>
                    <CustomDataGrid
                        rows={users}
                        columns={columns}
                        showFilterBar={true}
                        titleOfTable={t('operationResponsibleList')}
                        params={params}
                    />
                </Grid>
            </Grid>
        </MainCard >

    );
}
export default OperationSettings;