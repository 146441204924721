//REACT
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";

//MUI
import { Grid, FormGroup } from '@mui/material';

//LIBRARY

import { DROP_DOWN_TYPES, RESPONSE_STATUSES, ROLES, ROUTES } from '../../utils/constants'
import MainCard from '../../UI/MainCard';
import { DEFAULT_THEME } from '../../utils/useThemes';
import CustomTextField from '../../UI/CustomTextField';
import YellowButton from '../../UI/YellowButton';
import { showError, showSuccess } from '../../redux/snackbar';
import UserService from '../../services/UserService';
import CompanyService from '../../services/CompanyService';
import CustomDropdown from '../../UI/CustomDropdown';
import NavigationPanel from '../../UI/NavigationPanel';
import { setUser } from '../../redux/user';
import { formatPhone, generateRandomString } from '../../utils/helper';

const styles = {
    subTitle: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    strongText: {
        fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
        fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
        fontSize: DEFAULT_THEME.typography.fontSize.regular
    },
    lightText: {
        fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
        fontWeight: DEFAULT_THEME.typography.fontWeight.regular,
        fontSize: DEFAULT_THEME.typography.fontSize.light
    },

}

const UserEdit = (props) => {

    const navigate = useNavigate();
    const { t } = useTranslation();

    const location = useLocation()
    const request = location.state?.request
    const [self, setSelf] = useState(location.state?.self)

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");

    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [type, setType] = useState("");
    const [id, setId] = useState("");
    const [selectedCompany, setSelectedCompany] = useState();
    const [companyList, setCompanyList] = useState([]);
    const [disabled, setDisabled] = useState(true);

    // just use for displaying a default password
    const passwordMasked = '********'
    const [password, setPassword] = useState(passwordMasked)
    const [passwordRepeat, setPasswordRepeat] = useState(passwordMasked)

    const dispatch = useDispatch();
    const userService = new UserService()
    const companyService = new CompanyService()
    const [panels, setPanels] = useState([t('adminPanel'), t('userMenu'), t('userEdit')])
    const currentUser = useSelector(state => state.user)

    useEffect(() => {
        fetchCompanies();
    }, [])

    useEffect(() => {
        getInfo();
    }, [request])

    useEffect(() => {
        areInputsValid()
    }, [firstName, lastName, phone, email, selectedCompany, type, password, passwordRepeat])

    const fetchCompanies = async (params) => {
        const result = await companyService.getCompanies(params)
        if (result) {
            setCompanyList(result.data)

            if (self) {
                const currentCompany = result.data.find(company => company.id === currentUser.companyId)
                setSelectedCompany({ id: currentCompany.id, title: currentCompany.name })
            }
        }
    }

    const getInfo = async () => {

        const firstName = request?.firstName
        if (firstName) {

            setFirstName(request?.firstName)
            setLastName(request?.lastName)
            setPhone(request?.phone)

            if (request?.company) {
                setSelectedCompany({ id: request?.company?.id, title: request?.company?.name })
            }

            // setSelectedCompany(company)
            setEmail(request?.email)
            setType(request?.type)
            setId(request?.id)

            // if the user selected himself/herself in the users table
            if (request?.id === currentUser.id) {
                setSelf(true)
            }
        }
        else {
            navigate(ROUTES.USERS_TABLE)
        }
    }

    const handleSubmit = async () => {

        if (!isPasswordValid()) return

        const user = {
            firstName,
            lastName,
            email,
            phone: formatPhone(phone.slice(1,)),
            type,
            companyId: selectedCompany.id,
            password,
        }

        // if the password is not changed, it is not sent to the service
        if (password === passwordMasked) delete user.password

        let response

        // service call 
        if (id === currentUser.id) {
            response = await userService.updateUserSelf(user)
            if (response.status === RESPONSE_STATUSES.success) {
                dispatch(setUser(response.data))
            }
        }
        else {
            response = await userService.updateUser(id, user)

            if (response.status === RESPONSE_STATUSES.success)
                navigate(ROUTES.USERS_TABLE)
        }
    }
    // checks if any of the inputs have changed
    const areInputsValid = () => {
        return (firstName !== request?.firstName ||
            lastName !== request?.lastName ||
            phone !== request?.phone ||
            email !== request?.email ||
            selectedCompany?.id !== (self ? currentUser.companyId : request?.company?.id) ||
            type !== request?.type ||
            ((password !== passwordMasked) && (passwordRepeat !== passwordMasked) && (password === passwordRepeat))
        )
    }

    const isPasswordValid = () => {
        // password is not changed
        if ((password === passwordMasked) && (passwordRepeat === passwordMasked)) {
            return true
            // password is changed
        } else if (password !== passwordRepeat || password.includes(passwordMasked) || passwordRepeat.includes(passwordMasked)) {
            dispatch(showError({ message: t("passwordsNotMatch") }));
            return false
            // password is changed but it is not valid
        } else if (password?.length < 6) {
            dispatch(showError({ message: t("passwordLength") }));
            return false
            // password is changed and it is valid
        } else return true
    }

    return (

        <MainCard isSingleCard={true}>
            {/* ********************************************************************** */}
            {/* NAVIGASYON PANEL */}
            {/* ********************************************************************** */}
            <Grid container item sx={{ ...styles.subTitle }} xs={12}>
                <NavigationPanel panels={panels}></NavigationPanel>
            </Grid>


            {/* ********************************************************************** */}
            {/* INPUT FIELD */}
            {/* ********************************************************************** */}
            <Grid container xs={12} sx={{ mt: '2.5rem', justifyContent: "center" }}>

                <FormGroup sx={{ width: 'stretch' }}>

                    {/* ********************************************************************** */}
                    {/* 1st ROW OF INPUT FIELD */}
                    {/* ********************************************************************** */}
                    <Grid container xs={12} direction="column" justifyContent="space-between" >

                        <Grid item container justifyContent="space-between">
                            <Grid item xs={5.9}>
                                <CustomTextField
                                    label={t('firstName2')} value={firstName} setValue={setFirstName} />
                            </Grid>

                            <Grid item xs={5.9}>
                                <CustomTextField
                                    label={t('lastName')} value={lastName} setValue={setLastName} />
                            </Grid>


                        </Grid>

                        <Grid item container justifyContent="space-between" sx={{ mt: '2rem' }}>
                            <Grid item xs={5.9}>
                                <CustomTextField
                                    label={t('phoneNumberLabel')} value={phone} type="tel" setValue={setPhone} />
                            </Grid>

                            <Grid item xs={5.9}>
                                <CustomTextField value={email} label={t('email')} setValue={setEmail} />
                            </Grid>
                        </Grid>



                        {/* ********************************************************************** */}
                        {/* 2nd ROW OF INPUT FIELD */}
                        {/* ********************************************************************** */}
                        <Grid item container justifyContent="space-between" sx={{ mt: '2rem' }}>
                            <Grid item xs={5.9}>
                                <CustomDropdown
                                    label={t('companyWorkFor')}
                                    placeholder={t('companyWorkFor')}
                                    value={{ id: selectedCompany?.id, title: selectedCompany?.title }}
                                    setValue={setSelectedCompany}
                                    data={companyList.map((company) => { return { id: company.id, title: company.name } })}
                                    disabled={!(currentUser.type === ROLES.AUTHORIZED_USER || currentUser.type === ROLES.SUPER_ADMIN)}
                                />
                            </Grid>

                            <Grid item xs={5.9}>
                                <CustomDropdown
                                    type={DROP_DOWN_TYPES.SELECT}
                                    label={t('userRole')}
                                    placeholder={t('userRole')}
                                    value={type}
                                    setValue={setType}
                                    data={Object.values(ROLES).map((role, i) => { return { id: i, title: t(`${role}`), value: role } })}
                                    disabled={!(currentUser.type === ROLES.AUTHORIZED_USER || currentUser.type === ROLES.SUPER_ADMIN)}
                                />
                            </Grid>


                        </Grid>

                        <Grid item container justifyContent="space-between" sx={{ mt: '2rem' }}>
                            <Grid item container justifyContent="space-between">
                                <Grid item xs={5.9}>
                                    <CustomTextField
                                        label={t('password')}
                                        value={password}
                                        setValue={setPassword}
                                        type="password"
                                        // if someone else is editing the user, the password field is disabled
                                        disabled={self === undefined}
                                    />
                                </Grid>

                                <Grid item xs={5.9}>
                                    <CustomTextField
                                        label={t('passwordRepeat')}
                                        value={passwordRepeat}
                                        setValue={setPasswordRepeat}
                                        type="password"
                                        // if someone else is editing the user, the password field is disabled
                                        disabled={self === undefined}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* ********************************************************************** */}
                        {/* BUTTON ROW*/}
                        {/* ********************************************************************** */}
                        <Grid container item sx={{ mt: '2rem' }}>
                            <Grid item xs={3} sx={{ mr: '1rem' }}>
                                <YellowButton
                                    type="submit"
                                    disabled={!areInputsValid()}
                                    onClick={() => handleSubmit()}
                                >
                                    {t('commonEdit')}
                                </YellowButton>


                            </Grid>

                        </Grid>
                    </Grid>

                </FormGroup>
            </Grid>


        </MainCard>
    )

}


export default UserEdit;