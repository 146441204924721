
const { createSlice } = require("@reduxjs/toolkit");


export const userSlice = createSlice({
    name: 'user',
    initialState: {
        id: "",
        phoneNumber: "",
        email: "",
        emailVerified: "",
    },
    reducers: {
        setUser: (state, action) => {
            return action.payload
        },
    }
})

export const { setUser } = userSlice.actions

export default userSlice.reducer