import { Button, Typography, Grid, Icon, useMediaQuery } from "@mui/material";
import { DEFAULT_THEME, useThemes } from "../utils/useThemes";
import { createSvgIcon } from '@mui/material/utils';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from "../utils/constants";





const CommonInfoButton = (props) => {
    const navigate = useNavigate();
    const getTheme = useThemes()
    const isLgExtra = useMediaQuery(getTheme().breakpoints.down('lg_laptop'));
    const isSmall = useMediaQuery(getTheme().breakpoints.down('sm'));
    const isPhone = useMediaQuery(getTheme().breakpoints.down('phone'));


    const { style, onClick } = props
    const styles = {
        cardButton: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems: 'center',
            borderWidth: '1px',
            borderRadius: '15px',
            borderStyle: 'solid',
            borderColor: '#EAEEF7',
            boxShadow: '0px 0px 23px 2px rgba(0, 0, 0, 0.05)',
            type: 'submit',
            width: isPhone ? '99%' : '100%',
            height: isLgExtra ? '140px' : '180px',
            textTransform: 'unset !important', //Problem of Uppercase buttons are originated from MUI Button Library, not i18next

            '&:hover': {
                backgroundColor: '#ccdbdc',
                // background: 'linear-gradient(to right, #2980b9, #1f6693)',
                // background: 'linear-gradient(to right, #fbd1a2, #fbd1a2)',

                color: 'black',
                cursor: 'pointer',
            }
        },
        lightText: {
            uppercase: false,
            fontSize: isSmall ? '11.5px' : isLgExtra ? '13px' : '15px',
        },
        icon: {
            height: isSmall ? 65 : isLgExtra ? 80 : 120,
            width: isSmall ? 45 : isLgExtra ? 50 : 72,
        },
        ...style
    }
    return (
        <Grid container>
            <Button onClick={() => onClick()} sx={{ ...styles.cardButton }}>
                <Grid container item direction="column" justifyContent="space-between" alignItems="center" alignContent="center">
                    <Grid item sx={{ ...styles.icon }}>{props.children}</Grid>
                    <Grid item>
                        <Typography sx={{ ...styles.lightText }}>{props.text}</Typography>
                    </Grid>
                </Grid>
            </Button>
        </Grid>

    )
}

export default CommonInfoButton;