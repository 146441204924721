//REACT
import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//MUI
import { Grid, FormGroup } from '@mui/material';

//LIBRARY
import { APPOINTMENT_STATUSES, DROP_DOWN_TYPES, FREIGHT_AREAS, FREIGHT_AREAS_ANG_GATES, ROLES, ROUTES } from '../../utils/constants'
import MainCard from '../../UI/MainCard';
import { DEFAULT_THEME } from '../../utils/useThemes';
import CustomTextField from '../../UI/CustomTextField';
import YellowButton from '../../UI/YellowButton';
import { showError, showSuccess } from '../../redux/snackbar';
import { modifyPlate } from "../../utils/modifyPlate";
import CustomDatePicker from "../../UI/CustomDatePicker";

//SERVICES
import AppointmentService from "../../services/AppointmentService";
import CompanyService from "../../services/CompanyService";
import NavigationPanel from "../../UI/NavigationPanel";
import dayjs from "dayjs";
import CustomDropdown from "../../UI/CustomDropdown";
import { removeSpaces } from "../../utils/helper";
const styles = {
    subTitle: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    strongText: {
        fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
        fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
        fontSize: DEFAULT_THEME.typography.fontSize.regular
    },
    lightText: {
        fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
        fontWeight: DEFAULT_THEME.typography.fontWeight.regular,
        fontSize: DEFAULT_THEME.typography.fontSize.light
    },
}

const LoadReservationEdit = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const user = useSelector(state => state.user)

    const appointmentService = new AppointmentService()
    const companyService = new CompanyService()

    const location = useLocation()

    const request = location?.state?.request
    const isApproval = location?.state?.isApproval

    const [appointmentId, setAppointmentId] = useState()
    const [phone, setPhone] = useState("");

    const [plate, setPlate] = useState("");
    const [truckPlate, setTruckPlate] = useState("");

    const [time, setTime] = useState("");
    const [arrivalTime, setArrivalTime] = useState("");
    const [entranceTime, setEntranceTime] = useState("");
    const [actualExitTime, setActualExitTime] = useState("");
    const [appointmentDate, setAppointmentDate] = useState("");
    const [route, setRoute] = useState("")
    const [gateNumber, setGateNumber] = useState("")
    const [description, setDescription] = useState("");

    const [freightArea, setFreightArea] = useState();

    const [selectedCompany, setSelectedCompany] = useState(null);
    const [companyList, setCompanyList] = useState([]);

    const [panels, setPanels] = useState([t('adminPanel'), t('freightOperations'), t('freightAppointments'), t('edit')])
    const dispatch = useDispatch();

    useEffect(() => {
        fetchCompanies()
    }, [])

    useEffect(() => {
        if (request && Object.keys(request).length > 0) {
            getInfo();
        } else {
            navigate(ROUTES.LOAD_RESERVATION_TABLE)
        }
    }, [request])

    const fetchCompanies = async (params) => {
        const result = await companyService.getCompanies(params)

        if (result) {
            setCompanyList(result.data)
        }
    }

    const handleSubmit = async () => {
        const appointmentUpdateObject = {
            // freight appointment fields
            trailerNumber: removeSpaces(truckPlate),
            plate: removeSpaces(plate),
            freightArea: freightArea?.title,
            companyId: selectedCompany.id,
            gateNumber,
            route,

            // common appointment fields
            appointmentDate: appointmentDate.format('YYYY-MM-DD'),
            appointmentTime: time,
            arrivalTime: arrivalTime,
            entranceTime: entranceTime,
            actualExitTime,
            description,

            // additional fields
            isApproval
        }

        // valid appointment update object
        if (appointmentId && appointmentUpdateObject) {
            if (!validateTimes())
                return
            const result = await appointmentService.updateAppointment(appointmentId, appointmentUpdateObject)

            if (result) {
                dispatch(showSuccess({ message: t("successMessage") }))
                navigate(ROUTES.LOAD_RESERVATION_TABLE)
            }
            else
                dispatch(showError({ message: t("errorMessageTitle") }))
        }
    }
    const validateTimes = () => {
        if (actualExitTime < entranceTime || entranceTime < arrivalTime || actualExitTime < time) {
            dispatch(showError({ message: t('timeErrorMessage') }))
            return false
        }
        else
            return true
    }

    const getInfo = () => {
        const companyDetails = request?.company
        const freightDetails = request?.freight

        setAppointmentId(request?.id)

        if (companyDetails) {

            setTime(request?.appointmentTime?.replace(".", ":"))
            setArrivalTime(request?.arrivalTime?.replace(".", ":"))
            setActualExitTime(request?.actualExitTime?.replace(".", ":"))
            setEntranceTime(request?.entranceTime?.replace(".", ":"))

            setAppointmentDate(dayjs(request?.appointmentDate, 'DD-MM-YYYY'))
            setSelectedCompany(companyDetails?.name)

            if (Object.values(FREIGHT_AREAS).includes(freightDetails?.freightArea)) {
                setFreightArea({ id: Object.values(FREIGHT_AREAS).indexOf(freightDetails?.freightArea), title: freightDetails?.freightArea })

                setGateNumber(freightDetails?.gateNumber)
            }

            setDescription(request?.description)
            setPhone(companyDetails?.phone)
            setPlate(freightDetails?.plate)
            setTruckPlate(freightDetails?.trailerNumber)
        }
        else {
            navigate(ROUTES.LOAD_RESERVATION_TABLE)
        }
    }

    return (

        <MainCard isSingleCard={true}>
            {/* ********************************************************************** */}
            {/* NAVIGASYON PANEL */}
            {/* ********************************************************************** */}
            <Grid container item sx={{ ...styles.subTitle }} xs={12}>
                <NavigationPanel panels={panels} ></NavigationPanel>
            </Grid>

            {/* ********************************************************************** */}
            {/* INPUT FIELD */}
            {/* ********************************************************************** */}
            <Grid container xs={12} sx={{ mt: '2.5rem', justifyContent: "center" }}>

                <FormGroup sx={{ width: 'stretch' }}>

                    <Grid container xs={12} direction="column" justifyContent="space-between" >
                        {
                            isApproval ? <>
                                <Grid item container justifyContent="space-between" sx={{ mt: '2rem' }}>
                                    <Grid item xs={3.9}>
                                        <CustomTextField
                                            label={t('companyWhichBook')}
                                            disabled={true}
                                            placeholder={t('companyWhichBook')}
                                            value={selectedCompany}
                                            setValue={setSelectedCompany} />
                                    </Grid>
                                    <Grid item xs={3.9}>
                                        <CustomTextField
                                            label={t('vehiclePlate')}
                                            placeholder={t('vehiclePlate')}
                                            disabled={true}
                                            value={modifyPlate(plate)}
                                            setValue={setPlate} />
                                    </Grid>
                                    <Grid item xs={3.9}>
                                        <CustomTextField
                                            label={t('appointmentTime')}
                                            value={time}
                                            setValue={setTime}
                                            type="time"
                                            disabled={true}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item container justifyContent="space-between" sx={{ mt: '2rem' }}>
                                    <Grid item xs={3.9}>
                                        <CustomDatePicker
                                            label={t('appointmentDate')}
                                            value={appointmentDate}
                                            disabled={true}
                                        />
                                    </Grid>
                                    <Grid item xs={3.9}>
                                        <CustomDropdown
                                            type={DROP_DOWN_TYPES.SELECT}
                                            label={t('gateNumber')}
                                            placeholder={t('gateNumber')}
                                            value={gateNumber}
                                            setValue={setGateNumber}
                                            data={Object.values(FREIGHT_AREAS_ANG_GATES[request?.freight?.freightArea]).map((item, i) => {
                                                return { id: i, title: item, value: item }
                                            })}
                                        />
                                    </Grid>
                                    <Grid item xs={3.9}>
                                        <CustomTextField
                                            label={t('route2')} value={route} setValue={setRoute} />
                                    </Grid>
                                </Grid>

                                <Grid item container sx={{ mt: '2rem' }} justifyContent={"center"}>
                                    <Grid item xs={2}>
                                        <YellowButton
                                            type="submit"
                                            onClick={() => handleSubmit()}
                                            disabled={!gateNumber}
                                        >
                                            {t('approve')}
                                        </YellowButton>
                                    </Grid>
                                </Grid>
                            </> : <>


                                {/* ********************************************************************** */}
                                {/* 1st ROW OF INPUT FIELD */}
                                {/* ********************************************************************** */}
                                <Grid item container justifyContent="space-between" sx={{ mt: '2rem' }}>
                                    <Grid item xs={3.9}>
                                        <CustomTextField
                                            label={t('companyWhichBook')} disabled={true} placeholder={t('companyWhichBook')} value={selectedCompany} setValue={setSelectedCompany} />

                                    </Grid>
                                    <Grid item xs={3.9}>
                                        <CustomTextField
                                            label={t('phoneNumberLabel')} type="tel" value={phone} setValue={setPhone} />
                                    </Grid>
                                    <Grid item xs={3.9}>
                                        <CustomTextField
                                            label={t('vehiclePlate')} placeholder={t('vehiclePlate')} disabled={false} value={modifyPlate(plate)} setValue={setPlate} />
                                    </Grid>

                                </Grid>

                                {/* ********************************************************************** */}
                                {/* 2nd ROW OF INPUT FIELD */}
                                {/* ********************************************************************** */}
                                <Grid item container justifyContent="space-between" sx={{ mt: '2rem' }}>
                                    <Grid item xs={3.9}>
                                        <CustomTextField
                                            label={t('truckPlateLabel')} placeholder={t('truckPlateLabel')} disabled={false} value={modifyPlate(truckPlate)} setValue={setTruckPlate} />
                                    </Grid>
                                    <Grid item xs={3.9}>
                                        <CustomTextField
                                            type={DROP_DOWN_TYPES.SELECT}
                                            label={t('freightArea')}
                                            value={t(`${request?.freight?.freightArea}`)}
                                            disabled={true}
                                        />
                                    </Grid>
                                    <Grid item xs={3.9}>
                                        <CustomTextField
                                            label={t('gateNumber')}
                                            value={request?.freight?.gateNumber}
                                            disabled={true}
                                        />
                                    </Grid>
                                </Grid>
                                {/* ********************************************************************** */}
                                {/* 3rd ROW OF INPUT FIELD */}
                                {/* ********************************************************************** */}
                                <Grid item container justifyContent="space-between" sx={{ mt: '2rem' }}>
                                    <Grid item xs={3.9}>
                                        <CustomTextField
                                            label={t('appointmentTime')}
                                            value={time}
                                            setValue={setTime}
                                            type="time"
                                            disabled={true}
                                        />
                                    </Grid>
                                    <Grid item xs={3.9}>
                                        <CustomTextField
                                            label={t('arrivalTime')}
                                            value={arrivalTime}
                                            setValue={setArrivalTime}
                                            type="time"
                                            disabled={!(user.type === ROLES.SUPER_ADMIN || user.type === ROLES.SECURITY_GUARD)}
                                        />
                                    </Grid>
                                    <Grid item xs={3.9}>
                                        <CustomTextField
                                            label={t('entranceTime')}
                                            value={entranceTime}
                                            setValue={setEntranceTime}
                                            type="time"
                                            disabled={!(user.type === ROLES.SUPER_ADMIN || user.type === ROLES.SECURITY_GUARD)}
                                        />
                                    </Grid>

                                </Grid>

                                {/* ********************************************************************** */}
                                {/* 4th ROW OF INPUT FIELD */}
                                {/* ********************************************************************** */}
                                <Grid item container justifyContent="space-between" sx={{ mt: '2rem' }}>
                                    <Grid item xs={5.9}>
                                        <CustomTextField
                                            label={t('exitTimeLabel')}
                                            // disabled={true}
                                            value={actualExitTime}
                                            setValue={setActualExitTime}
                                            type="time"
                                            disabled={!(user.type === ROLES.SUPER_ADMIN || user.type === ROLES.SECURITY_GUARD)}
                                        />
                                    </Grid>
                                    <Grid item xs={5.9}>
                                        <CustomDatePicker
                                            type={DROP_DOWN_TYPES.SELECT}
                                            label={t('appointmentDate')}
                                            value={appointmentDate}
                                            setValue={setAppointmentDate}
                                            disabled={true}
                                        />
                                    </Grid>
                                    <Grid item container sx={{ mt: '2rem', justifyContent: 'center' }}>
                                        <CustomTextField
                                            style={{
                                                [`& fieldset`]: {
                                                    borderRadius: 5,
                                                },
                                            }}
                                            label={t('description')}
                                            multiline
                                            rows={6}
                                            value={description}
                                            setValue={setDescription}
                                            placeholder={t('description')}
                                        />
                                    </Grid>


                                </Grid>

                                {/* ********************************************************************** */}
                                {/* BUTTON ROW*/}
                                {/* ********************************************************************** */}
                                <Grid item container sx={{ mt: '2rem' }} >
                                    <Grid item xs={4} >
                                        <YellowButton
                                            type="submit"
                                            onClick={() => handleSubmit()}
                                        >
                                            {t('commonEdit')}
                                        </YellowButton>


                                    </Grid>

                                </Grid>
                            </>
                        }
                    </Grid>
                </FormGroup>
            </Grid>
        </MainCard>
    )
}


export default LoadReservationEdit;