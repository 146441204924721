import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { useState } from "react";
import { DEFAULT_THEME, useThemes } from '../utils/useThemes';
import { useSelector } from "react-redux";
import NotificationBell from "./NotificationBell";
import { ROLES, ROUTES } from "../utils/constants";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";

const pages = ["Ana Sayfa", "Bize Ulaşın", "Hızlı Destek"];
const settings = ["Hesabım", "Yeni Bir Kullanıcı Oluştur", "Kullanıcı Ayarları", "Çıkış Yap"];

const styles = {
    subTitle: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    strongText: {
        fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
        fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
        fontSize: DEFAULT_THEME.typography.fontSize.regular
    },
    lightText: {
        fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
        fontWeight: DEFAULT_THEME.typography.fontWeight.regular,
        fontSize: DEFAULT_THEME.typography.fontSize.light
    },
    title1: {
        backgroundColor: 'black',
        borderBottomLeftRadius: 3,
        borderTopLeftRadius: 3,
        color: 'white',
        fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
        fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
        fontSize: DEFAULT_THEME.typography.fontSize.regular,
        padding: '2px 4px 2px 4px',
    },

    title2: {
        backgroundColor: DEFAULT_THEME.palette.super_primary,
        color: 'black',
        borderBottomRightRadius: 3,
        borderTopRightRadius: 3,
        fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
        fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
        fontSize: DEFAULT_THEME.typography.fontSize.regular,
        padding: '2px 4px 2px 4px',
    },
}
function MobileNavbar() {
    const [anchorElNav, setAnchorElNav] = useState("");
    const [anchorElUser, setAnchorElUser] = useState("");
    const darkMode = useSelector(state => state.darkMode)
    const user = useSelector(state => state.user)
    const navigate = useNavigate();


    const handleOpenNavMenu = (e) => {
        setAnchorElNav(e.currentTarget);
    };
    const handleOpenUserMenu = (e) => {
        setAnchorElUser(e.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const navigateTo = (page) => {
        if (page == "Ana Sayfa") {
            if (user.type === ROLES.SUPER_ADMIN) {
                navigate(ROUTES.USER_MENU)
            }
            else if (user.type === ROLES.AUTHORIZED_USER) {
                navigate(ROUTES.USER_MENU)
            }
            else if (user.type === ROLES.PARTNER_COMPANY_AUTHORIZED_USER) {
                navigate(ROUTES.STANDARD_USER_MENU)
            }
            else if (user.type === ROLES.STANDARD_USER) {
                navigate(ROUTES.STANDARD_USER_MENU)
            }
            else if (user.type === ROLES.SECURITY_GUARD) {
                navigate(ROUTES.SECURITY_MENU)
            }
            else if (user.type === ROLES.OPERATION_RESPONSIBLE) { //company user
                navigate(ROUTES.COMPANY_CARS_TRACKING)
            }
        }
        else if (page == "Bize Ulaşın") {
            if (user) navigate(ROUTES.CONTACT_US_PRIVATE)
            else navigate(ROUTES.CONTACT_US)
        }
        else {
            window.location.href = 'https://www.renault.com.tr/bize-ulasin.html';
        }

    };

    return (
        <AppBar position="static">
            <Container maxWidth="xl" sx={{ backgroundColor: darkMode ? '#1A1A1A' : 'white' }}>
                <Toolbar disableGutters>
                    <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none", color: darkMode ? "white" : "#8B8D91" } }}>
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon sx={{ width: '30px', height: '30px' }} />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: "block", md: "none" },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page} onClick={handleCloseNavMenu}>
                                    <Grid item onClick={() => navigateTo(page)}>
                                        <Typography textAlign="center">{page}</Typography>
                                    </Grid>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>

                    <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "row" }}>
                        <Typography sx={{ ...styles.title1 }}>YPDM</Typography>
                        <Typography sx={{ ...styles.title2 }}>RANDEVU</Typography>
                    </Box>

                    <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                        {pages.map((page) => (
                            <Button
                                key={page}
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: "white", display: "block" }}
                            >
                                {page}
                            </Button>
                        ))}
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar sx={{ height: '30px', width: '30px' }} alt="" src="/static/images/avatar/2.jpg" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: "45px" }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                    <Typography textAlign="center">{setting}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    {/* <Box sx={{ flexGrow: 0 }}>
                        <NotificationBell > </NotificationBell>
                    </Box> */}

                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default MobileNavbar;