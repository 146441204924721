import { Grid } from "@mui/material"
import CommonInfoButton from "./CommonInfoButton"
import { useNavigate } from "react-router-dom"


const Card = ({ field, styles }) => {
    const navigate = useNavigate();

    return (
        <Grid item xs={12} md={6} lg={4} sx={{ ...styles.card }}>
            <CommonInfoButton
                onClick={() => navigate(field?.navigate)}
                sx={{ ...styles.card }}
                children={field?.children}
                text={field?.text}
            />
        </Grid>
    )
}
export default Card