//REACT
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";

//MUI
import Grid from '@mui/material/Grid';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

//LIBRARY
import MainCard from '../UI/MainCard';
import dayjs from 'dayjs';

// SERVICE 
import AppointmentService from '../services/AppointmentService';
import { APPOINTMENT_STATUSES, APPOINTMENT_TYPES } from '../utils/constants';

const tableStyles = {
    tableHead: {
        backgroundColor: '#2980b9',
        fontSize: '45px',
        fontWeight: 'bold',
        textAlign: 'center',
        color: 'white',
        height: '10vh',
    },
    tableCell: {
        fontSize: '65px',
        textAlign: 'center',
        padding: '8px',
        fontWeight: 'bold',
    },
    tableRow: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#f5f5f5',
        },
    },
    tableContainer: {
        width: '100%',
        maxHeight: '80vh',
        overflow: 'auto',
    },
    table: {
        minWidth: '100%',
    },
};

const POLL_INTERVAL = 1 * 60 * 1000; // 30 minutes in milliseconds

const AppointmentBoard = () => {
    const { t } = useTranslation();
    const [appointments, setAppointments] = useState([]);
    const [visitAppointments, setVisitAppointments] = useState([]);
    const [freightAppointments, setFreightAppointments] = useState([]);

    const appointmentService = new AppointmentService();

    // Function to calculate startTime and endTime for the next 15 minutes
    const calculateTimeWindow = () => {
        const appointmentDate = dayjs();
        const startTime = appointmentDate.subtract(30, 'minute').format('YYYY-MM-DDTHH:mm:ss');
        const endTime = appointmentDate.add(30, 'minute').format('YYYY-MM-DDTHH:mm:ss');

        return { appointmentDate, startTime, endTime };
    };

    // Function to fetch appointments
    const fetchAppointments = async () => {

        const params = {
            ...calculateTimeWindow(),
            appointmentStatus: [APPOINTMENT_STATUSES.PENDING, APPOINTMENT_STATUSES.APPROVED]
        };

        try {
            const result = await appointmentService.getAppointments(params);

            const visitAppointments = [];
            const freightAppointments = [];

            result?.data.map(appointment => {
                appointment.appointmentType === APPOINTMENT_TYPES.VISIT ? visitAppointments.push(appointment) : freightAppointments.push(appointment);
            })

            setVisitAppointments(visitAppointments);
            setFreightAppointments(freightAppointments);

        } catch (error) {
            console.error('Error fetching appointments:', error);
        }
    };

    // Effect for initial fetch and setting up interval
    useEffect(() => {
        fetchAppointments(); // Fetch immediately on load

        const intervalId = setInterval(fetchAppointments, POLL_INTERVAL);

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    return (
        <Grid container justifyContent="center" alignItems="center" sx={{ overflow: 'auto' }}>

            <TableContainer component={Paper} sx={tableStyles.tableContainer}>
                <Table sx={tableStyles.table} aria-label="appointment table" size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell style={tableStyles.tableHead}>{t('plate')}</TableCell>
                            <TableCell style={tableStyles.tableHead}> {t('freightArea')} </TableCell>
                            <TableCell style={tableStyles.tableHead}> {t('gateNumber2')} </TableCell>
                            <TableCell style={tableStyles.tableHead}>{t('appointmentTime')}</TableCell>
                            <TableCell style={tableStyles.tableHead}>{t('appointmentStatus')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {
                            freightAppointments.length === 0 &&
                            <TableRow style={tableStyles.tableRow}>
                                <TableCell style={tableStyles.tableCell} colSpan={5}>{t('noWaitingAppointments')}</TableCell>
                            </TableRow>
                        }
                        {freightAppointments.map((appointment, index) => (
                            <TableRow key={index} style={tableStyles.tableRow}>
                                <TableCell style={tableStyles.tableCell}>{appointment?.freight?.plate}</TableCell>
                                <TableCell style={tableStyles.tableCell}>{t(`${appointment?.freight?.freightArea}`)}</TableCell>
                                <TableCell style={tableStyles.tableCell}>{appointment?.freight?.gateNumber}</TableCell>
                                <TableCell style={tableStyles.tableCell}>{dayjs(appointment?.appointmentTime, 'HH:mm:ss').format('HH:mm')}</TableCell>
                                <TableCell style={tableStyles.tableCell}>{t(`${appointment?.appointmentStatus}`)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>


            {
                visitAppointments.length > 0 &&
                <Grid container justifyContent="center" alignItems="center" sx={{ mt: '1vh', overflow: 'auto' }}>
                    <TableContainer component={Paper} sx={tableStyles.tableContainer}>
                        <Table sx={{ ...tableStyles.table, mt: '10vh' }} aria-label="appointment table" size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={tableStyles.tableHead}>{t('visitorFullName')}</TableCell>
                                    <TableCell style={tableStyles.tableHead}> {t('personToMeet')} </TableCell>
                                    <TableCell style={tableStyles.tableHead}>{t('appointmentReason')}</TableCell>
                                    <TableCell style={tableStyles.tableHead}>{t('appointmentTime')}</TableCell>
                                    {/* <TableCell style={tableStyles.tableHead}>{t('appointmentStatus')}</TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {visitAppointments.map((appointment, index) => (
                                    <TableRow key={index} style={tableStyles.tableRow}>
                                        <TableCell style={tableStyles.tableCell}>{appointment?.visit?.firstName + ' ' + appointment?.visit?.lastName}</TableCell>
                                        <TableCell style={tableStyles.tableCell}>{appointment?.visit?.user?.firstName + ' ' + appointment.visit?.user?.lastName}</TableCell>
                                        <TableCell style={tableStyles.tableCell}>{t(`${appointment?.appointmentReason}`)}</TableCell>
                                        <TableCell style={tableStyles.tableCell}>{dayjs(appointment?.appointmentTime, 'HH:mm:ss').format('HH:mm')}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            }
        </Grid>
    );
};

export default AppointmentBoard;