import { Button, Fade, Grid, IconButton, Tooltip, Typography } from "@mui/material";

const SideBarElement = ({ field, styles, onClick }) => {

    return (
        <>
            <Tooltip
                placement="right-start"
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                componentsProps={{
                    tooltip: {
                        sx: {
                            ...styles.tooltip
                        },
                    },
                }}
            >
                <IconButton sx={{ ...styles.button }} onClick={() => onClick()}>
                    <Grid item container direction="column" alignItems="center" justifyContent="space-between">
                        <Grid item container justifyContent="center" alignItems="center">
                            {field?.children}
                        </Grid>
                        <Grid item>
                            <Typography sx={{ ...styles.sidebarTitle, maxWidth: '3.5rem', overflow: 'hidden', fontSize: '0.6rem' }}>
                                {field?.text}
                            </Typography>
                        </Grid>
                    </Grid>
                </IconButton>
            </Tooltip >
        </>
    )

}
export default SideBarElement;