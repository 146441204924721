//REACT
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

//MUI
import { Grid, InputLabel, MenuItem, Select } from '@mui/material';

//LIBRARY
import MainCard from '../../UI/MainCard';
import CustomTextField from '../../UI/CustomTextField';
import YellowButton from '../../UI/YellowButton';
import CustomDropdown from '../../UI/CustomDropdown';

// Services
import UserService from '../../services/UserService';
import FreightAreaService from '../../services/FreightAreaService';

import { showError, showSuccess } from '../../redux/snackbar';
import NavigationPanel from '../../UI/NavigationPanel';
import { DROP_DOWN_TYPES, FREIGHT_AREAS, ROUTES } from '../../utils/constants';
import { FormControl } from 'react-bootstrap';


const styles = {
    subTitle: {
        flexDirection: 'row',
        alignItems: 'center',
    },
}

const AddOperationResponsible = () => {

    // initialize variables
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    // initialize services
    const freightAreaService = new FreightAreaService()
    const userService = new UserService()

    const [freightArea, setFreightArea] = useState('')

    const [users, setUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState(null)

    const [searchParams, setSearchParams] = useState({})
    const [searchParamsLength, setSearchParamsLength] = useState(0)

    const [disabled, setDisabled] = useState(true)

    const [panels,] = useState([t('adminPanel'), t('operationSettings'), t('addOperationResponsible')])

    useEffect(() => {

        if (searchParams && searchParamsLength >= 2) {
            const params = {
                search: true,
                query: searchParams
            }
            fetchUsers(params)

        }
        else if (searchParamsLength === 0) {
            fetchUsers()
        }
    }, [searchParamsLength, searchParams])

    useEffect(() => {
        if (freightArea && selectedUser) {
            setDisabled(false)
        }
        else {
            setDisabled(true)
        }
    }, [freightArea, selectedUser])

    // action function 
    const createFreightArea = async (appointment) => {

        const result = await freightAreaService.createFreightArea(appointment)

        if (result) {
            navigate(ROUTES.OPERATION_SETTINGS)
        }
        else
            dispatch(showError({ message: t("errorMessageTitle") }));
    }

    const fetchUsers = async (params) => {
        const result = await userService.getUsers(params)

        if (result) {
            setUsers(result.data)
        }
    }

    // input checks, submit action
    const onSubmitAppointment = async () => {

        if (!selectedUser) {
            dispatch(showError({ message: t("errorMessageTitle") }));
            return
        }

        const body = {
            area: freightArea,
            responsible: selectedUser.id
        }

        await createFreightArea(body)
    }

    return (

        <MainCard isSingleCard={true}>
            {/* ********************************************************************** */}
            {/* NAVIGASYON PANEL */}
            {/* ********************************************************************** */}
            <Grid container item sx={{ ...styles.subTitle }} xs={12}>
                <NavigationPanel panels={panels}></NavigationPanel>
            </Grid>




            {/* ********************************************************************** */}
            {/* INPUT FIELD */}
            {/* ********************************************************************** */}
            <Grid container xs={12} sx={{ mt: '2.5rem' }}>

                {/* ********************************************************************** */}
                {/* 1st ROW OF INPUT FIELD */}
                {/* ********************************************************************** */}
                <Grid container xs={8} direction="column" justifyContent="space-between" >

                    <Grid item container justifyContent="space-between">
                        <Grid item xs={3.9}>
                            <CustomDropdown
                                type={DROP_DOWN_TYPES.SELECT}
                                label={t('freightArea')}
                                placeholder={t('freightArea')}
                                value={freightArea}
                                setValue={setFreightArea}
                                data={Object.keys(FREIGHT_AREAS).map((area, i) => { return { id: i, title: t(`${area}`), value: area } })}
                            />

                        </Grid>

                        <Grid item xs={3.9}>
                            <CustomDropdown
                                size="small"
                                margin="normal"
                                data={
                                    users?.map(user => {
                                        return { title: user.firstName + ' ' + user.lastName, id: user.id }
                                    })
                                }
                                label={t('operationResponsible')}
                                value={selectedUser}
                                setValue={setSelectedUser}
                                searchParams={searchParams}
                                setSearchParams={setSearchParams}
                                setSearchParamsLength={setSearchParamsLength}
                                fetchFunction={fetchUsers}
                            />
                        </Grid>


                    </Grid>


                    {/* ********************************************************************** */}
                    {/* BUTTON */}
                    {/* ********************************************************************** */}
                    <Grid item container sx={{ mt: '2rem' }}>
                        <Grid item xs={4}>
                            <YellowButton
                                disabled={disabled}
                                type="submit"
                                onClick={() => onSubmitAppointment()}
                            >
                                {t('commonCreateButton')}
                            </YellowButton>
                        </Grid>




                    </Grid>
                </Grid>
            </Grid>


        </MainCard>
    )

}


export default AddOperationResponsible;