//REACT
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import * as React from 'react';
import { useState } from "react";
import { useSelector } from 'react-redux';
//MUI
import { Grid, Typography, useMediaQuery } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

//LIBRARY
import MainCard from '../../UI/MainCard';
import CustomDataGrid from '../../UI/CustomDataGrid';
import { ROUTES } from '../../utils/constants'
import BlackButton from '../../UI/BlackButton';
import { DEFAULT_THEME, useThemes } from '../../utils/useThemes';
import UserService from '../../services/UserService';
import NavigationPanel from '../../UI/NavigationPanel';
import { useEffect } from 'react';
import CommonEditButton from '../../UI/CommonEditButton';
import { displayPhone } from '../../utils/helper';
import CompanyService from '../../services/CompanyService';

const GetCompanies = (props) => {

    const navigate = useNavigate();
    const companyService = new CompanyService()
    const [companies, setCompanies] = useState([])
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(8);
    const [params, setParams] = useState({ page, limit });
    const darkMode = useSelector(state => state.darkMode)
    const getTheme = useThemes()
    const isPhone = useMediaQuery(getTheme().breakpoints.down('phone'));
    const { t } = useTranslation();
    const [totalCount, setTotalCount] = useState(0);
    const [panels, setPanels] = useState([t('adminPanel'), t('userSettings'), t('companyList')])


    const styles = {
        strongText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
            fontSize: DEFAULT_THEME.typography.fontSize.regular
        },
        lightText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.regular,
            fontSize: DEFAULT_THEME.typography.fontSize.light
        },
        subtitle: {
            backgroundColor: darkMode ? 'black' : 'white',
            backdropFilter: 'blur(12px)',
            alignItems: 'center',
            borderStyle: 'solid',
            borderColor: '#EAEEF7',
            height: isPhone ? '120px' : '85px',
            borderRadius: 10,
            p: 2,
        },
        bottomGrid: {
            p: 4,
            borderWidth: '1px',
            backgroundColor: darkMode ? 'black' : 'white',
            borderRadius: '30px',
            borderStyle: 'solid',
            backdropFilter: 'blur(12px)',
            justifyContent: 'space-around',
            width: 'stretch',
            borderColor: '#E3E5EA',
            mt: '20px',
        },
    }

    const GetCompanies = async (params) => {
        const result = await companyService.getCompanies(params)

        if (result) {
            setCompanies(result.data)
            setTotalCount(result.count)
        }
    }

    {/* function to allow us to do a operation for particular row in this case is a edit operation */ }
    const getActionColumnDetail = (object) => {
        return (
            <CommonEditButton route={ROUTES.USER_EDIT} disabled={false} state={object} />
        );
    };

    //örnek column oluşturma
    const columns = [
        // {
        //     width: 80,
        //     renderCell: (params) => getActionColumnDetail(params.row)
        // },
        {
            field: 'name',
            headerName: t('companyName'),
            minWidth: 350,
        },

        {
            field: 'phone',
            headerName: t('phoneNumberLabel'),
            width: 220,
        },
        {
            field: 'email',
            headerName: 'Email',
            minWidth: 220,
        },
    ];

    return (
        <MainCard>
            <Grid container item xs={12} sx={{ ...styles.subtitle }}>
                <NavigationPanel panels={panels}></NavigationPanel>
            </Grid>

            <Grid item container sx={{ ...styles.bottomGrid }} justifyContent='space-between'>
                <Grid item xs={12}>
                    <CustomDataGrid
                        rows={companies}
                        columns={columns}
                        showFilterBar={true}
                        titleOfTable={t('companyList')}
                        getFunction={GetCompanies}
                        totalCount={totalCount}
                        params={params}

                    />
                </Grid>
            </Grid>
        </MainCard>

    );
}

export default GetCompanies;