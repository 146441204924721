import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import Person2Icon from '@mui/icons-material/Person2';

import LogoutIcon from '@mui/icons-material/Logout';
import GridViewIcon from '@mui/icons-material/GridView';
import { Grid, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROLES, ROUTES } from '../utils/constants'
import { DEFAULT_THEME, useThemes } from "../utils/useThemes";

import { useRef } from 'react';
import { IconButton, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import React, { useState, useEffect } from 'react';
import Sun from '@mui/icons-material/WbSunny';
import Moon from '@mui/icons-material/DarkMode';
import eventBus from '../utils/EventBus';
import { setDarkMode } from '../redux/darkMode';

import { useDispatch, useSelector } from "react-redux";
import { setUser } from '../redux/user';
import { LocalShippingOutlined } from '@mui/icons-material';


const SideBarMobile = () => {
    const navigate = useNavigate();
    const getTheme = useThemes()
    const dispatch = useDispatch();

    const isPhone = useMediaQuery(getTheme().breakpoints.down('phone'));

    const currentMode = useSelector(state => state.darkMode)

    const [darkModeChecked, setDarkModeChecked] = useState(currentMode);
    const currentUser = useSelector(state => state.user)
    // const [currentUser, setCurrentUser] = useState('')

    // useEffect(() => {
    //     const user = localStorage.getItem('user')
    //     const data = JSON.parse(user)
    //     setCurrentUser(data)
    // }, [])

    const styles = {
        sidebar: {
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            borderRadius: 7,
            backgroundColor: currentMode ? 'black' : 'white',
            borderStyle: 'solid',
            minWidth: '370px',
            minHeight: 65,
            borderColor: '#EAEEF7',
            mt: '0.2rem'

        },
        button: {
            minWidth: 20,
            minHeight: 15,
            backgroundColor: currentMode ? DEFAULT_THEME.palette.sideBarDark : DEFAULT_THEME.palette.sideBar,
            borderRadius: 3,
            '&:hover': {
                backgroundColor: '#EFDF00',
                color: 'black',
                cursor: 'pointer',
            },
        },
        icon: {
            color: !currentMode ? '#000000' : '',
        },
        iconButton: {
            minWidth: 15,
            minHeight: 10,
            backgroundColor: currentMode ? DEFAULT_THEME.palette.sideBarDark : DEFAULT_THEME.palette.sideBar,
            borderRadius: 3,
            '&:hover': {
                backgroundColor: '#EFDF00',
                color: 'black',
                cursor: 'pointer',
            },
        },
        infoText: {
            fontSize: 8, width: 15, color: !currentMode ? '#000000' : '',
            mb: '2px', mt: '5px', fontWeight: 'bold'
        },
        themeButton: {
            backgroundColor: currentMode ? DEFAULT_THEME.palette.sideBarDark : DEFAULT_THEME.palette.sideBar,
            color: !currentMode ? 'black' : '',
            borderRadius: 10,
            minHeight: 30,
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            minWidth: 30,
            '&:hover': {
                backgroundColor: '#EFDF00',
                color: 'black'
            },
        }
    }
    const logOut = () => {
        dispatch(setUser(""))
        navigate(ROUTES.INDEX)
    }

    const SecuritySideBar = () => {
        return <Grid container item sx={{ ...styles.sidebar }} justifyContent='center' alignItems='center' alignContent='center'>

            <IconButton sx={{ ...styles.button }} onClick={() => navigate(ROUTES.SECURITY_MENU)}>
                <Grid item container direction="column" alignItems="center" alignContent="center" justifyContent="space-around">
                    <GridViewIcon sx={{ ...styles.icon }} />
                </Grid>
            </IconButton>

            <IconButton sx={{ ...styles.button }} onClick={() => navigate(ROUTES.REPORTING_MENU)}>
                <Grid item container direction="column" alignItems="center" justifyContent="space-between">
                    <CalendarMonthIcon sx={{ ...styles.icon }} />
                </Grid>
            </IconButton>

            <IconButton sx={{ ...styles.button }} onClick={() => navigate(ROUTES.VISITING_OPERATIONS_MENU)}>
                <Grid item container direction="column" alignItems="center" justifyContent="space-between">
                    <EventAvailableIcon sx={{ ...styles.icon }} />
                </Grid>
            </IconButton>
            <IconButton sx={{ ...styles.iconButton }} onClick={() => logOut()}>
                <Grid item container direction="column" alignItems="center" justifyContent="space-between">
                    <LogoutIcon sx={{ ...styles.icon }} />
                </Grid>
            </IconButton>
            <Grid >
                <IconButton color="secondary" sx={{ ...styles.themeButton }}
                    onClick={
                        (c) => {
                            eventBus.dispatch("darkMode", { isDarkMode: !darkModeChecked })
                            dispatch(setDarkMode(!darkModeChecked))
                            setDarkModeChecked(!darkModeChecked)
                        }
                    } >
                    {darkModeChecked ? (<Sun style={{ verticalAlign: 'middle' }} />)
                        :
                        <Moon style={{ verticalAlign: 'middle' }} />
                    }
                </IconButton>
            </Grid>
        </Grid >
    }

    const SuperAdminSideBar = () => {
        return <Grid container item sx={{ ...styles.sidebar }} justifyContent='center' alignItems='center' alignContent='center'>

            <IconButton sx={{ ...styles.button }} onClick={() => navigate(ROUTES.USER_MENU)}>
                <Grid item container direction="column" alignItems="center" alignContent="center" justifyContent="space-around">
                    <GridViewIcon sx={{ ...styles.icon }} />
                </Grid>
            </IconButton>

            <IconButton sx={{ ...styles.button }} onClick={() => navigate(ROUTES.SECURITY_MENU)}>
                <Grid item container direction="column" alignItems="center" justifyContent="space-around">
                    <PlagiarismIcon sx={{ ...styles.icon }} />
                </Grid>
            </IconButton>

            <IconButton sx={{ ...styles.button }} onClick={() => navigate(ROUTES.REPORTING_MENU)}>
                <Grid item container direction="column" alignItems="center" justifyContent="space-between">
                    <CalendarMonthIcon sx={{ ...styles.icon }} />
                </Grid>
            </IconButton>

            <IconButton sx={{ ...styles.button }} onClick={() => navigate(ROUTES.VISITING_OPERATIONS_MENU)}>
                <Grid item container direction="column" alignItems="center" justifyContent="space-between">
                    <EventAvailableIcon sx={{ ...styles.icon }} />
                </Grid>
            </IconButton>

            <IconButton sx={{ ...styles.button }} onClick={() => navigate(ROUTES.FREIGHT_HANDLING_MENU)}>
                <Grid item container direction="column" alignItems="center" justifyContent="space-between">
                    <Person2Icon sx={{ ...styles.icon }} />
                </Grid>
            </IconButton>

            <IconButton sx={{ ...styles.iconButton }} onClick={() => logOut()}>
                <Grid item container direction="column" alignItems="center" justifyContent="space-between">
                    <LogoutIcon sx={{ ...styles.icon }} />
                </Grid>
            </IconButton>
            <Grid >
                <IconButton color="secondary" sx={{ ...styles.themeButton }}
                    onClick={
                        (c) => {
                            eventBus.dispatch("darkMode", { isDarkMode: !darkModeChecked })
                            dispatch(setDarkMode(!darkModeChecked))
                            setDarkModeChecked(!darkModeChecked)
                        }
                    } >
                    {darkModeChecked ? (<Sun style={{ verticalAlign: 'middle' }} />)
                        :
                        <Moon style={{ verticalAlign: 'middle' }} />
                    }
                </IconButton>
            </Grid>
        </Grid >
    }
    const CompanyAdminSideBar = () => {
        return <Grid container item sx={{ ...styles.sidebar }} justifyContent='center' alignItems='center' alignContent='center'>

            <IconButton sx={{ ...styles.button }} onClick={() => navigate(ROUTES.STANDARD_USER_MENU)}>
                <Grid item container direction="column" alignItems="center" alignContent="center" justifyContent="space-around">
                    <GridViewIcon sx={{ ...styles.icon }} />
                </Grid>
            </IconButton>

            <IconButton sx={{ ...styles.button }} onClick={() => navigate(ROUTES.REPORTING_MENU)}>
                <Grid item container direction="column" alignItems="center" justifyContent="space-between">
                    <CalendarMonthIcon sx={{ ...styles.icon }} />
                </Grid>
            </IconButton>

            <IconButton sx={{ ...styles.button }} onClick={() => navigate(ROUTES.VISITING_OPERATIONS_MENU)}>
                <Grid item container direction="column" alignItems="center" justifyContent="space-between">
                    <EventAvailableIcon sx={{ ...styles.icon }} />
                </Grid>
            </IconButton>

            <IconButton sx={{ ...styles.button }} onClick={() => navigate(ROUTES.FREIGHT_HANDLING_MENU)}>
                <Grid item container direction="column" alignItems="center" justifyContent="space-between">
                    <LocalShippingOutlined sx={{ ...styles.icon }} />
                </Grid>
            </IconButton>
            <IconButton sx={{ ...styles.iconButton }} onClick={() => logOut()}>
                <Grid item container direction="column" alignItems="center" justifyContent="space-between">
                    <LogoutIcon sx={{ ...styles.icon }} />
                </Grid>
            </IconButton>
            <Grid >
                <IconButton color="secondary" sx={{ ...styles.themeButton }}
                    onClick={
                        (c) => {
                            eventBus.dispatch("darkMode", { isDarkMode: !darkModeChecked })
                            dispatch(setDarkMode(!darkModeChecked))
                            setDarkModeChecked(!darkModeChecked)
                        }
                    } >
                    {darkModeChecked ? (<Sun style={{ verticalAlign: 'middle' }} />)
                        :
                        <Moon style={{ verticalAlign: 'middle' }} />
                    }
                </IconButton>
            </Grid>
        </Grid >
    }
    const StandardUserSideBar = () => {
        return <Grid container item sx={{ ...styles.sidebar }} justifyContent='center' alignItems='center' alignContent='center'>

            <IconButton sx={{ ...styles.button }} onClick={() => navigate(ROUTES.STANDARD_USER_MENU)}>
                <Grid item container direction="column" alignItems="center" alignContent="center" justifyContent="space-around">
                    <GridViewIcon sx={{ ...styles.icon }} />
                </Grid>
            </IconButton>

            <IconButton sx={{ ...styles.button }} onClick={() => navigate(ROUTES.REPORTING_MENU)}>
                <Grid item container direction="column" alignItems="center" justifyContent="space-between">
                    <CalendarMonthIcon sx={{ ...styles.icon }} />
                </Grid>
            </IconButton>

            <IconButton sx={{ ...styles.button }} onClick={() => navigate(ROUTES.VISITING_OPERATIONS_MENU)}>
                <Grid item container direction="column" alignItems="center" justifyContent="space-between">
                    <EventAvailableIcon sx={{ ...styles.icon }} />
                </Grid>
            </IconButton>

            <IconButton sx={{ ...styles.button }} onClick={() => navigate(ROUTES.STANDARD_USER_SETTINGS)}>
                <Grid item container direction="column" alignItems="center" justifyContent="space-between">
                    <Person2Icon sx={{ ...styles.icon }} />
                </Grid>
            </IconButton>
            <IconButton sx={{ ...styles.iconButton }} onClick={() => logOut()}>
                <Grid item container direction="column" alignItems="center" justifyContent="space-between">
                    <LogoutIcon sx={{ ...styles.icon }} />
                </Grid>
            </IconButton>
            <Grid >
                <IconButton color="secondary" sx={{ ...styles.themeButton }}
                    onClick={
                        (c) => {
                            eventBus.dispatch("darkMode", { isDarkMode: !darkModeChecked })
                            dispatch(setDarkMode(!darkModeChecked))
                            setDarkModeChecked(!darkModeChecked)
                        }
                    } >
                    {darkModeChecked ? (<Sun style={{ verticalAlign: 'middle' }} />)
                        :
                        <Moon style={{ verticalAlign: 'middle' }} />
                    }
                </IconButton>
            </Grid>
        </Grid >
    }

    const AuthUserSideBar = () => {
        return <Grid container item sx={{ ...styles.sidebar }} justifyContent='center' alignItems='center' alignContent='center'>

            <IconButton sx={{ ...styles.button }} onClick={() => navigate(ROUTES.USER_MENU)}>
                <Grid item container direction="column" alignItems="center" alignContent="center" justifyContent="space-around">
                    <GridViewIcon sx={{ ...styles.icon }} />
                </Grid>
            </IconButton>

            <IconButton sx={{ ...styles.button }} onClick={() => navigate(ROUTES.USER_SETTINGS)}>
                <Grid item container direction="column" alignItems="center" justifyContent="space-around">
                    <PlagiarismIcon sx={{ ...styles.icon }} />
                </Grid>
            </IconButton>

            <IconButton sx={{ ...styles.button }} onClick={() => navigate(ROUTES.REPORTING_MENU)}>
                <Grid item container direction="column" alignItems="center" justifyContent="space-between">
                    <CalendarMonthIcon sx={{ ...styles.icon }} />
                </Grid>
            </IconButton>

            <IconButton sx={{ ...styles.button }} onClick={() => navigate(ROUTES.VISITING_OPERATIONS_MENU)}>
                <Grid item container direction="column" alignItems="center" justifyContent="space-between">
                    <EventAvailableIcon sx={{ ...styles.icon }} />
                </Grid>
            </IconButton>
            <IconButton sx={{ ...styles.iconButton }} onClick={() => logOut()}>
                <Grid item container direction="column" alignItems="center" justifyContent="space-between">
                    <LogoutIcon sx={{ ...styles.icon }} />
                </Grid>
            </IconButton>
            <Grid >
                <IconButton color="secondary" sx={{ ...styles.themeButton }}
                    onClick={
                        (c) => {
                            eventBus.dispatch("darkMode", { isDarkMode: !darkModeChecked })
                            dispatch(setDarkMode(!darkModeChecked))
                            setDarkModeChecked(!darkModeChecked)
                        }
                    } >
                    {darkModeChecked ? (<Sun style={{ verticalAlign: 'middle' }} />)
                        :
                        <Moon style={{ verticalAlign: 'middle' }} />
                    }
                </IconButton>
            </Grid>
        </Grid >
    }
    return (
        currentUser.type === ROLES.SUPER_ADMIN ? SuperAdminSideBar() :
            currentUser.type === ROLES.SECURITY_GUARD ? SecuritySideBar() :
                currentUser.type === ROLES.AUTHORIZED_USER ? AuthUserSideBar() :
                    currentUser.type === ROLES.STANDARD_USER ? StandardUserSideBar() :
                        currentUser.type === ROLES.PARTNER_COMPANY_AUTHORIZED_USER ? CompanyAdminSideBar() :
                            currentUser.type === ROLES.OPERATION_RESPONSIBLE ? CompanyAdminSideBar() :

                                SuperAdminSideBar()
    );

}

export default SideBarMobile;

