//REACT
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

//MUI
import Grid from '@mui/material/Grid';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';

//LIBRARY
import CustomTextField from '../UI/CustomTextField';
import YellowButton from '../UI/YellowButton';
import MainCard from '../UI/MainCard';
import { DEFAULT_THEME } from '../utils/useThemes';
import { setMessageAndButtonText } from "../redux/popup"
import { ROUTES } from '../utils/constants';
import { formatPhone } from '../utils/helper'

// SERVICE 
import AppointmentService from '../services/AppointmentService';


import { hidePopup } from '../redux/popup';

const styles = {
    strongText: {
        fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
        fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
        fontSize: DEFAULT_THEME.typography.fontSize.regular
    },
    lightText: {
        fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
        fontWeight: DEFAULT_THEME.typography.fontWeight.regular,
        fontSize: DEFAULT_THEME.typography.fontSize.light
    },
    textfield: {
        [`& fieldset`]: {
            borderRadius: 30
        },

    },
    textfieldDescription: {
        [`& fieldset`]: {
            borderRadius: 5
        }
    }
}

const GuestReservation = () => {

    const { t } = useTranslation();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [fullNameOfThePersonToMeet, setFullNameOfThePersonToMeet] = useState("");
    const [appointmentReason, setAppointmentReason] = useState("");
    const [appointmentDate, setAppointmentDate] = useState("");
    const [appointmentTime, setAppointmentTime] = useState("");
    const [description, setDescription] = useState("");
    const [checked, setChecked] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const currentUser = useSelector(state => state.user)
    // initialize services
    const appointmentService = new AppointmentService()

    const navigateHome = async () => {
        dispatch(hidePopup())
        navigate(ROUTES.INDEX)
    }

    useEffect(() => {
        console.log(currentUser)
    }, [])

    const closeButtonClick = () => {
        dispatch(hidePopup())
    }

    const handleSubmit = async () => {
        const guestVisitAppointmentObj = {
            firstName,
            lastName,
            phoneNumber: formatPhone(phoneNumber),
            fullNameOfThePersonToMeet,
            appointmentDate,
            appointmentTime,
            appointmentReason,
            description
        }

        // validation for input fields, see validateAppointmentObject method. 
        // It might be refactored in the input level.
        if (validateAppointmentObject(guestVisitAppointmentObj) && checked) {
            // send the api request to create the appointment
            const resultData = await appointmentService.createGuestAppointment(guestVisitAppointmentObj)

            // success case 
            if (resultData.data && resultData.success) {
                // extract the appointment and user objects from the response
                const createdAppointment = resultData.data
                const personToMeet = createdAppointment.visit?.user
                dispatch(setMessageAndButtonText({
                    title: 'Randevunuz başarıyla alınmıştır.',
                    text: `
                            ${personToMeet.firstName} ${personToMeet.lastName} ile ${createdAppointment.appointmentDate} tarihli randevunuz oluşturulmuştur.\n
                            Randevunuz onaylandığında SMS ile bilgilendirileceksiniz.
                        `,
                    successButtonText: "Tamam",//t("commonOkButton"),
                    successButtonAction: () => { navigateHome() },
                    isSuccessMessage: true
                }))
            }

            // error case 
            if (resultData && resultData.error) {
                dispatch(setMessageAndButtonText({
                    title: 'Randevu Oluşturulamadı!',
                    text: `${resultData.error.message}`,
                    noButtonText: t("commonOkButton"),
                    noButtonAction: () => { closeButtonClick() },
                    isErrorMessage: true
                }))
            }
        }
        else
            dispatch(setMessageAndButtonText({
                title: 'Lütfen alanları doldurunuz.',
                text: 'Randevu oluşturmak için gerekli alanları doldurmanız gerekmektedir.',
                noButtonText: t("commonOkButton"),
                noButtonAction: () => { closeButtonClick() },
                isErrorMessage: true
            }))
    };

    const handleClick = () => {
        //navigate(ROUTES.CLARIFICATION_TEXT)
        window.open("https://www.renault.com.tr/kisisel-verilerin-korunmasi.html");
    };

    /**
     * @param {*} guestVisitAppointmentObj // appointment object consist of all the inputs in the page
     * @returns {true} if all the input fields are filled except description. It might be empty
     * @returns {false} otherwise
     */
    const validateAppointmentObject = (guestVisitAppointmentObj) => {
        return Object.keys(guestVisitAppointmentObj).every(key => {
            if (!guestVisitAppointmentObj[key] && key !== 'description')
                return false
            else return true
        })
    }

    return (

        <MainCard isSingleCard={true} navBar={true} sideBar={false} isGuest={true}>
            {/* ********************************************************************** */}
            {/* NAVIGASYON PANEL */}
            {/* ********************************************************************** */}
            <Grid container item alignItems='center' xs={12}>
                <Typography sx={{ ...styles.strongText }}>{t('guestReservation')}</Typography>
            </Grid>

            {/* ********************************************************************** */}
            {/* INPUT FIELD */}
            {/* ********************************************************************** */}
            <Grid container item xs={12} sx={{ mt: '2.5rem', width: 'stretch' }}>
                <form>
                    {/* ********************************************************************** */}
                    {/* 1st ROW OF INPUT FIELD */}
                    {/* ********************************************************************** */}
                    <Grid container justifyContent="space-between">

                        <Grid item container justifyContent="space-between">
                            <Grid item xs={3.9}>
                                <CustomTextField sx={{ ...styles.textfield }}
                                    label={t('firstName')} placeholder={t('firstName')} value={firstName} setValue={setFirstName} />
                            </Grid>
                            <Grid item xs={3.9}>
                                <CustomTextField sx={{ ...styles.textfield }}
                                    label={t('lastName')} placeholder={t('lastName')} value={lastName} setValue={setLastName} />
                            </Grid>

                            <Grid item xs={3.9}>
                                <CustomTextField
                                    label={t('phone')} placeholder={t('phone')} value={phoneNumber} type="tel" setValue={setPhoneNumber} />
                            </Grid>
                        </Grid>
                        {/* ********************************************************************** */}
                        {/* 2nd ROW OF INPUT FIELD */}
                        {/* ********************************************************************** */}
                        <Grid item container justifyContent="space-between" sx={{ mt: '1rem' }}>
                            <Grid item xs={3.9}>
                                <CustomTextField value={fullNameOfThePersonToMeet} placeholder={t('fullNameOfThePersonToMeet')} label={t('fullNameOfThePersonToMeet')} setValue={setFullNameOfThePersonToMeet} />
                            </Grid>

                            <Grid item xs={3.9}>
                                <CustomTextField
                                    type='date'
                                    label={t('authInputField6')}
                                    value={appointmentDate}
                                    placeholder={t('authInputField6')}
                                    setValue={setAppointmentDate}
                                />
                            </Grid>
                            <Grid item xs={3.9}>
                                <CustomTextField
                                    placeholder={t('authInputField7')}
                                    label={t('authInputField7')}
                                    type="time"
                                    value={appointmentTime}
                                    setValue={setAppointmentTime} />
                            </Grid>
                        </Grid>

                        <Grid item container justifyContent="space-between" sx={{ mt: '1rem' }}>
                            <Grid item xs={12}>
                                <CustomTextField
                                    value={appointmentReason}
                                    label={t('authInputField4')}
                                    placeholder={t('authInputField4')}
                                    setValue={setAppointmentReason} />

                            </Grid>
                        </Grid>
                        {/* ********************************************************************** */}
                        {/* 3rd ROW OF INPUT FIELD */}
                        {/* ********************************************************************** */}
                        <Grid item container sx={{ mt: '1rem', justifyContent: 'center' }}>
                            <CustomTextField
                                label="Açıklama"
                                multiline
                                rows={6}
                                sx={{
                                    [`& fieldset`]: {
                                        borderRadius: 2,
                                    },
                                }}
                                value={description}
                                setValue={setDescription}
                            />
                        </Grid>
                        <Grid item container alignItems="center" alignContent="center" justifyContent="flex-start">
                            <Grid item alignItems="center" alignContent="center" sx={{ width: '1.8rem' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checked}
                                            onClick={(e) => setChecked(e.target.checked)}
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item alignItems="center" alignContent="center" sx={{ mr: '2rem', mb: '0.2rem' }}>
                                <div>
                                    <span onClick={() => handleClick()} style={{ cursor: 'pointer', textDecoration: 'underline' }}>Aydınlatma Metnini</span>
                                    <span> okudum ve kabul ediyorum.</span>
                                </div>

                            </Grid>
                        </Grid>
                        {/* ********************************************************************** */}
                        {/* BUTTON */}
                        {/* ********************************************************************** */}
                        <Grid item container sx={{ mt: '2rem' }}>
                            <Grid item xs={3}>
                                <YellowButton onClick={() => handleSubmit()}> {t('commonCreateButton')} </YellowButton>
                            </Grid>
                        </Grid>
                    </Grid>

                </form>
            </Grid>
        </MainCard>

    )

}


export default GuestReservation;