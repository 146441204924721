//REACT
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

//MUI
import Grid from '@mui/material/Grid';

//LIBRARY
import { hidePopup, setMessageAndButtonText } from "../../redux/popup"

// SERVICE 
import UserService from '../../services/UserService';

import { Typography } from '@mui/material';
import { ROUTES } from '../../utils/constants';
import CustomTextField from '../../UI/CustomTextField';
import MainCard from '../../UI/MainCard';
import { DEFAULT_THEME } from '../../utils/useThemes';
import YellowButton from '../../UI/YellowButton';

const styles = {
    strongText: {
        fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
        fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
        fontSize: DEFAULT_THEME.typography.fontSize.regular
    },
    lightText: {
        fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
        fontWeight: DEFAULT_THEME.typography.fontWeight.regular,
        fontSize: DEFAULT_THEME.typography.fontSize.light
    },
    textfield: {
        [`& fieldset`]: {
            borderRadius: 30
        },

    },
    textfieldDescription: {
        [`& fieldset`]: {
            borderRadius: 5
        }
    }
}

const ResetPassword = () => {

    const { t } = useTranslation();

    const dispatch = useDispatch()
    const navigate = useNavigate()
    // declare email 
    const [email, setEmail] = useState('')
    const [disabled, setDisabled] = useState(true)

    // initialize services
    const userService = new UserService();

    useEffect(() => {
        const emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
        if (emailRegex.test(email)) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [email])

    const handleSubmit = async () => {
        console.log('handle submit')

        const result = await userService.sendPasswordResetEmail(email)
        // success case 
        if (result.data && result.success) {

            dispatch(setMessageAndButtonText({
                title: 'Şifre Sıfırlama İsteği Gönderildi!',
                text: `Lütfen e-posta adresinizi kontrol edin. \n E-posta adresinize gönderilen linke tıklayarak şifrenizi sıfırlayabilirsiniz. \n Linkinizin geçerlilik süresi 30 dakikadır.`,
                successButtonText: "Tamam",//t("commonOkButton"),
                successButtonAction: () => { dispatch(hidePopup()); navigate(ROUTES.INDEX) },
                isSuccessMessage: true,
                isErrorMessage: false
            }))
        }

        // error case 
        if (result && result.error) {
            dispatch(setMessageAndButtonText({
                title: 'Şifre Sıfırlama İsteği Gönderilemedi!',
                text: `${result?.error?.message}`,
                noButtonText: t("commonOkButton"),
                noButtonAction: () => { dispatch(hidePopup()) },
                isErrorMessage: true
            }))
        }
    }

    return (

        <MainCard isSingleCard={true} navBar={true} sideBar={false} isGuest={true}>
            {/* ********************************************************************** */}
            {/* NAVIGASYON PANEL */}
            {/* ********************************************************************** */}
            <Grid container item alignItems='center' xs={12}>
                <Typography sx={{ ...styles.strongText }}>{t('newPassword')}</Typography>
            </Grid>

            {/* ********************************************************************** */}
            {/* INPUT FIELD */}
            {/* ********************************************************************** */}
            <Grid container item xs={12} sx={{ mt: '2.5rem', width: 'stretch' }}>
                <Grid container justifyContent="space-between"></Grid>
                <Grid item container justifyContent="space-center" alignItems="center" xs={12} sx={{ mb: '2rem' }}>
                    <Grid item xs={3.9}>
                        <CustomTextField sx={{ ...styles.textfield }}
                            label={t('email')} placeholder={t('email')} value={email} setValue={setEmail} type="email" />
                    </Grid>
                    <Grid item xs={1.5} sx={{ ml: '1rem' }}>
                        <YellowButton
                            type="submit"
                            onClick={() => handleSubmit()}
                            disabled={disabled}
                        >
                            {t('send')}
                        </YellowButton>


                    </Grid>
                </Grid>
            </Grid>
        </MainCard>
    )
}



export default ResetPassword;

