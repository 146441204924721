import { configureStore } from "@reduxjs/toolkit";
import notificationsReducer from "./notifications";
import userReducer from "./user";
import visitReducer from "./visit"
import popupReducer from "./popup"
import redirectReducer from "./redirect"
import darkModeReducer from "./darkMode"
import snackbarReducer from "./snackbar"
import loadingReducer from "./loading"

export default configureStore({
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
                serializableCheck: false,
        }),
        reducer: {
                notifications: notificationsReducer,
                user: userReducer,
                visit: visitReducer,
                redirect: redirectReducer,
                popup: popupReducer,
                darkMode: darkModeReducer,
                snackbar: snackbarReducer,
                loading: loadingReducer,
        }
})