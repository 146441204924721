const { createSlice } = require("@reduxjs/toolkit");

export const darkModeSlice = createSlice({
    name: 'darkMode',
    initialState: false,
    reducers: {
        setDarkMode: (state, action) => {
            return action.payload
        },
    }
})

export const { setDarkMode } = darkModeSlice.actions

export default darkModeSlice.reducer