//REACT
import { useTranslation } from 'react-i18next';
import { useState } from "react";

//MUI
import { Grid, FormGroup } from '@mui/material';

//LIBRARY
import MainCard from '../../UI/MainCard';
import { DEFAULT_THEME } from '../../utils/useThemes';
import CustomTextField from '../../UI/CustomTextField';
import YellowButton from '../../UI/YellowButton';
import NavigationPanel from '../../UI/NavigationPanel';
import { useDispatch } from 'react-redux';
import CompanyService from '../../services/CompanyService';
import { showSuccess } from '../../redux/snackbar';
import { formatPhone } from '../../utils/helper';

const styles = {
    subTitle: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    strongText: {
        fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
        fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
        fontSize: DEFAULT_THEME.typography.fontSize.regular
    },
    lightText: {
        fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
        fontWeight: DEFAULT_THEME.typography.fontWeight.regular,
        fontSize: DEFAULT_THEME.typography.fontSize.light
    },
}

const CreateFirmInternal = (props) => {

    const { t } = useTranslation();


    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [description, setDescription] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [disabled, setDisabled] = useState(true);

    const [panels, setPanels] = useState([t('adminPanel'), t('userSettings'), t('createNewFirm')])
    const dispatch = useDispatch()
    const companyService = new CompanyService();

    const handleSubmit = async (e) => {
        const company = {
            name,
            email,
            phone: formatPhone(phoneNumber),
            description,
        }
        await createFirm(company)

    }
    const clearFields = () => {
        setName('')
        setEmail('')
        setPhoneNumber('')
        setDescription('')

    }

    const createFirm = async (company) => {

        const result = await companyService.createCompany(company)
        try {
            if (result) {
                dispatch(showSuccess({ message: t("successMessage") }));
                clearFields()
                console.log("Company created.");
            }
        } catch (error) {
            console.log(error)
        }

    }
    const checkInputs = () => {

        if (name && email && phoneNumber) {
            setDisabled(false)
        }
        else {
            setDisabled(true)
        }


    }

    return (

        <MainCard isSingleCard={true}>
            {/* ********************************************************************** */}
            {/* NAVIGASYON PANEL */}
            {/* ********************************************************************** */}
            <Grid container item sx={{ ...styles.subTitle }} xs={12}>
                <NavigationPanel panels={panels}> </NavigationPanel>

            </Grid>

            {/* ********************************************************************** */}
            {/* INPUT FIELD */}
            {/* ********************************************************************** */}
            <Grid container xs={12} sx={{ justifyContent: "center" }}>
                <FormGroup sx={{ width: 'stretch' }} onChange={() => checkInputs()}>
                    <Grid container xs={12} direction="column" justifyContent="space-between" >
                        {/* ********************************************************************** */}
                        {/* 1st ROW OF INPUT FIELD */}
                        {/* ********************************************************************** */}
                        <Grid item container justifyContent="space-between" sx={{ mt: '2rem' }}>

                            <Grid item xs={3.9}>
                                <CustomTextField
                                    fullWidth size='small'
                                    placeholder={t('companyName')}
                                    label={t('companyName')}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}>
                                </CustomTextField>
                            </Grid>
                            <Grid item xs={3.9}>
                                <CustomTextField
                                    fullWidth size='small'
                                    placeholder={t('companyEmail')}
                                    label={t('companyEmail')}
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}>
                                </CustomTextField>
                            </Grid>
                            <Grid item xs={3.9}>
                                <CustomTextField
                                    fullWidth size='small'
                                    placeholder={t('phoneNumberLabel')}
                                    type="tel"
                                    label={t('phoneNumberLabel')}
                                    value={phoneNumber}
                                    setValue={setPhoneNumber}>
                                </CustomTextField>
                            </Grid>
                        </Grid>



                        {/* ********************************************************************** */}
                        {/* 2nd ROW OF INPUT FIELD */}
                        {/* ********************************************************************** */}
                        <Grid item container sx={{ mt: '2rem', justifyContent: 'center' }}>
                            <CustomTextField
                                placeholder={t('authInputField5')}
                                isDescriptionTF={true}
                                label={t('authInputField5')}
                                multiline
                                rows={3}
                                value={description}
                                setValue={setDescription}>
                            </CustomTextField>
                        </Grid>

                        {/* ********************************************************************** */}
                        {/* BUTTON ROW*/}
                        {/* ********************************************************************** */}
                        <Grid item container sx={{ mt: '2rem' }} >
                            <Grid item xs={4} >
                                <YellowButton type="submit" onClick={(e) => handleSubmit(e)} disabled={disabled}>
                                    {t('commonCreateButton')}

                                </YellowButton>
                            </Grid>
                        </Grid>
                    </Grid>

                </FormGroup>
            </Grid>


        </MainCard>
    )

}


export default CreateFirmInternal;