import NoPage from '../pages/NoPage';
import Login from '../pages/login/Login';


const AuthCheckFunction = (element, permission, user, isPublic = false) => {
    if (isPublic) {
        return element
    }
    if (user.id == "") {
        return Login;
    }
    let isAuthorized = permission?.some(role => role === user.type)
    if (isAuthorized) {
        return element
    }
    else {return NoPage}

}
export default AuthCheckFunction