//REACT
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

//MUI
import LocalShippingIconOutlined from '@mui/icons-material/LocalShippingOutlined';
import HandshakeIconOutlined from '@mui/icons-material/HandshakeOutlined';
import ManageAccountsIconOutlined from '@mui/icons-material/ManageAccountsOutlined';
import DepartureBoardIconOutlined from '@mui/icons-material/DepartureBoardOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import RuleIcon from '@mui/icons-material/Rule';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import PeopleIcon from '@mui/icons-material/People';

import { Grid, useMediaQuery, createSvgIcon } from '@mui/material';
//LIB

import { ROLES, ROUTES } from '../../utils/constants'
import MainCard from '../../UI/MainCard';
import { DEFAULT_THEME, useThemes } from '../../utils/useThemes';
import CommonInfoButton from '../../UI/CommonInfoButton';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import NavigationPanel from '../../UI/NavigationPanel';


const TruckRampIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"> <path d="M640 0V400c0 61.9-50.1 112-112 112c-61 0-110.5-48.7-112-109.3L48.4 502.9c-17.1 4.6-34.6-5.4-39.3-22.5s5.4-34.6 22.5-39.3L352 353.8V64c0-35.3 28.7-64 64-64H640zM576 400c0-26.5-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48s48-21.5 48-48zM23.1 207.7c-4.6-17.1 5.6-34.6 22.6-39.2l46.4-12.4 20.7 77.3c2.3 8.5 11.1 13.6 19.6 11.3l30.9-8.3c8.5-2.3 13.6-11.1 11.3-19.6l-20.7-77.3 46.4-12.4c17.1-4.6 34.6 5.6 39.2 22.6l41.4 154.5c4.6 17.1-5.6 34.6-22.6 39.2L103.7 384.9c-17.1 4.6-34.6-5.6-39.2-22.6L23.1 207.7z" /></svg>
);


const StandardUserMenu = ({ user }) => {

    const getTheme = useThemes()
    const isPhone = useMediaQuery(getTheme().breakpoints.down('phone'));
    const currentUser = useSelector((state) => state.user);

    const navigate = useNavigate();
    const { t } = useTranslation();
    const [panels, setPanels] = useState([t('adminPanel'), "Ana Menü"])

    const styles = {
        subTitle: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        strongText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: isPhone ? DEFAULT_THEME.typography.fontWeight.bold : DEFAULT_THEME.typography.fontWeight.extra_bold,
            fontSize: DEFAULT_THEME.typography.fontSize.regular
        },
        lightText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.regular,
            fontSize: DEFAULT_THEME.typography.fontSize.light
        },
        infoButtonIcon: {
            minHeight: '100%',
            minWidth: '100%'
        },
        card: {
            p: isPhone ? "5px" : "17px",
        }
    }

    let fields = [
        { text: t('unloadRes'), navigate: ROUTES.LOAD_RESERVATION, isUnloadPage: true, children: <TruckRampIcon sx={{ ...styles.infoButtonIcon }} /> },
        { text: t('loadRes'), navigate: ROUTES.LOAD_RESERVATION, isLoadPage: true, children: <LocalShippingOutlinedIcon sx={{ ...styles.infoButtonIcon }} /> },
        { text: t('reservationOperations'), navigate: ROUTES.VISITING_OPERATIONS_MENU, children: <EventAvailableIcon sx={{ ...styles.infoButtonIcon }} /> },
        // { text: t('reporting'), navigate:ROUTES.REPORTING_MENU, children:<CalendarMonthIcon sx={{ ...styles.infoButtonIcon }} />},
        // { text: t('userProcedures'), navigate:ROUTES.USER_SETTINGS, children:<PeopleIcon sx={{ ...styles.infoButtonIcon }} />},
    ]
    let fieldsForCompAuthUser = [
        { text: t('unloadRes'), navigate: ROUTES.LOAD_RESERVATION, isUnloadPage: true, children: <TruckRampIcon sx={{ ...styles.infoButtonIcon }} /> },
        { text: t('loadRes'), navigate: ROUTES.LOAD_RESERVATION, isLoadPage: true, children: <LocalShippingOutlinedIcon sx={{ ...styles.infoButtonIcon }} /> },
        { text: t('reservationOperations'), navigate: ROUTES.VISITING_OPERATIONS_MENU, children: <EventAvailableIcon sx={{ ...styles.infoButtonIcon }} /> },
        // { text: t('reporting'), navigate: ROUTES.REPORTING_MENU, children: <CalendarMonthIcon sx={{ ...styles.infoButtonIcon }} /> },
        // { text: t('userProcedures'), navigate: ROUTES.USER_SETTINGS, children: <PeopleIcon sx={{ ...styles.infoButtonIcon }} /> },
        // { text: t('commonDecisionRes'), navigate: ROUTES.RESERVATION_APPROVAL, children: <RuleIcon sx={{ ...styles.infoButtonIcon }} /> }

    ]

    return (
        <MainCard isSingleCard={true}>

            <Grid container justifyContent="space-between" direction="column">
                <Grid item>
                    <Grid container item xs={12} alignItems='center' sx={{ mb: '1rem' }}>
                        <NavigationPanel panels={panels}></NavigationPanel>
                    </Grid>
                </Grid>


                <Grid item>
                    <Grid container item>
                        <Grid container item xs={12}>

                            {currentUser.type === ROLES.PARTNER_COMPANY_AUTHORIZED_USER ?
                                fieldsForCompAuthUser.map((f, i) => {
                                    return <Grid item key={i} xs={isPhone ? 5.7 : 2.7} sx={{ ...styles.card }}>
                                        <CommonInfoButton text={f.text} isLoadPage={f.isLoadPage ?? false} isUnloadPage={f.isUnloadPage ?? false} navigate={f.navigate} children={f.children} />
                                    </Grid>

                                })
                                :
                                fields.map((f, i) => {
                                    return <Grid item key={i} xs={isPhone ? 5.7 : 2.7} sx={{ ...styles.card }}>
                                        <CommonInfoButton text={f.text} isLoadPage={f.isLoadPage ?? false} isUnloadPage={f.isUnloadPage ?? false} navigate={f.navigate} children={f.children} />
                                    </Grid>

                                })}
                        </Grid>
                    </Grid>


                </Grid>
            </Grid>
        </MainCard>


    );
}

export default StandardUserMenu;