import React, { useState, useCallback, useEffect } from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import AppRouter from './router/AppRouter';
//MUI
import { ThemeProvider, createTheme } from '@mui/material/styles';

//Redux
import { Provider } from 'react-redux';
import store from './redux/store';
//Theme
import { useThemes } from './utils/useThemes';
//Translation
import './utils/i18n'; // available for all components through context api
import CustomPopUp from './UI/CustomPopUp';
import eventBus from './utils/EventBus';
import { useSelector } from "react-redux";

function App() {
  const getTheme = useThemes()
  const [isDark, setIsDark] = useState(false);
  //const darkMode = useSelector(state => state.darkMode)

  const themeSetter = useCallback(e => {
    setIsDark(!!e.matches)
  }, [])

  eventBus.on("darkMode", (data) => {
    const isDarkModeChecked = data.isDarkMode
    setIsDark(isDarkModeChecked)
  })
  useEffect(() => {

    // Watch changes of browser theme
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', themeSetter);
    // Clean listener
    return () => window.removeEventListener("change", themeSetter);

    // eslint-disable-next-line
  }, []);


  return (
    <Provider store={store}>
      <ThemeProvider theme={getTheme(isDark)}>
        <CssBaseline />
        <CustomPopUp />
        <AppRouter />
      </ThemeProvider>
    </Provider>
  );
}
export default App;

