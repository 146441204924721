
const { createSlice, } = require("@reduxjs/toolkit");


export const popupSlice = createSlice({
    name: 'snackbar',
    initialState: {
        isOpen: false,
        isErrorMessage: false,
        isSuccessMessage: false,
        title: '',
        text: 'Açıklama ',
        yesButtonText: 'Sil',
        successButtonText: 'Başarılı',
        noButtonText: 'Hayır',
        yesButtonAction: () => { },
        noButtonAction: () => { },
        successButtonAction: () => { },

    },

    reducers: {


        openPopup: (state, action) => {
            return { ...state, ...action.payload, isOpen: true }
        },
        hidePopup: (state, action) => {
            return {
                ...state, isOpen: false,
            }
        },
        setMessageAndButtonText: (state, action) => {
            return {
                ...state,
                ...action.payload,
                isOpen: true,
            }


        },
        setButtonAction: (state, action) => {
            return { ...state, ...action.payload, isOpen: false }
        }



    }
})

export const { openPopup, hidePopup, setMessageAndButtonText, setButtonAction } = popupSlice.actions

export default popupSlice.reducer