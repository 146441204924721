import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";

//MUI
import Grid from '@mui/material/Grid';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';

//LIBRARY
import dayjs from 'dayjs';

// SERVICE 
import AppointmentService from '../services/AppointmentService';
import { APPOINTMENT_STATUSES, APPOINTMENT_TYPES, FREIGHT_AREAS } from '../utils/constants';
import { displayPlate } from '../utils/helper';

const tableStyles = {
    tableHead: {
        backgroundColor: '#2980b9',
        fontSize: '100px',
        fontWeight: 'bold',
        textAlign: 'center',
        color: 'white',
        height: '12vh',
        textTransform: 'uppercase',
    },
    tableCell: {
        fontSize: '150px',
        textAlign: 'center',
        padding: '13px',
        fontWeight: 'bold',
        position: 'relative', // Make the cell relative to position the status line
    },
    tableRow: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#f5f5f5',
        },
    },
    statusLine: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '40px',
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    statusText: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: 'white',
        textTransform: 'uppercase',
        transform: 'rotate(-90deg)',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
    },
    approvedStatus: {
        backgroundColor: 'green',
    },
    pendingStatus: {
        backgroundColor: 'lightgrey',
    },
    inProgressStatus: {
        backgroundColor: 'orange',
    },
};

const POLL_INTERVAL = 1 * 60 * 1000; // 30 minutes in milliseconds
const MAX_NUMBER_OF_APPOINTMENTS_TO_DISPLAY = 3;

const AppointmentBoard2 = () => {
    const { t } = useTranslation();
    const [appointments, setAppointments] = useState([]);
    const [lastIndexShown, setLastIndexShown] = useState(0);
    const [showSecondTable, setShowSecondTable] = useState(false);

    const appointmentService = new AppointmentService();

    // Function to calculate startTime and endTime for the next 15 minutes
    const calculateTimeWindow = () => {
        const appointmentDate = dayjs();
        const startTime = appointmentDate.subtract(30, 'minute').format('YYYY-MM-DDTHH:mm:ss');
        const endTime = appointmentDate.add(30, 'minute').format('YYYY-MM-DDTHH:mm:ss');

        return { appointmentDate, startTime, endTime };
    };

    // Function to fetch appointments
    const fetchAppointments = async () => {

        const params = {
            ...calculateTimeWindow(),
            appointmentStatus: [APPOINTMENT_STATUSES.PENDING, APPOINTMENT_STATUSES.APPROVED],
            appointmentType: [APPOINTMENT_TYPES.OPERATION],
        };

        try {
            const result = await appointmentService.getAppointments(params);

            if (result && result.data.length > 0) {
                result.data.sort((a, b) => {
                    if (a.appointmentStatus === APPOINTMENT_STATUSES.APPROVED) return -1;
                    if (a.appointmentStatus === APPOINTMENT_STATUSES.PENDING) return 1;
                    return 0;
                });
            }

            setAppointments(result.data);

        } catch (error) {
            console.error('Error fetching appointments:', error);
        }
    };

    // Effect for initial fetch and setting up interval
    useEffect(() => {
        fetchAppointments(); // Fetch immediately on load

        const intervalId = setInterval(fetchAppointments, POLL_INTERVAL);

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    // useEffect(() => {
    //     const timeoutId = setTimeout(() => {
    //         setShowSecondTable(true);
    //     }, 10000); // 10 seconds delay

    //     return () => clearTimeout(timeoutId);
    // }, []);

    // useEffect(() => {
    //     if (showSecondTable) {
    //         const timeoutId = setTimeout(() => {
    //             setShowSecondTable(false);
    //         }, 3000); // 3 seconds delay

    //         return () => clearTimeout(timeoutId);
    //     }
    // }, [showSecondTable]);

    // Use effect for automatic switching between appointments every 30 seconds
    useEffect(() => {
        const intervalId = setInterval(() => {
            if (appointments.length > 0) {
                setLastIndexShown((prevIndex) => {
                    const newIndex = prevIndex + MAX_NUMBER_OF_APPOINTMENTS_TO_DISPLAY;
                    return newIndex >= appointments.length ? 0 : newIndex;
                });
            }
        }, 5000); // 5 seconds interval

        return () => clearInterval(intervalId);
    }, [appointments]);

    return (
        <Grid container justifyContent="center" alignItems="center" sx={{ overflow: 'auto' }}>
            <TableContainer component={Paper} sx={tableStyles.tableContainer}>
                <Table sx={tableStyles.table} aria-label="appointment table" size='small'>
                    <TableHead>
                        <TableRow>
                            {!showSecondTable && appointments.length > 0 ? (
                                <>
                                    <TableCell style={tableStyles.tableHead}>{t('plate')}</TableCell>
                                    <TableCell style={tableStyles.tableHead}> {t('gateNumber2')} </TableCell>
                                    <TableCell style={tableStyles.tableHead}>{t('Saat')}</TableCell>
                                </>
                            ) : (
                                appointments.length > 0 &&
                                <>
                                    <TableCell style={tableStyles.tableHead}>{t('freightArea')}</TableCell>
                                    <TableCell style={tableStyles.tableHead}>{t('FİRMA')}</TableCell>
                                </>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {appointments.length === 0 &&
                            <TableRow style={tableStyles.tableRow}>
                                <TableCell style={tableStyles.tableCell} colSpan={3}>{t('noWaitingAppointments')}</TableCell>
                            </TableRow>
                        }
                        {appointments.slice(lastIndexShown, lastIndexShown + MAX_NUMBER_OF_APPOINTMENTS_TO_DISPLAY).map((appointment, index) => (
                            <TableRow key={index} sx={tableStyles.tableRow}>
                                {
                                    !showSecondTable ?
                                        // FIRST TABLE : PLATE, GATE NUMBER AND APPOINTMENT TIME
                                        <>
                                            <TableCell style={tableStyles.tableCell}>
                                                <div style={{
                                                    ...tableStyles.statusLine,
                                                    ...(appointment.appointmentStatus === APPOINTMENT_STATUSES.APPROVED ? tableStyles.approvedStatus :
                                                        appointment.appointmentStatus === APPOINTMENT_STATUSES.PENDING ?
                                                            tableStyles.pendingStatus : tableStyles.inProgressStatus)
                                                }}>
                                                    <Typography style={{ ...tableStyles.statusText, color: appointment.appointmentStatus === APPOINTMENT_STATUSES.APPROVED ? 'white' : 'black' }}>
                                                        {appointment.appointmentStatus === APPOINTMENT_STATUSES.APPROVED && t(`${APPOINTMENT_STATUSES.APPROVED}`)}
                                                        {appointment.appointmentStatus === APPOINTMENT_STATUSES.PENDING && t(`${APPOINTMENT_STATUSES.PENDING}`)}
                                                    </Typography>
                                                </div>
                                                {displayPlate(appointment?.freight?.plate)}
                                            </TableCell>
                                            <TableCell style={tableStyles.tableCell}>
                                                {
                                                    appointment?.freight?.gateNumber ?
                                                        appointment?.freight?.gateNumber :
                                                        <Typography style={{ color: 'red', fontSize: '70px', fontWeight: 'bold' }}>{t('Bekleniyor')}</Typography>
                                                }
                                                <Typography style={{ fontSize: '50px', fontWeight: 'bold' }}>
                                                    {t(`${appointment?.freight?.freightArea}`)}
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={tableStyles.tableCell}> {appointment?.appointmentTime} </TableCell>
                                        </>
                                        :
                                        // SECOND TABLE : OPERATION AREA AND COMPANY NAME
                                        (
                                            <>
                                                <TableCell style={tableStyles.tableCell}>
                                                    <div style={{
                                                        ...tableStyles.statusLine,
                                                        ...(appointment.appointmentStatus === APPOINTMENT_STATUSES.APPROVED ? tableStyles.approvedStatus :
                                                            appointment.appointmentStatus === APPOINTMENT_STATUSES.PENDING ?
                                                                tableStyles.pendingStatus : tableStyles.inProgressStatus)
                                                    }}>
                                                        <Typography style={{ ...tableStyles.statusText, color: appointment.appointmentStatus === APPOINTMENT_STATUSES.APPROVED ? 'white' : 'black' }}>
                                                            {appointment.appointmentStatus === APPOINTMENT_STATUSES.APPROVED && t(`${APPOINTMENT_STATUSES.APPROVED}`)}
                                                            {appointment.appointmentStatus === APPOINTMENT_STATUSES.PENDING && t(`${APPOINTMENT_STATUSES.PENDING}`)}
                                                        </Typography>
                                                    </div>
                                                    {appointment?.freight?.freightArea === FREIGHT_AREAS.MAIS_SEVKIYAT ? t(`${appointment?.freight?.freightArea}`).substring(4, 13).toUpperCase() : t(`${appointment?.freight?.freightArea}`).substring(0, 7).toUpperCase()}
                                                </TableCell>
                                                <TableCell style={tableStyles.tableCell}>{appointment?.company?.name.length > 8 ? `${appointment?.company?.name.substring(0, 8).toUpperCase()}...` : appointment?.company?.name}</TableCell>
                                            </>
                                        )
                                }
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    );
};

export default AppointmentBoard2;