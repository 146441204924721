//React
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';

//MUI
import { Box, Button, Dialog, Grid, IconButton, ListItem, TextField, Typography, useMediaQuery } from "@mui/material"

//MUI-Icons
import { DataGrid, gridClasses, GridToolbarContainer, GridToolbarFilterButton, GridPagination, GridCsvExportMenuItem, GridToolbarDensitySelector, GridToolbarExportContainer, GridSearchIcon } from '@mui/x-data-grid';
import { DEFAULT_THEME, useThemes } from '../utils/useThemes';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'; import BlackButton from './BlackButton';
import { FREIGHT_DOWNLOAD_COLUMNS, ROUTES } from '../utils/constants';
import { useNavigate } from 'react-router-dom';
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { useSelector } from 'react-redux';
import { downloadCSV, removeSpaces } from '../utils/helper';
import dayjs from 'dayjs';


const CustomDataGrid = (props) => {
    const { rows, columns, params, checkboxSelection, isAddButton = true, setSelected = false, showFilterBar = true, getFunction = () => { }, paginationMode = 'server', totalCount, noRowText = 'noRows', body = false, onCellEdit, onDownloadAll = false, titleOfTable } = props

    const [open, setOpen] = useState(false)
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(30)
    const [filterParams, setFilterParams] = useState({})
    const rowsPerPageOptions = [30, 40, 50]
    const navigate = useNavigate();
    const getTheme = useThemes()
    const isPhone = useMediaQuery(getTheme().breakpoints.down('phone'));
    const isLarge = useMediaQuery(getTheme().breakpoints.down('desktop'));
    const darkMode = useSelector(state => state.darkMode)
    const { t } = useTranslation();

    // checks if the pagesize changed or not
    const usePrevious = (value) => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }
    const prevPageSize = usePrevious(pageSize)

    useEffect(() => {
        let pageNumber = page
        if (prevPageSize !== pageSize) {
            setPage((perv) => 0)
            pageNumber = 0
        }

        // limit and page
        const pageOptions = { page: pageNumber, limit: pageSize }

        // params are default params passed into the CustomDataGrid component
        // filterParams are the params that are passed into the filter bar
        const requestParams = { ...params, ...pageOptions, ...filterParams }

        if (filterParams?.columnField === 'plate' || filterParams?.columnField === 'trailerNumber') {
            requestParams.value = removeSpaces(filterParams.value)
        }

        getFunction(requestParams)


    }, [pageSize, page, filterParams?.value])

    const styles = {
        box: {
            height: rowsPerPageOptions.length * 220,
            width: 'stretch'
        },

        root: {
            //backgroundColor: '#ffffff',
            border: 2,
            borderColor: '#DFDFDF',
            paddingBottom: "14px", paddingTop: "14px",

            [`& .${gridClasses.row}.even`]: {
                //background: '#FFFFFF',

            },
            [`& .${gridClasses.row}.odd`]: {
                //backgroundColor: '#ffffff',
            },
            '& .MuiDataGrid-row': {
                padding: '0px !important',
                '&:hover': {
                    backgroundColor: '#a4a4a4',
                }
            },
            '& .MuiDataGrid-cell': {
                justifyContent: 'center'
            },
            '& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root': {
                color: 'white',
            },
            '& .MuiDataGrid-menuIcon .MuiButtonBase-root': {
                color: 'black'
            },
            '& .MuiDataGrid-row.Mui-selected': {
                backgroundColor: '#FFCC33',
                '&:hover': {
                    backgroundColor: '#a4a4a4',
                }
            }
        },
        dataGridHead: {
            '& .MuiDataGrid-columnHeaders': {
                color: '#000000',
                borderRadius: '7px',
                backgroundColor: '#CED0DA',
            },
            '& .MuiDataGrid-columnHeaderTitleContainer': {

                justifyContent: 'center'
            },
            '& .MuiDataGrid-columnHeaderTitle': {
                fontFamily: DEFAULT_THEME.typography.fontFamilyBold,
                fontSize: isPhone ? '15px' : '19px',
            },
            '& .MuiDataGrid-columnSeparator--sideRight': { visibility: 'hidden' },
            '& .MuiDataGrid-sortIcon,': {
                fill: '#000000'
            }
        },
        fullScreenButton: {
            width: "16px", borderRadius: "5px",
            height: "30px",
            fontSize: "12px",

            color: darkMode ? '#C2BEBE' : 'black',
            //color: darkMode ? "black" : "white",
            textAlign: "center",
        },
        addButton: {
            width: "140px", borderRadius: "5px",
            maxHeight: "32px",
            alignItems: 'center',
            alignContent: 'center',
            fontSize: "15px",
            backgroundColor: '#2980b9'
        },
        mobileScreenButton: {
            width: "60px", borderRadius: "5px",
            maxHeight: "26px",
            fontSize: "11px",
        },
        downloadCsv: {
            '&:hover': {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                cursor: 'pointer'
            },
            padding: "6px 16px"

        },
        title: {
            fontSize: isPhone ? 15 : 20,
            fontWeight: 600,
        },

    }

    const whereToNavigate = () => {

        if (titleOfTable === "Şirket Aracı Takibi") {
            navigate(ROUTES.COMPANY_CAR_ADD_OR_EDIT)
        }
        else if (titleOfTable === "Servis Aracı Takibi") {
            navigate(ROUTES.SERVICE_CAR_ADD_OR_EDIT)
        }
        else if (titleOfTable === t('freightAppointments')) {
            navigate(ROUTES.LOAD_RESERVATION)
        }
        else if (titleOfTable === "Randevu Listesi") {
            navigate(ROUTES.AUTH_USER_RESERVATION)
        }
        else if (titleOfTable === "Haftalık Ziyaretler") {
            navigate(ROUTES.AUTH_USER_RESERVATION)
        }
        else if (titleOfTable === "Günlük Ziyaretler") {
            navigate(ROUTES.AUTH_USER_RESERVATION)
        }
        else if (titleOfTable === "Günlük Ziyaret Takibi") {
            navigate(ROUTES.AUTH_USER_RESERVATION)
        }
        else if (titleOfTable === "Kullanıcı Listesi") {
            navigate(ROUTES.CREATE_USER_INTERNAL)
        }
        else if (titleOfTable === "Kartsız Personel") {
            navigate(ROUTES.CARDLESS_EMPLOYEE_ADD_OR_EDIT)
        }
        else if (titleOfTable === t('operationResponsibleList')) {
            navigate(ROUTES.ADD_OPERATION_RESPONSIBLE, { state: { freightArea: params?.freightArea } })
        }
        else if (titleOfTable === t('companyList')) {
            navigate(ROUTES.CREATE_FIRM_INTERNAL)
        }
    };


    const CustomToolbarMobile = () => {
        return (
            <GridToolbarContainer sx={{ mb: '10px' }}>
                <Grid container justifyContent="space-around" alignItems='center' alignContent="center" sx={{ height: '40px' }}>
                    <Grid container item xs={12} justifyContent='center'>
                        <Grid item>
                            <Typography sx={{ ...styles.title }}>{titleOfTable}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid container justifyContent="space-between" alignItems='center' alignContent="center" sx={{ mb: '0.5rem' }}>
                            <Grid container item xs={3.5}>
                                {showFilterBar && <>
                                    <GridToolbarFilterButton sx={{ fontSize: 0 }} />
                                </>}

                            </Grid>
                            <Grid container item xs={3.5}>

                                {isAddButton ? <BlackButton
                                    style={styles.mobileScreenButton}
                                    onClick={() => { whereToNavigate() }}
                                    startIcon={<AddCircleOutlineOutlinedIcon></AddCircleOutlineOutlinedIcon>}
                                >
                                    {t('commonAddButton')}
                                </BlackButton> : <Grid></Grid>}

                            </Grid>
                            <Grid container item xs={3.5} justifyContent='center'>
                                <IconButton
                                    sx={styles.fullScreenButton}
                                    onClick={() => {
                                        setOpen((prev) => !prev)
                                        open ? document.exitFullscreen() : document.documentElement.requestFullscreen()
                                    }}
                                >
                                    {open ? <FullscreenExitIcon></FullscreenExitIcon> : <FullscreenOutlinedIcon></FullscreenOutlinedIcon>}
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </GridToolbarContainer >
        );
    }

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>

                <Grid container justifyContent="space-between" alignContent="center" sx={{ height: isLarge ? '100px' : '70px' }}>

                    <Grid container item alignItems='center' alignContent='center' justifyContent="space-around" sx={{ mb: '0.5rem' }}>

                        <Grid item alignItems='center'>
                            <Typography sx={{ ...styles.title }}>{titleOfTable}</Typography>
                        </Grid>
                        <GridPagination />
                        <Grid item>
                            {showFilterBar && <>
                                <GridToolbarFilterButton />
                            </>}

                        </Grid>

                        <GridToolbarExportContainer >
                            {onDownloadAll ?
                                <ListItem onClick={() => onDownloadAllCsv()} sx={{ ...styles.downloadCsv }}>
                                    {t('downloadAllCsv')}
                                </ListItem> : null}
                        </GridToolbarExportContainer>

                        <GridToolbarDensitySelector />

                        {isAddButton ? <BlackButton
                            style={styles.addButton}
                            onClick={() => { whereToNavigate() }}
                            startIcon={<AddCircleOutlineOutlinedIcon></AddCircleOutlineOutlinedIcon>}
                        >
                            {t('commonAddButton')}
                        </BlackButton> : <Grid></Grid>}
                        <IconButton
                            sx={styles.fullScreenButton}
                            onClick={() => {
                                setOpen((prev) => !prev)
                                open ? document.exitFullscreen() : document.documentElement.requestFullscreen()
                            }}
                        >
                            {open ? <FullscreenExitIcon></FullscreenExitIcon> : <FullscreenOutlinedIcon></FullscreenOutlinedIcon>}
                        </IconButton>

                    </Grid>

                </Grid>
            </GridToolbarContainer >
        );
    }

    const CustomNoRowsOverlay = () => {
        return (
            <Grid container item sx={{ justifyContent: "center" }}>
                <Box sx={{ mt: 7 }}>Veri bulunamadı.</Box>
            </Grid>

        );
    }

    const handleClose = () => {
        setOpen(false)
    }

    const onCellEditStop = (params, e) => {
        //Return if there are no changes
        if (e.target.value === params.formattedValue ||
            e.target.value === undefined ||
            (!e.target.value && !params.formattedValue)) return
        let data = { id: params.row.id }
        data[params.field] = e.target.value
        onCellEdit(data)
    }

    function isNumeric(str) {
        if (typeof str != "string") return false // we only process strings!  
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }

    const flatten = (ob) => {
        // The object which contains the final result
        const result = {};
        // Flattens object
        const flattenObj = (ob, suffix = '') => {
            Object.entries(ob).forEach(([key, value]) => {
                if (key !== "id" && key !== 'createdAt' && !key.endsWith('Id')) {
                    if (typeof value === "object" && value !== null) {
                        (Array.isArray(value) || isNumeric(key)) ? flattenObj(value) : flattenObj(value, key)
                    } else {
                        let counter = 1;
                        let newKey = suffix ? t(key) + ' ' + t(suffix) : key
                        while (result[t(newKey)]) {
                            newKey = `${t((newKey).split(' ')[0])} ${++counter}`;
                        }
                        result[t(newKey)] = t(value);
                    }
                }
            });
        };
        flattenObj(ob);
        return result;
    };

    // Declare a flatten function that takes 
    // object as parameter and returns the 
    // flatten object
    const flattenObj = (ob) => {

        // The object which contains the
        // final result
        let result = {};

        // loop through the object "ob"
        for (const i in ob) {

            // We check the type of the i using
            // typeof() function and recursively
            // call the function again
            if ((typeof ob[i]) === 'object' && !Array.isArray(ob[i])) {
                const temp = flattenObj(ob[i]);
                for (const j in temp) {

                    // Store temp in result
                    result[i + '.' + j] = temp[j];
                }
            }

            // Else store ob[i] in result directly
            else {
                result[i] = ob[i];
            }

        }
        return result
    };

    const onDownloadAllCsv = async () => {
        let fieldsToExport = await onDownloadAll()
        const flattedObjects = fieldsToExport.map(a => flattenObj(a))
        const returnArr = []

        flattedObjects.forEach((obj) => {
            const temp = {}
            FREIGHT_DOWNLOAD_COLUMNS.forEach((key) => {
                if (key === 'freight.freightArea' || key === 'appointmentStatus')
                    temp[t(key)] = t(obj[key])

                else temp[t(key)] = obj[key]
            })
            returnArr.push(temp)
        })

        const fileName = dayjs().format('YYYY-MM-DD') + ' ' + titleOfTable + '.csv'
        downloadCSV(returnArr, fileName)
    }

    const dataGrid = () => {
        return <Box sx={styles.box}>
            <DataGrid
                onCellEditStop={onCellEditStop}
                isCellEditable={() => true}
                paginationMode={paginationMode}
                filterMode={paginationMode}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'id', sort: 'desc' }],
                    },

                }}
                // localeText={language}
                sx={{
                    ...styles.root,
                    ...styles.dataGridHead
                }}
                rowHeight={40}
                components={{ Toolbar: isPhone ? CustomToolbarMobile : CustomToolbar, NoRowsOverlay: CustomNoRowsOverlay }}
                componentsProps={{ filterPanel: { sx: { maxWidth: isPhone ? "19rem" : '40rem' } } }}
                rows={rows}
                columns={columns}
                rowCount={paginationMode === 'server' ? totalCount : rows.length}
                checkboxSelection={checkboxSelection}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={rowsPerPageOptions}
                page={page}
                onPageChange={(page) => setPage(page)}
                paginationModel={{ page, pageSize }}
                onPaginationModelChange={(paginationModel) => {
                    setPage(paginationModel.page)
                    setPageSize(paginationModel.pageSize)
                }}
                pagination
                // isCellEditable={false}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
                onFilterModelChange={(params) => setFilterParams(params.items[0])}
                onSelectionModelChange={(ids) => {
                    if (setSelected) {
                        const selectedIDs = new Set(ids);
                        const selectedRowData = rows.filter((row) =>
                            selectedIDs.has(row.id)
                        );
                        setSelected(selectedRowData)
                    }
                }}
            />
        </Box>
    }

    return (open ?
        <Dialog
            open={open}
            onClose={handleClose}
            fullScreen
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{ sx: { p: "20px" } }}
        >
            {body ? body() : null}
            {dataGrid()}
        </Dialog> :

        dataGrid()
    )
}

export default CustomDataGrid