//REACT
import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

//MUI
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import { Typography, useMediaQuery, Grid } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PeopleIcon from '@mui/icons-material/People';


//LIB
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MainCard from '../../UI/MainCard';
import CommonInfoButton from '../../UI/CommonInfoButton';
import { ROLES, ROUTES } from '../../utils/constants'
import { DEFAULT_THEME, useThemes } from '../../utils/useThemes';
import { useSelector } from "react-redux";
import NavigationPanel from '../../UI/NavigationPanel';
import { useState } from 'react';

const StandardUserSettings = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const getTheme = useThemes()
    const isPhone = useMediaQuery(getTheme().breakpoints.down('phone'));
    const currentUser = useSelector(state => state.user)
    const [panels, setPanels] = useState([t('adminPanel'), t('userMenu')])

    const styles = {
        subTitle: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        strongText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
            fontSize: DEFAULT_THEME.typography.fontSize.regular
        },
        lightText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.regular,
            fontSize: DEFAULT_THEME.typography.fontSize.light
        },
        infoButtonIcon: {
            minHeight: '100%',
            minWidth: '100%'
        },
        card: {
            p: isPhone ? "5px" : "17px",
        }
    }

    let fieldsForUser = [
        { text: "Kullanıcı Listesi", navigate: ROUTES.USERS_TABLE, children: <PeopleIcon sx={{ ...styles.infoButtonIcon }} /> },
        { text: t('createNewUser'), navigate: ROUTES.CREATE_USER_INTERNAL, children: <PersonAddIcon sx={{ ...styles.infoButtonIcon }} /> },
        { text: t('createNewFirm'), navigate: ROUTES.CREATE_FIRM_INTERNAL, children: <AddBusinessIcon sx={{ ...styles.infoButtonIcon }} /> },
    ]

    if (!(currentUser.userType === ROLES.SUPER_ADMIN || currentUser.userType === ROLES.AUTHORIZED_USER)) {
        fieldsForUser.shift()
    }

    return (
        <MainCard isSingleCard={true}>
            <Grid container item xs={12} sx={{ ...styles.subtitle }} alignItems='center'>
                <NavigationPanel panels={panels}></NavigationPanel>
            </Grid>

            <Grid container item>
                {fieldsForUser.map((f, i) => {
                    return <Grid item key={i} xs={isPhone ? 5.7 : 2.7} sx={{ ...styles.card }}>
                        <CommonInfoButton text={f.text} navigate={f.navigate} children={f.children} />
                    </Grid>

                })}
            </Grid>
        </MainCard >

    );
}

export default StandardUserSettings;