import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';


const CommonEditButton = (props) => {
    const navigate = useNavigate()
    const { route, disabled, state } = props
    return (
        <Grid item>
            <Button
                style={{
                    height: '30px', width: '2rem', borderRadius: 5, backgroundColor: '#2980b9', cursor: 'pointer'
                }}
                onClick={() => navigate(route, { state: { request: state } })}
                disabled={disabled}
            >
                <EditIcon sx={{ color: 'white' }} />
            </Button>
        </Grid>
    )
}
export default CommonEditButton