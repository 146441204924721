import { Button, Typography, useMediaQuery } from "@mui/material";
import { DEFAULT_THEME, useThemes } from "../utils/useThemes";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const YellowButton = (props) => {
    const { style, icon, ...rest } = props
    const navigate = useNavigate();
    const getTheme = useThemes()
    const isPhone = useMediaQuery(getTheme().breakpoints.down('phone'));

    const styles = {
        root: {
            width: "100% !important",
            height: '46px',
            // backgroundColor: DEFAULT_THEME.palette.super_primary,
            color: "white",
            backgroundColor: '#2980b9',
            textAlign: "center",
            borderRadius: "8px",
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
            fontSize: isPhone ? DEFAULT_THEME.typography.fontSize.very_light : DEFAULT_THEME.typography.fontSize.light,
            '&:hover': {
                backgroundColor: '#469d89',
                color: 'white',
                cursor: 'pointer',
            },
            ...style
        }
    }

    return (
        <Button sx={{ ...styles.root }} startIcon={icon}  {...rest} />


    )
}

export default YellowButton;