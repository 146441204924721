import { ENDPOINTS, TRACKING_TYPES } from "../utils/constants"
import axios from "../utils/interceptor"
import { useTranslation } from 'react-i18next';

export default class TrackingService {

    constructor() {
        this.endpoint = ENDPOINTS.TRACKING
    }

    getTrackings = async (params) => {

        try {
            // Request
            let result = await axios.get(this.endpoint.BASE, { params }, { urlsToClear: this.endpoint.BASE })

            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            //Return
            return result.data

        } catch (e) {
            return Promise.reject(e)
        }
    }
    updateTracking = async (trackingAppointmentId, body) => {

        let url = `${this.endpoint.BASE}/${trackingAppointmentId}`

        try {
            // Request
            let result = await axios.patch(url, body, { urlsToClear: this.endpoint.BASE })

            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            //Return
            return result.data

        } catch (e) {
            return Promise.reject(e)
        }
    }
    createTracking = async (tracking) => {

        let url = this.endpoint.BASE

        try {
            // Request
            let result = await axios.post(url, tracking, { urlsToClear: this.endpoint.BASE })

            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            //Return
            return result.data

        } catch (e) {
            return Promise.reject(e)
        }
    }

    deleteTracking = async (trackingAppointmentId) => {

        let url = `${this.endpoint.BASE}/${trackingAppointmentId}`

        try {
            // Request
            let result = await axios.delete(url, { urlsToClear: this.endpoint.BASE })

            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            //Return
            return result.data

        } catch (e) {
            return Promise.reject(e)
        }
    }
}