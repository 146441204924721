import * as React from 'react';
import BlackButton from "../../UI/BlackButton";
import { useState } from "react";

//React
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

//MUI
import { Grid, Typography } from "@mui/material"
import { Checkbox, FormControlLabel } from "@mui/material";
import MainCard from '../../UI/MainCard';

//Libraries & Assets
import { DEFAULT_THEME } from '../../utils/useThemes';
import { formatPhone } from '../../utils/helper'

//Translation
import { useTranslation } from 'react-i18next';
import CustomTextField from '../../UI/CustomTextField';

import CustomDropdown from '../../UI/CustomDropdown';
import { hidePopup, setMessageAndButtonText } from "../../redux/popup"

import UserService from '../../services/UserService';
import CompanyService from '../../services/CompanyService';
import { showSuccess } from '../../redux/snackbar';
import NavigationPanel from '../../UI/NavigationPanel';
import { DROP_DOWN_TYPES, PAGINATION_LIMITS, RESPONSE_STATUSES, ROLES, ROUTES } from '../../utils/constants';
import { useEffect } from 'react';
import { WindowSharp } from '@mui/icons-material';
import YellowButton from '../../UI/YellowButton';

const styles = {
    strongText: {
        fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
        fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
        fontSize: DEFAULT_THEME.typography.fontSize.regular
    },
    lightText: {
        fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
        fontWeight: DEFAULT_THEME.typography.fontWeight.regular,
        fontSize: DEFAULT_THEME.typography.fontSize.light
    },
    root: {
        justifyContent: 'flex-start',
        alignItems: "center",

    },
    title1: {
        backgroundColor: '#000000',
        color: '#FFFFFF',
        fontWeight: 800,
        fontSize: '23px',
        fontFamily: DEFAULT_THEME.typography.fontFamilyExtraBold,

    },
    title2: {
        color: '#000000',
        backgroundColor: "yellow",
        fontWeight: 800,
        fontSize: '23px',
        fontFamily: DEFAULT_THEME.typography.fontFamilyBold,
    }
    ,
    text: {
        fontFamily: DEFAULT_THEME.typography.fontFamilyBold,
        fontWeight: 200,
        fontSize: '12px',
        /* or 60px */
    },
    notchedOutline: {
        borderWidth: "1px",
        borderColor: "red !important"
    },
    img2: {
        height: "120px"
    },
    welcomeTextForm: {
        fontFamily: DEFAULT_THEME.typography.fontFamilyExtraBold,
        fontWeight: 100,
        fontSize: '20px',
    },
    welcomeTextForm2: {
        fontFamily: DEFAULT_THEME.typography.fontFamilyExtraBold,
        fontWeight: 800,
        fontSize: '20px',
        mb: '45px'
    },
    form: {
        width: "100%",
        height: "100%"
    },
    containerLeft: {
        backgroundColor: "black", alignItems: "center", borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px', display: 'flex', height: "stretch"

    },
    logoContainer: {
        alignItems: "center", ml: '65px', mt: '35px'
    },

    title3: {
        color: 'white', fontSize: '25px', fontWeight: "bold", mb: '15px'
    },
    title4: {
        color: 'white', fontSize: '12px', mb: '65px'
    },
    textField: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: `90px`,
            },
        }
    },
    img: {
        height: '90%',
        width: '90%',

    },
    disabled: {
        backgroundColor: '#E5E5E5',
    }

}


const CreateUser = (props) => {
    // initialize hooks
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // initialize services
    const userService = new UserService();
    const companyService = new CompanyService();

    // initialize states
    const [firstName, setFirstName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [phone, setPhone] = useState("");
    const [lastName, setLastName] = useState("");
    const [checked, setChecked] = useState(false)
    const [type, setType] = useState("");

    const [company, setCompany] = useState("");
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState()

    const [disabled, setDisabled] = useState(true)

    const [companySearchParams, setCompanySearchParams] = useState({})
    const [companySearchParamsLength, setCompanySearchParamsLength] = useState(0)

    //fetch companies 
    useEffect(() => {
        getCompanies()
    }, [])

    // validate inputs  
    useEffect(() => {
        setDisabled(
            !(validateUserObject({ firstName, lastName, email, phone, password, repeatPassword, type }) && checked)
        )
    }, [firstName, lastName, email, phone, password, repeatPassword, type, checked])

    useEffect(() => {

        if (companySearchParams && companySearchParamsLength >= 2) {
            const params = {
                search: true,
                query: companySearchParams
            }
            getCompanies(params)
        }
        else if (companySearchParamsLength === 0) {
            getCompanies()
        }

    }, [companySearchParamsLength])

    const getCompanies = async (params) => {
        const result = await companyService.getCompaniesPublic({ ...params, limit: PAGINATION_LIMITS.UPPER_LIMIT })
        if (result && result.status === RESPONSE_STATUSES.success) {
            setCompanies(result.data)
        }
    }

    const createUser = async (user) => {

        const result = await userService.createUser(user)

        if (result && result.error) {
            dispatch(setMessageAndButtonText({
                'title': t("errorMessageTitle"), 'text': `${result.error.message}`, 'noButtonText': t("commonOkButton"), noButtonAction: () => { dispatch(hidePopup()) }, "isErrorMessage": true
            }))
        }

        if (result.data && result.success) {
            navigate(ROUTES.USERS_TABLE)
        }
    }

    const handleSubmit = async (e) => {
        if (repeatPassword !== password) {
            dispatch(setMessageAndButtonText({
                'title': t("errorMessageTitle"), 'text': t("errorPasswordMessage"), 'noButtonText': t("commonOkButton"), noButtonAction: () => { dispatch(hidePopup()) }, "isErrorMessage": true
            }))
            return
        }
        else {
            await createUser({
                firstName,
                lastName,
                email,
                phone: formatPhone(phone),
                password,
                type,
                companyId: selectedCompany.id
            })
        }
    }

    const validateUserObject = (user) => {
        return Object.keys(user).every(key => {
            if (!user[key])
                return false
            else return true
        })
    }

    return (

        <MainCard isSingleCard={true}>

            <Grid container item xs={11} alignItems='center'>
                <NavigationPanel panels={[t('adminPanel'), t('userSettings'), t('createNewUser')]}></NavigationPanel>
            </Grid>

            <Grid container justifyContent="center" sx={{ mt: '2rem' }} xs={12}>

                <Grid container xs={12} sx={{ ...styles.root }} justifyContent="center">

                    {/* ************************************************************************/}
                    {/* LOGIN FORM */}
                    {/* ************************************************************************/}
                    <Grid item container xs={12} >
                        <Grid container item justifyContent="space-between" sx={{
                            padding: '20px', width: 'stretch',
                            borderRadius: '40px'
                        }} direction="column">

                            {/*TEXTFIELD ROW */}
                            <Grid item>
                                <Grid item container direction="column" height="stretch" justifyContent="space-between" xs={12}>

                                    {/* First row: firstName, lastName, email  */}
                                    <Grid item container xs={12} justifyContent="space-between" alignItems="center" sx={{ mb: '1.1rem' }}>
                                        <Grid item xs={3.9}>
                                            <CustomTextField
                                                fullWidth size='small' placeholder={t('firstName')} label={t('firstName')} value={firstName} onChange={(e) => setFirstName(e.target.value)}>
                                            </CustomTextField>
                                        </Grid>
                                        <Grid item xs={3.9}>
                                            <CustomTextField
                                                fullWidth size='small' placeholder={t('lastName')} label={t('lastName')} value={lastName} onChange={(e) => setLastName(e.target.value)}>
                                            </CustomTextField>
                                        </Grid>
                                        <Grid item xs={3.9}>
                                            <CustomTextField
                                                fullWidth size='small' placeholder={t('commonEmail')} label={t('commonEmail')} type="email" value={email} onChange={(e) => setEmail(e.target.value)}></CustomTextField>
                                        </Grid>
                                    </Grid>

                                    {/* Second row: phone, role, company */}
                                    <Grid item container xs={12} justifyContent="space-between" alignItems="center" sx={{ mb: '1.1rem' }}>
                                        <Grid item xs={3.9}>
                                            <CustomTextField
                                                fullWidth size='small' type="tel" placeholder={t('phoneNumberLabel')} label={t('phoneNumberLabel')} value={phone} setValue={setPhone}>
                                            </CustomTextField>
                                        </Grid>
                                        <Grid item xs={3.9}>
                                            <CustomDropdown
                                                type={DROP_DOWN_TYPES.SELECT}
                                                label={t('userRole')}
                                                placeholder={t('userRole')}
                                                value={type}
                                                setValue={setType}
                                                data={Object.values(ROLES).map((role, i) => { return { id: i, title: t(`${role}`), value: role } })}
                                            />
                                        </Grid>
                                        <Grid item xs={3.9}>
                                            <CustomDropdown
                                                type={DROP_DOWN_TYPES.AUTO_COMPLETE}
                                                label={t('company')}
                                                placeholder={t('company')}
                                                value={selectedCompany}
                                                setValue={setSelectedCompany}
                                                data={companies.map((company) => { return { id: company.id, title: company.name } })}
                                                searchParams={companySearchParams}
                                                setSearchParams={setCompanySearchParams}
                                                setSearchParamsLength={setCompanySearchParamsLength}
                                                fetchFunction={getCompanies}
                                            />
                                        </Grid>
                                    </Grid>

                                    {/* Third row: password, passwordRepeat */}

                                    <Grid item container xs={12} justifyContent="space-between" alignItems="center" sx={{ mb: '1.1rem' }}>
                                        <Grid item xs={5.9}>
                                            <CustomTextField
                                                type="password" fullWidth size='small' placeholder={t('password')} label={t('password')} value={password} onChange={(e) => setPassword(e.target.value)}></CustomTextField>
                                        </Grid>
                                        <Grid item xs={5.9}>
                                            <CustomTextField
                                                type="password" fullWidth size='small' placeholder={t('passwordRepeat')} label={t('passwordRepeat')} value={repeatPassword} onChange={(e) => setRepeatPassword(e.target.value)}></CustomTextField>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item container alignItems="center" alignContent="center" justifyContent="flex-start">
                                <Grid item alignItems="center" alignContent="center" sx={{ width: '1.8rem' }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checked}
                                                onClick={(e) => setChecked(e.target.checked)}
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item alignItems="center" alignContent="center" sx={{ mr: '2rem', mb: '0.2rem' }}>
                                    <div>
                                        <span onClick={() => window.open("https://www.renault.com.tr/kisisel-verilerin-korunmasi.html")} style={{ cursor: 'pointer', textDecoration: 'underline' }}>Aydınlatma Metnini</span>
                                        <span> okudum ve kabul ediyorum.</span>
                                    </div>

                                </Grid>
                            </Grid>

                            <Grid item sx={{ mb: '2.5rem' }}>
                                <Grid item container direction="column">
                                    <YellowButton
                                        disabled={disabled}
                                        onClick={handleSubmit}
                                        style={{ width: '20%', height: '46px' }}
                                    > {t('createUser')} </YellowButton>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid >
            </Grid >
        </MainCard>
    );

}


export default CreateUser;