//REACT
import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

//MUI
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import { useMediaQuery, Grid } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PeopleIcon from '@mui/icons-material/People';
import RuleIcon from '@mui/icons-material/Rule';
import FormatListBulleted from '@mui/icons-material/FormatListBulleted';

//LIB
import MainCard from '../../UI/MainCard';
import { ROUTES } from '../../utils/constants'
import { DEFAULT_THEME, useThemes } from '../../utils/useThemes';
import { useSelector } from "react-redux";
import { useState } from 'react';
import NavigationPanel from '../../UI/NavigationPanel';
import Card from '../../UI/Card';




const UserSettings = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const getTheme = useThemes()
    const isPhone = useMediaQuery(getTheme().breakpoints.down('phone'));
    const currentUser = useSelector(state => state.user)
    const [panels, setPanels] = useState([t('adminPanel'), t('userSettings')])

    const styles = {
        subTitle: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        strongText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
            fontSize: DEFAULT_THEME.typography.fontSize.regular
        },
        lightText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.regular,
            fontSize: DEFAULT_THEME.typography.fontSize.light
        },
        infoButtonIcon: {
            minHeight: '100%',
            minWidth: '100%'
        },
        card: {
            p: isPhone ? "5px" : "17px",
        }
    }

    let fields = [
        {
            text: t('createNewUser'),
            navigate: ROUTES.CREATE_USER_INTERNAL,
            children: <PersonAddIcon sx={{ ...styles.infoButtonIcon }} />
        },
        {
            text: t('createNewFirm'),
            navigate: ROUTES.CREATE_FIRM_INTERNAL,
            children: <AddBusinessIcon sx={{ ...styles.infoButtonIcon }} />
        },
        {
            text: t('userList'),
            navigate: ROUTES.USERS_TABLE,
            children: <PeopleIcon sx={{ ...styles.infoButtonIcon }} />
        },
        {
            text: t('userApproval'),
            navigate: ROUTES.USER_APPROVAL,
            children: <RuleIcon sx={{ ...styles.infoButtonIcon }} />
        },
        {
            text: t('companyList'),
            navigate: ROUTES.COMPANIES,
            children: <FormatListBulleted sx={{ ...styles.infoButtonIcon }} />
        },

    ]
    return (
        <MainCard isSingleCard={true}>

            <Grid container item xs={11} alignItems='center'>
                <NavigationPanel panels={panels}></NavigationPanel>
            </Grid>

            <Grid container>
                {fields.map((f, i) => {
                    return <Card field={f} styles={styles} />
                })}
            </Grid>
        </MainCard >


    );
}

export default UserSettings;