//REACT
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setMessageAndButtonText, hidePopup } from "../../redux/popup"
import { useDispatch, useSelector } from 'react-redux';

//MUI
import Grid from '@mui/material/Grid';
import { IconButton, useMediaQuery } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

//LIBRARY
import YellowButton from '../../UI/YellowButton'
import { ROUTES } from '../../utils/constants'
import MainCard from '../../UI/MainCard';
import { DEFAULT_THEME, useThemes } from '../../utils/useThemes';
import CustomDataGrid from '../../UI/CustomDataGrid';
import { useState } from 'react';
import NavigationPanel from '../../UI/NavigationPanel';
import TrackingService from '../../services/TrackingService';
import { TRACKING_TYPES } from '../../utils/constants';
import dayjs from 'dayjs';


const CompanyCarsTracking = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const getTheme = useThemes()
    const { t } = useTranslation();

    const trackingService = new TrackingService()

    const darkMode = useSelector(state => state.darkMode)
    const [rows, setRows] = useState([]);
    const [params, setParams] = useState({ trackingType: TRACKING_TYPES.VEHICLE });

    const isPhone = useMediaQuery(getTheme().breakpoints.down('phone'));
    const [panels, setPanels] = useState([t('adminPanel'), t('companyCarTracking')])
    const [totalCount, setTotalCount] = useState(0)

    // service call for appointments with pagination
    const getTrackings = async (params) => {
        const response = await trackingService.getTrackings(params);
        if (response && response.data) {
            setRows(response.data)
            setTotalCount(response.count)
        }
    }

    const styles = {
        topGrid: {
            p: isPhone ? 2 : 4,
            borderWidth: '1px',
            borderRadius: '30px',
            borderStyle: 'solid',
            backdropFilter: 'blur(12px)',
            justifyContent: 'space-around',
            width: 'stretch',
            backgroundColor: darkMode ? 'black' : 'white',
            borderColor: '#E3E5EA',
        },
        bottomGrid: {
            p: 4,
            borderWidth: '1px',
            backgroundColor: darkMode ? 'black' : 'white',

            borderRadius: '30px',
            borderStyle: 'solid',
            backdropFilter: 'blur(12px)',
            justifyContent: 'space-around',
            width: 'stretch',
            borderColor: '#E3E5EA',
            mt: '20px',
        },
        subtitle: {
            backgroundColor: darkMode ? 'black' : 'white',
            backdropFilter: 'blur(12px)',
            alignItems: 'center',
            borderStyle: 'solid',
            borderColor: '#EAEEF7',
            height: isPhone ? '120px' : '85px',
            borderRadius: 10,
            p: 2,
        },
        strongText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
            fontSize: DEFAULT_THEME.typography.fontSize.regular
        },
        lightText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.regular,
            fontSize: DEFAULT_THEME.typography.fontSize.light
        },
    }

    const deleteRow = async (object) => {
        const result = await trackingService.deleteTracking(object.id)

        if (result) {
            const newRows = rows.filter((row) => row.id !== object.id);
            setRows(newRows);
        }
    };

    {/* function to allow us to do a operation for particular row in this case is a delete and edit operation */ }
    const getActionColumnDetail = (object) => {
        return (
            <Grid container item justifyContent={"space-evenly"}>
                <Grid item>
                    <IconButton
                        title={t('edit')}
                        style={{
                            color: '#2980b9'
                        }}
                        onClick={() => navigate(ROUTES.COMPANY_CAR_ADD_OR_EDIT, { state: { request: object, isUpdate: true } })}
                    >
                        <EditIcon />
                    </IconButton>
                </Grid>
                <Grid item>
                    <IconButton
                        aria-label="delete"
                        title={t('delete')}
                        style={{
                            color: '#E45B5B'
                        }}
                        onClick={() => dispatch(setMessageAndButtonText({
                            'title': t("deleteTitle"),
                            'text': t("deleteText"),
                            'noButtonText': t("commonCancelButton"),
                            noButtonAction: () => {
                                dispatch(hidePopup())
                            },
                            'yesButtonText': t("commonDelete"),
                            yesButtonAction: () => {
                                deleteRow(object);
                                dispatch(hidePopup())
                            },
                            "isErrorMessage": false
                        }))}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Grid>
            </Grid>
        );
    };

    const getActionColumn = (object) => {
        return (
            <Grid item>
                <YellowButton

                    style={{
                        height: '30px', width: '110px', borderRadius: 5, fontSize: 11
                    }}
                    onClick={() => {
                        dispatch(setMessageAndButtonText({
                            'title': 'Açıklama', 'noButtonText': t("commonHideButton"), noButtonAction: () => { dispatch(hidePopup()) }, 'text': object.description, "isErrorMessage": true
                        }))
                    }}>
                    {t('commonShowDescription')}
                </YellowButton>

            </Grid>
        );
    };

    const columns = [
        {
            field: 'operation',
            headerName: t('operation'),
            minWidth: 100,
            renderCell: (params) => getActionColumnDetail(params.row)
        },
        {
            field: 'trackingDate',
            headerName: t('trackingDate'),
            minWidth: 180,
            renderCell: (params) => dayjs(params.row?.trackingDate, 'DD-MM-YYYY').format('DD/MM/YYYY')
        },
        {
            field: 'plate',
            headerName: t('plateLabel'),
            width: 100,
            renderCell: (params) => params.row.vehicleTracking?.plate

        },
        {
            field: 'fullName',
            headerName: t('fullName'),
            minWidth: 170,
            renderCell: (params) => params.row.vehicleTracking?.fullName
        },
        {
            field: 'entranceTime',
            headerName: t('entranceTime'),
            minWidth: 180,
            renderCell: (params) => params.row.entranceTime?.slice(0, 5)
        },
        {
            field: 'exitTime',
            headerName: t('exitTime'),
            minWidth: 180,
            renderCell: (params) => params.row.exitTime?.slice(0, 5)
        },
        // {
        //     field: 'cardNumber',
        //     headerName: t('cardNumber'),
        //     minWidth: 180,
        //     renderCell: (params) => params.row.vehicleTracking?.cardNumber
        // },
        {
            field: 'details',
            headerName: t('details'),
            minWidth: 150,
            renderCell: (params) => getActionColumn(params.row)
        },
    ];

    return (

        <MainCard>
            {/* ********************************************************************** */}
            {/* NAVİGE PANEL*/}
            {/* ********************************************************************** */}
            <Grid container item xs={12} sx={{ ...styles.subtitle }}>
                <NavigationPanel panels={panels}></NavigationPanel>
            </Grid>

            <Grid item container sx={{ ...styles.bottomGrid }} justifyContent='space-between'>
                {/* ********************************************************************** */}
                {/* DATAGRID*/}
                {/* ********************************************************************** */}
                <Grid container item sx={{ mt: isPhone ? '1.5rem' : '' }}>
                    <CustomDataGrid
                        rows={rows}
                        columns={columns}
                        showFilterBar={true}
                        titleOfTable={t('carArrivalTableTitle')}
                        getFunction={getTrackings}
                        totalCount={totalCount}
                        params={params}

                    />
                </Grid>
            </Grid>
        </MainCard >


    );
}

export default CompanyCarsTracking;