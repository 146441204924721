// React
import { useTranslation } from "react-i18next";

// MUI
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Grid, Typography } from '@mui/material';
import { DEFAULT_THEME } from "../utils/useThemes";
import 'dayjs/locale/tr';
import 'dayjs/locale/en';

// Services
export default function BasicDatePicker(props) {
    const { defaultValue, value, setValue, label, style, ...rest } = props
    const { t, i18n } = useTranslation();

    const language = i18n.language

    const styles = {
        styleTitle: {
            fontSize: "1rem",
            fontFamily: DEFAULT_THEME.typography.fontSize.light
        },
        textField: {
            '& .MuiInputLabel-formControl': {
                fontSize: '0.9rem',
                color: "#bdbdbd !important"
            },
            '& .MuiOutlinedInput-input': {
                fontSize: '0.9rem',
            },
            [`& fieldset`]: {
                borderRadius: 30,
            },
            ...style
        },
        textFieldProps: {
            size: 'small',
            style: {
                fontSize: '0.9rem',
            },
        },
        endIcon: { style: { color: "black" } },
    }

    return (
        <Grid container>
            < Grid item xs={12} >
                <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={language}
                >
                    <DatePicker
                        sx={{ width: "100%", ...styles.textField }}
                        value={value}
                        format="DD/MM/YYYY"
                        label={label}
                        slotProps={{ textField: styles.textFieldProps, openPickerButton: styles.endIcon }}
                        defaultValue={defaultValue}
                        onChange={(newValue) => {
                            setValue(newValue)
                        }}
                        {...rest}
                    />
                </LocalizationProvider>
            </Grid >
        </Grid >
    );
}