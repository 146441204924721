import { Button, Typography, useMediaQuery } from "@mui/material";
import { DEFAULT_THEME, useThemes } from "../utils/useThemes";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";





const BlackButton = (props) => {
    const { style, icon } = props
    const [isDark, setIsDark] = useState(false);
    const darkMode = useSelector(state => state.darkMode)
    const getTheme = useThemes()

    const isPhone = useMediaQuery(getTheme().breakpoints.down('phone'));


    const styles = {
        button: {
            width: "stretch",
            height: '46px',
            backgroundColor: darkMode ? '#C2BEBE' : 'black',
            color: darkMode ? "black" : "white",
            textAlign: "center",
            borderRadius: "8px",
            border: "1px #000000",
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
            fontSize: isPhone ? DEFAULT_THEME.typography.fontSize.very_light : DEFAULT_THEME.typography.fontSize.light,

            '&:hover': {
                backgroundColor: darkMode ? '#DFDADA' : '#323232',
                color: darkMode ? 'black' : 'white',
            },
            ...style


        },
    }

    return (
        <Button variant="contained" sx={{ ...styles.button }} startIcon={icon} {...props} />

    )
}

export default BlackButton;