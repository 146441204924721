import axios from '../utils/interceptor'
import { COOKIES, ENDPOINTS, HEADERS, RESPONSE_STATUSES } from "../utils/constants"
import cookie from "react-cookies"

export default class UserService {

    constructor() {
        this.endpoints = ENDPOINTS.USERS
    }
    /**
    * @param {String} password password to login
    * @param {String} email email address to login
    * 
    */
    login = async ({ email, password }) => {

        const body = { email, password }

        try {
            // Request
            let result = await axios.post(this.endpoints.LOGIN, body)

            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            //Save Cookie
            let cookieOptions = { path: '/', maxAge: 60 * 60 * 24 * 15 } // 15 days
            cookie.save(COOKIES.AUTHORIZATION, result.headers[HEADERS.AUTHORIZATION], cookieOptions)
            //Return
            return result.data

        } catch (e) {
            return Promise.reject(e)
        }
    }
    logOut = async () => {
        try {
            // Request
            let result = await axios.post(this.endpoints.LOGOUT)

            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            //Remove Cookie
            cookie.remove(COOKIES.AUTHORIZATION)
            //Return
            return result.data

        } catch (e) {
            return Promise.reject(e)
        }
    }
    createUser = async (user) => {

        let url = this.endpoints.BASE

        try {
            // Request
            let result = await axios.post(url, user, { urlsToClear: [this.endpoints.BASE] })

            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            //Return
            return result.data

        } catch (e) {
            return Promise.reject(e)
        }
    }

    registerUser = async (user) => {

        let url = this.endpoints.REGISTER

        try {
            // Request
            let result = await axios.post(url, user, { urlsToClear: [this.endpoints.BASE] })

            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            //Return
            return result.data

        } catch (e) {
            return Promise.reject(e)
        }
    }
    getUsers = async (params) => {
        try {
            // Request
            let result = await axios.get(this.endpoints.BASE, { params })

            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            //Return
            return result.data
        }
        catch (e) {
            return Promise.reject(e)
        }
    }
    getUser = async (id) => {
        try {
            // Request
            let result = await axios.get(`${this.endpoints.BASE}/${id}`)

            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            //Return
            return result.data
        }
        catch (e) {
            return Promise.reject(e)
        }
    }
    getUserSelf = async () => {
        try {
            // Request
            let result = await axios.get(this.endpoints.SELF)

            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            //Return
            return result.data
        }
        catch (e) {
            return Promise.reject(e)
        }
    }

    loginWithToken = async (token) => {
        const body = { token }
        try {

            // Request
            let result = await axios.post(this.endpoints.AUTH_TOKEN, body)

            if (result.error) return Promise.reject(result.error.message)

            return result;

        } catch (e) {
            return Promise.reject(e)
        }
    }


    updateUser = async (id, user) => {

        let url = this.endpoints.BASE + '/' + id

        try {
            // Request
            let result = await axios.patch(url, user, { urlsToClear: [this.endpoints.BASE] })

            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            //Return
            return result.data

        } catch (e) {
            return Promise.reject(e)
        }
    }

    updateUserSelf = async (user) => {

        try {
            // Request
            let result = await axios.patch(this.endpoints.SELF, user, { urlsToClear: [this.endpoints.BASE] })

            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            //Return
            return result.data

        } catch (e) {
            return Promise.reject(e)
        }
    }

    sendPasswordResetEmail = async (email) => {

        const url = this.endpoints.RESET_PASSWORD

        try {
            // Request
            let result = await axios.post(url, { email })

            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            //Return
            return result.data

        } catch (e) {
            return Promise.reject(e)
        }
    }

    resetPassword = async (token, password) => {

        const url = this.endpoints.RENEW_PASSWORD
        const headers = { [HEADERS.AUTHORIZATION]: `Bearer ${token}` }

        try {
            // Request
            let result = await axios.patch(url, { password }, { headers })

            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            //Return
            return result.data

        } catch (e) {
            return Promise.reject(e)
        }
    }

}