//REACT
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

//MUI
import { Grid, FormGroup } from '@mui/material';

//LIBRARY
import MainCard from '../../UI/MainCard';
import CustomTextField from '../../UI/CustomTextField';
import YellowButton from '../../UI/YellowButton';

// Services
import { showError, showSuccess } from '../../redux/snackbar';
import { modifyPlate } from "../../utils/modifyPlate";
import NavigationPanel from "../../UI/NavigationPanel";
import TrackingService from "../../services/TrackingService";
import { ROUTES } from "../../utils/constants";
import dayjs from "dayjs";
import CustomDatePicker from "../../UI/CustomDatePicker";

const CompanyCarAddOrEdit = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const trackingService = new TrackingService()

    const { request, isUpdate } = location?.state || {}

    const [id, setId] = useState("");
    const [fullName, setFullName] = useState("");
    const [plate, setPlate] = useState("");
    const [cardNumber, setCardNumber] = useState("");
    const [entranceTime, setEntranceTime] = useState("");
    const [exitTime, setExitTime] = useState("");
    const [trackingDate, setTrackingDate] = useState(!isUpdate ? dayjs() : "");
    const [description, setDescription] = useState("");
    const [disabled, setDisabled] = useState(true);

    const [panels, setPanels] = useState([t('adminPanel'), t('companyCarTracking'), isUpdate ? "Şirket Aracı Güncelle" : t('companyCarAdd')]);

    useEffect(() => {
        if (isUpdate) getInfo();
    }, [request])

    // this is for updating
    useEffect(() => {
        if (isUpdate)
            setDisabled(
                !isAnyFieldChanged()
            )
    }, [fullName, plate, entranceTime, trackingDate, exitTime, cardNumber, description])

    // this is for adding 
    useEffect(() => {
        if (!isUpdate)
            checkInputs()
    }, [fullName, plate, entranceTime, trackingDate])

    const getInfo = () => {
        setFullName(request?.vehicleTracking?.fullName)
        setEntranceTime(request?.entranceTime)
        setId(request?.id)
        setExitTime(request?.exitTime)
        setCardNumber(request?.vehicleTracking?.cardNumber)
        setTrackingDate(dayjs(request?.trackingDate, 'DD-MM-YYYY'))
        setDescription(request?.description)
        setPlate(request?.vehicleTracking?.plate)
    }

    const checkInputs = () => {
        if (!isUpdate)
            setDisabled(!(fullName && plate && entranceTime && trackingDate))
    }

    const isAnyFieldChanged = () => {
        return (
            request?.vehicleTracking?.fullName !== fullName ||
            request?.vehicleTracking?.cardNumber !== cardNumber ||
            request?.vehicleTracking?.plate !== plate ||
            request?.entranceTime !== entranceTime ||
            request?.exitTime !== exitTime ||
            request?.trackingDate !== trackingDate.format('DD-MM-YYYY') ||
            request?.description !== description
        )
    }

    const onSubmitAppointment = async () => {

        const trackingType = "VEHICLE"
        const trackingObj = {
            trackingType,
            fullName,
            plate,
            cardNumber,
            entranceTime,
            trackingDate: trackingDate.format('YYYY-MM-DD'),
            exitTime,
            description,
        }
        if (isUpdate) {
            await updateTrackingAppointment(trackingObj)

        }
        else {
            await createTrackingAppointment(trackingObj)
        }
    }
    // action function 
    const createTrackingAppointment = async (tracking) => {

        const result = await trackingService.createTracking(tracking)

        if (result) {
            navigate(ROUTES.COMPANY_CARS_TRACKING)
        }
        else
            dispatch(showError({ message: t("errorMessageTitle") }));
    }
    const updateTrackingAppointment = async (tracking) => {

        const result = await trackingService.updateTracking(id, tracking)

        if (result) {
            navigate(ROUTES.COMPANY_CARS_TRACKING)
        }
        else
            dispatch(showError({ message: t("errorMessageTitle") }));
    }



    return (

        <MainCard isSingleCard={true}>
            {/* ********************************************************************** */}
            {/* NAVIGASYON PANEL */}
            {/* ********************************************************************** */}
            <NavigationPanel panels={panels}></NavigationPanel>

            {/* ********************************************************************** */}
            {/* INPUT FIELD */}
            {/* ********************************************************************** */}
            <Grid container xs={12} sx={{ mt: '2.5rem', justifyContent: "center" }}>

                <FormGroup sx={{ width: 'stretch' }} onChange={() => checkInputs()} >

                    {/* ********************************************************************** */}
                    {/* 1st ROW OF INPUT FIELD */}
                    {/* ********************************************************************** */}
                    <Grid container xs={12} direction="column" justifyContent="space-between" >

                        <Grid item container justifyContent="space-between">

                            <Grid item xs={3.9}>
                                <CustomDatePicker
                                    placeholder={t('commonDate')}
                                    label={t('commonDate')}
                                    value={trackingDate}
                                    setValue={setTrackingDate} />
                            </Grid>

                            <Grid item xs={3.9}>
                                <CustomTextField
                                    placeholder="Plaka" label="Plaka" value={modifyPlate(plate)} setValue={setPlate} />
                            </Grid>
                            <Grid item xs={3.9}>
                                <CustomTextField
                                    placeholder="Tam Ad" label="Tam Ad" value={fullName} setValue={setFullName} />
                            </Grid>

                        </Grid>

                        {/* ********************************************************************** */}
                        {/* 2nd ROW OF INPUT FIELD */}
                        {/* ********************************************************************** */}
                        <Grid item container justifyContent="space-between" sx={{ mt: '2rem' }}>
                            <Grid item xs={3.9}>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        label="Giriş Saati"
                                        value={entranceTime}
                                        setValue={setEntranceTime}
                                        type="time"
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={3.9}>
                                <CustomTextField
                                    type="time" label="Çıkış Saati" value={exitTime} setValue={setExitTime} />
                            </Grid>
                            <Grid item xs={3.9}>
                                <CustomTextField
                                    placeholder="Kart No" label="Kart No" value={cardNumber} setValue={setCardNumber} />

                            </Grid>
                        </Grid>

                        <Grid item container justifyContent="space-between" sx={{ mt: '2rem' }}>
                            <CustomTextField
                                placeholder={t('description')} isDescriptionTF={true} multiline rows={5} label={t('description')} value={description} setValue={setDescription} />
                        </Grid>

                        {/* ********************************************************************** */}
                        {/* BUTTON */}
                        {/* ********************************************************************** */}
                        <Grid item sx={{ mt: '2rem' }}>
                            <Grid item xs={4}>
                                <YellowButton
                                    disabled={disabled}
                                    type="submit"
                                    onClick={() => onSubmitAppointment()}
                                >
                                    {isUpdate ? "Güncelle" : t('commonAddButton')}
                                </YellowButton>

                            </Grid>
                        </Grid>
                    </Grid>

                </FormGroup>
            </Grid>


        </MainCard>
    )
}
export default CompanyCarAddOrEdit;
