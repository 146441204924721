
//REACT
import { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'

//MUI
import { FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'

//LIBRARY
import moment from 'moment'
import 'moment/locale/tr'

// SERVICES
import AppointmentService from '../../services/AppointmentService'

// UTILS
import MainCard from '../../UI/MainCard'
import NavigationPanel from '../../UI/NavigationPanel'
import { APPOINTMENT_STATUSES, APPOINTMENT_TYPES } from '../../utils/constants'


const styles = {
    subTitle: {
        flexDirection: 'row',
        alignItems: 'center',
    },

    strongText: {
        fontWeight: 'bold',
        fontSize: '14px',
    },

    lightText: {
        fontWeight: 'normal',
        fontSize: '14px',
    },

    tableContainer: {
        margin: '20px',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
    },

    headerRow: {
        backgroundColor: '#f5f5f5',
        fontWeight: 'bold'
    },

    cell: {
        border: '1px solid #ccc',
        height: '80px',
        width: '300px',
        boxSizing: 'border-box',
        padding: '10px',
    },

    filledCell: {
        boxSizing: 'border-box',
        color: 'rgba(255, 255, 255, 0.8)',
        backgroundColor: '#27AE60',
        cursor: 'pointer',
        height: '100%',
        width: '100%',
        borderRadius: '5px',
        '&:hover': {
            backgroundColor: '#1E8449',
        },
    },
    pendingCell: {
        boxSizing: 'border-box',
        color: 'rgba(255, 255, 255, 0.8)',
        // grey color for pending appointments
        backgroundColor: 'rgba(128, 128, 128, 0.8)',
        cursor: 'pointer',
        height: '100%',
        width: '100%',
        borderRadius: '5px',
        '&:hover': {
            backgroundColor: 'rgba(128, 128, 128, 1)',
        },
    },
    inProgressCell: {
        boxSizing: 'border-box',
        color: 'rgba(255, 255, 255, 0.8)',
        backgroundColor: '#ffb266',
        cursor: 'pointer',
        height: '100%',
        width: '100%',
        borderRadius: '5px',
        '&:hover': {
            backgroundColor: '#ff8c00',
        },
    },

    boldText: {
        fontWeight: 'bold',
        fontSize: '14px',
        boxSizing: 'border-box',
    },
    companyName: {
        fontWeight: 'bold',
        fontSize: '14px',
        textTransform: 'uppercase'
    },
    rightArrow: {
        width: 0,
        height: 0,
        borderTop: '20px solid transparent',
        borderBottom: '20px solid transparent',
        borderLeft: '20px solid rgb(2, 117, 216)',
        ml: '20px'
    },

    leftArrow: {
        width: 0,
        height: 0,
        borderTop: '20px solid transparent',
        borderBottom: '20px solid transparent',
        borderRight: '20px solid rgb(2, 117, 216)',
        mr: '20px'
    }
}

const VisitCalendar = (props) => {

    const { t } = useTranslation()

    // initialize services
    const appointmentService = new AppointmentService()

    const [panels,] = useState([t('adminPanel'), t('visitCalendar')])

    const [appointments, setAppointments] = useState([])
    const [rows, setRows] = useState([])
    const [slots, setSlots] = useState([])

    const [params, setParams] = useState({
        appointmentType: APPOINTMENT_TYPES.VISIT,
        appointmentStatus: [APPOINTMENT_STATUSES.APPROVED, APPOINTMENT_STATUSES.PENDING, APPOINTMENT_STATUSES.IN_PROGRESS],
        // monday
        startDate: moment().day(1).format('YYYY-MM-DD'),
        // friday
        endDate: moment().day(6).format('YYYY-MM-DD'),
        limit: 100,
    })

    const days = [
        { name: t('monday') },
        { name: t('tuesday') },
        { name: t('wednesday') },
        { name: t('thursday') },
        { name: t('friday') },
    ]

    // called just once to get the slots 
    useEffect(() => {
        getSlots()
    }, [])

    useEffect(() => {
        setTableRows()
    }, [appointments])

    useEffect(() => {
        getAppointments()
    }, [params])

    const getAppointments = async () => {
        const response = await appointmentService.getAppointments(params)
        if (response) {
            setAppointments(response.data)
        }
    }

    const getSlots = async () => {
        const response = await appointmentService.getSlots({
            startDate: params.startDate,
        })
        if (response) {
            setSlots(response.data)
        }
    }

    // sets the rows of the table with matching appointments (appointmentDate and slotNumber)
    const setTableRows = () => {
        const rows = days.map((_, index) => {
            const row = {
                date: moment(params.startDate).add(index, 'days').format('DD-MM-YYYY'),
                slots: []
            }

            slots.forEach((slot, index) => {
                const appointment = appointments.find(appointment => {

                    // Check if appointmentTime is between (slotStartTime should be included, slotEndTime should not be included)
                    return (
                        appointment?.appointmentDate === row.date &&
                        moment(appointment?.appointmentTime, 'HH:mm:ss').isBetween(moment(slot.START, 'HH:mm:ss'), moment(slot.END, 'HH:mm:ss'), undefined, '[)')
                    );
                });

                if (appointment) {
                    const value = (
                        <Grid sx={getAppointmentSlotStyle(appointment)}>
                            <Typography variant="body1" sx={{ ...styles.lightText, fontSize: '14px' }}> {appointment?.company?.name} </Typography>
                            <Typography variant="body1" sx={styles.boldText}> {t(`${appointment?.visit?.firstName}`)} {t(`${appointment?.visit?.lastName}`)} </Typography>
                        </Grid>
                    )

                    row.slots.push({
                        slotNumber: index + 1,
                        value
                    })
                }
            })
            return row
        })

        setRows(rows)
    }

    const getAppointmentSlotStyle = (appointment) => {
        if (appointment.appointmentStatus === APPOINTMENT_STATUSES.APPROVED) {
            return styles.filledCell
        } else if (appointment.appointmentStatus === APPOINTMENT_STATUSES.PENDING) {
            return styles.pendingCell
        } else if (appointment.appointmentStatus === APPOINTMENT_STATUSES.IN_PROGRESS) {
            return styles.inProgressCell
        }
    }



    // called when the user clicks on the arrows to change the week
    const handleWeekChange = (direction) => {
        if (direction === 'right') {
            setParams({
                ...params,
                startDate: moment(params.startDate).add(7, 'days').format('YYYY-MM-DD'),
                endDate: moment(params.endDate).add(7, 'days').format('YYYY-MM-DD'),
            })
        } else {
            setParams({
                ...params,
                startDate: moment(params.startDate).subtract(7, 'days').format('YYYY-MM-DD'),
                endDate: moment(params.endDate).subtract(7, 'days').format('YYYY-MM-DD'),
            })
        }
    }

    return (

        <MainCard isSingleCard={true}>
            {/* ********************************************************************** */}
            {/* NAVIGASYON PANEL */}
            {/* ********************************************************************** */}
            <Grid container item sx={{ ...styles.subTitle }} xs={12}>
                <NavigationPanel panels={panels}></NavigationPanel>
            </Grid>


            <Grid container item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: '30px' }}>
                <Grid item sx={{ alignSelf: 'left', marginRight: 'auto', ml: '20px', pb: "20px" }}>
                    {/* COLOR INFORMATION */}
                    <Grid container item xs={12} sx={{ display: 'block' }}>
                        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                            <Grid item sx={{ ...styles.filledCell, height: '15px', width: '30px', mr: '5px' }} />
                            <Typography variant="body1" sx={{ ...styles.lightText, fontSize: '14px' }}>{t('APPROVED')}</Typography>
                        </Grid>
                        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                            <Grid item sx={{ ...styles.inProgressCell, height: '15px', width: '30px', mr: '5px' }} />
                            <Typography variant="body1" sx={{ ...styles.lightText, fontSize: '14px' }}>{t('IN_PROGRESS')}</Typography>
                        </Grid>
                        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                            <Grid item sx={{ ...styles.pendingCell, height: '15px', width: '30px', mr: '5px' }} />
                            <Typography variant="body1" sx={{ ...styles.lightText, fontSize: '14px' }}>{t('PENDING')}</Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item sx={{ display: 'flex', pb: "20px" }}>

                    {/* LEFT ARROW */}
                    <Grid
                        item
                        sx={{ ...styles.leftArrow, cursor: 'pointer', alignSelf: 'center' }}
                        onClick={() => handleWeekChange('left')}
                    ></Grid>

                    {/* CURRENT WEEK */}
                    <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px', alignSelf: 'center', width: '200px', textAlign: 'center' }}>
                        {moment(params.startDate).locale('tr').format('DD MMMM')} / {moment(params.endDate).locale('tr').format('DD MMMM')}
                    </Typography>

                    {/* RIGHT ARROW */}
                    <Grid
                        item
                        sx={{ ...styles.rightArrow, cursor: 'pointer', alignSelf: 'center', mr: "20px" }}
                        onClick={() => handleWeekChange('right')}
                    ></Grid>
                </Grid>
            </Grid>

            {/* ********************************************************************** */}
            {/* CALENDAR */}
            {/* ********************************************************************** */}
            <TableContainer component={Paper} sx={styles.tableContainer}>
                <Table>
                    {/* Days of the week */}
                    <TableHead>
                        <TableRow sx={styles.headerRow}>
                            <TableCell sx={styles.boldText}></TableCell> {/* Empty cell for alignment */}
                            {/* Columns headers (dates)  */}
                            {days.map((day, index) => (
                                <TableCell key={index} align="center" sx={{ ...styles.boldText, width: '300px' }}>
                                    <Typography variant="body1" sx={styles.boldText}>{day.name}</Typography>
                                    {/* Different color for today's date */}
                                    <Typography
                                        variant="body3"
                                        sx={moment(params.startDate).add(index, 'days').format('DD-MM-YYYY') === moment().format('DD-MM-YYYY') ? { ...styles.boldText, color: 'rgb(2, 117, 216)' } : styles.boldText}
                                    >
                                        {moment(params.startDate).add(index, 'days').format('DD-MM-YYYY')}
                                    </Typography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {/* Time slots and calendar slots */}
                    <TableBody>
                        {slots.map((slot, slotIndex) => (
                            <TableRow key={slotIndex}>
                                <TableCell key={slotIndex} align="center" sx={{ ...styles.boldText, width: '150px' }}>{slot.START} - {slot.END}</TableCell>
                                {rows.map((row, rowIndex) => (
                                    <TableCell key={rowIndex} align="center" sx={styles.cell}>
                                        {/* Iterate through the slots of the rows to find matching slot for each. */}
                                        {row.slots.find(slot => slot?.slotNumber === slotIndex + 1)?.value}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>


        </MainCard>
    )

}


export default VisitCalendar