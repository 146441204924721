// React
import React, { useEffect, useState } from 'react'
// Libraries
import { useNavigate } from 'react-router'

// Utils
import { COOKIES, ROLES, ROUTES } from '../utils/constants'

// MUI
import { Grid } from '@mui/material'

// Custom Components

// Redux
import { useDispatch, useSelector } from 'react-redux'
import cookie from "react-cookies"
import UserService from '../services/UserService'
import { setUser } from '../redux/user';

// Services

const PublicRoute = (props) => {
    const { element: Element, sendToLogin } = props
    const user = useSelector((state) => state.user);
    const navigate = useNavigate();
    const [userData, setUserData] = useState('');
    const userService = new UserService();
    const dispatch = useDispatch()

    useEffect(() => {
        if (sendToLogin) {
            navigate(ROUTES.INDEX)
        }
    }, [])

    useEffect(() => {
        if (user?.type) {
            setUser(user)
            navigate(ROUTES.USER_MENU)
        }
    }, [user])

    const manageNavigate = () => {

        if (user.type === ROLES.SUPER_ADMIN) {
            navigate(ROUTES.USER_MENU)
        }
        else if (user.type === ROLES.AUTHORIZED_USER) {
            navigate(ROUTES.USER_MENU)
        }
        else if (user.type === ROLES.PARTNER_COMPANY_AUTHORIZED_USER) {
            navigate(ROUTES.STANDARD_USER_MENU)
        }
        else if (user.type === ROLES.STANDARD_USER) {
            navigate(ROUTES.STANDARD_USER_MENU)
        }
        else if (user.type === ROLES.SECURITY_GUARD) {
            navigate(ROUTES.SECURITY_MENU)
        }
        else if (user.type === ROLES.OPERATION_RESPONSIBLE) { //company user
            navigate(ROUTES.STANDARD_USER_MENU)
        }
    }

    if (!userData) return <Grid container>
        {Element &&
            <Element />
        }
    </Grid>
    else return null
}

export default PublicRoute
