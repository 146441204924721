// React
import React, { useEffect, useState } from 'react'

// Libraries
import { Navigate } from "react-router-dom";

// Utils
import { ROUTES } from '../utils/constants'

// MUI
import { Grid } from '@mui/material'

// Custom Components
import NavBar from '../UI/NavBar';

// Redux
import { useSelector } from 'react-redux'

// Services



const ProtectedRoute = (props) => {
    const { element: Element } = props
    const [userData, setUserData] = useState("");

    const user = useSelector((state) => state.user);


    return (
        user ?
            Element ?
                <Grid container>
                    {/* {!redirect && */}
                    <Element />
                    {/* } */}
                </Grid>
                :
                <Navigate to={ROUTES.NO_PAGE} replace />
            :
            <Navigate to={ROUTES.INDEX} replace />
    )
}

export default ProtectedRoute