//REACT
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, } from 'react-redux';
import { useState, useEffect } from "react";

//MUI
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

//LIBRARY
import YellowButton from '../../UI/YellowButton';
import MainCard from '../../UI/MainCard';
import CustomDataGrid from '../../UI/CustomDataGrid';
import { APPOINTMENT_STATUSES, APPOINTMENT_TYPES, ROUTES } from '../../utils/constants'
import { DEFAULT_THEME } from '../../utils/useThemes';
import { setMessageAndButtonText, hidePopup } from "../../redux/popup"
import NavigationPanel from '../../UI/NavigationPanel';
import AppointmentService from '../../services/AppointmentService';
import moment from 'moment';

const styles = {
    strongText: {
        fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
        fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
        fontSize: DEFAULT_THEME.typography.fontSize.regular
    },
    lightText: {
        fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
        fontWeight: DEFAULT_THEME.typography.fontWeight.regular,
        fontSize: DEFAULT_THEME.typography.fontSize.light
    },

}

const VisitDailyWeeklyFirm = () => {
    const appointmentService = new AppointmentService()

    const navigate = useNavigate();
    const location = useLocation()
    const whichScreen = location.state?.request
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [panels, setPanels] = useState(["Yönetim Paneli", "Raporlama"])
    const dateNow = moment().format('YYYY-MM-DD')
    const [weekBefore, setWeekBefore] = useState(moment().subtract(1, 'weeks').format('YYYY-MM-DD'))
    const nextWeekDate = moment().add(1, 'weeks').format('YYYY-MM-DD')
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(8);
    const [appointments, setAppointments] = useState([])
    const [params, setParams] = useState({
        page,
        limit,
        appointmentType: APPOINTMENT_TYPES.VISIT,
        startDate: weekBefore,
        endDate: nextWeekDate
    })
    const [totalCount, setTotalCount] = useState("")

    useEffect(() => {
        getAppointments(params);

    }, [params])

    const getAppointments = async ({ params }) => {
        console.log("params", params)
        const result = await appointmentService.getAppointments(params)
        if (result && result.data)
            setAppointments(result.data)
        setTotalCount(result.count)
    }

    const getActionColumn = (object) => {
        return (
            <YellowButton
                style={{ height: '30px', width: '110px', borderRadius: 5, fontSize: 11 }}
                onClick={() => dispatch(setMessageAndButtonText({
                    'title': object.visit?.firstName + " " + object.visit?.lastName + " " + object.appointmentDate, 'noButtonText': t("commonHideButton"), noButtonAction: () => { dispatch(hidePopup()) }, 'text': object.description, "isErrorMessage": true
                }))}>
                {t('commonShowDescription')}
            </YellowButton>
        );
    };

    //örnek column oluşturma
    const columns = [
        {
            field: 'details',
            headerName: 'Durum',
            minWidth: 220,
            renderCell: (params) => getActionColumnDetail(params.row)
        },
        {
            field: 'description',
            headerName: 'Açıklama ',
            minWidth: 150,
            renderCell: (params) => getActionColumn(params.row)
        },
        {
            field: 'company',
            headerName: 'Şirket',
            minWidth: 170,
            renderCell: (params) => (
                <Grid container>
                    <Typography>{params.row.company?.name}</Typography>
                </Grid>
            ),
        },
        {
            field: 'firstName',
            headerName: t('firstName2'),
            minWidth: 170,
            renderCell: (params) => params.row.visit?.firstName
        },
        {
            field: 'lastName',
            headerName: t('lastName'),
            minWidth: 170,
            renderCell: (params) => params.row.visit?.lastName
        },
        {
            field: 'appointmentDate',
            headerName: "Ziyaret Tarihi",
            minWidth: 190,
            // renderCell: (params) => params.row.appointmentDate -> not needed since the field name matched with the data key
        },
        {
            field: 'appointmentTime',
            headerName: "Ziyaret Saati",
            minWidth: 150,
        },
        {
            field: 'arrivalTime',
            headerName: "Varış Saati",
            minWidth: 190,
        },
        {
            field: 'estimatedExitTime',
            headerName: "Tahmini Çıkış Saati",
            minWidth: 200,
        },
        {
            field: 'appointmentReason',
            headerName: "Görüşme Sebebi",
            minWidth: 190,
        },
        {
            field: 'phoneNumber',
            headerName: t('phoneNumberLabel'),
            minWidth: 180,
            renderCell: (params) => params.row.visit?.phoneNumber
        },


    ];



    {/* function to allow us to do a operation for particular row in this case is a info about reservation operation */ }
    const getActionColumnDetail = (object) => {
        return (
            <Grid item >
                <Typography align='center' sx={{ height: '25px', width: '180px', fontWeight: DEFAULT_THEME.typography.fontWeight.bold, borderRadius: 5, color: 'black', backgroundColor: '#44BC15' }}> {t(`${object?.appointmentStatus}`)}</Typography>
            </Grid>
        );
    };

    return (
        <MainCard isSingleCard={true}>
            <Grid container item justifyContent="space-between">

                <Grid item xs={12} sx={{ mb: '2rem' }}>
                    <NavigationPanel panels={panels}></NavigationPanel>
                </Grid>
                <Grid item xs={12}>
                    <CustomDataGrid
                        rows={appointments}
                        columns={columns}
                        showFilterBar={true}
                        titleOfTable={t('firmVisits')}
                        getFunction={getAppointments}
                        // totalCount={totalCount}
                        params={params}

                    />
                </Grid>
            </Grid>




        </MainCard>

    );
}

export default VisitDailyWeeklyFirm;