//REACT
import { useTranslation } from 'react-i18next';
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//MUI
import { Grid, FormGroup } from '@mui/material';

//LIBRARY
import { ROLES, ROUTES } from '../../utils/constants'
import MainCard from '../../UI/MainCard';
import { DEFAULT_THEME } from '../../utils/useThemes';
import CustomTextField from '../../UI/CustomTextField';
import YellowButton from '../../UI/YellowButton';
import NavigationPanel from '../../UI/NavigationPanel';
import moment from 'moment';
import CustomDatePicker from '../../UI/CustomDatePicker';

// SERVICES
import AppointmentService from '../../services/AppointmentService';
import { objectCleaner } from '../../utils/helper';
import { showError, showSuccess } from '../../redux/snackbar';
import { modifyPlate } from '../../utils/modifyPlate';
import dayjs from 'dayjs';

const styles = {
    subTitle: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    strongText: {
        fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
        fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
        fontSize: DEFAULT_THEME.typography.fontSize.regular
    },
    lightText: {
        fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
        fontWeight: DEFAULT_THEME.typography.fontWeight.regular,
        fontSize: DEFAULT_THEME.typography.fontSize.light
    },
}

const ReservationEdit = (props) => {

    const navigate = useNavigate();
    const { t } = useTranslation();

    // initialize appointment service
    const appointmentService = new AppointmentService()

    const location = useLocation()
    const request = location.state?.request
    const user = useSelector((state) => state.user);

    if (!request.id)
        navigate(ROUTES.RESERVATION_TABLE)

    const [id, setId] = useState(request.id);
    const [firstName, setFirstName] = useState(request.visit?.firstName);
    const [lastName, setLastName] = useState(request.visit?.lastName);
    const [phoneNumber, setPhoneNumber] = useState(request.visit?.phoneNumber);
    const [plate, setPlate] = useState(request?.visit?.plate);

    const [appointmentReason, setAppointmentReason] = useState(request?.appointmentReason);
    const [appointmentDate, setAppointmentDate] = useState(dayjs(request?.appointmentDate, 'DD-MM-YYYY'))
    const [appointmentTime, setAppointmentTime] = useState(request?.appointmentTime);
    const [arrivalTime, setArrivalTime] = useState(request?.arrivalTime);
    const [actualExitTime, setActualExitTime] = useState(request?.actualExitTime);

    const [description, setDescription] = useState(request?.description);
    const dispatch = useDispatch()
    const [panels, setPanels] = useState([t('adminPanel'), t('reporting'), t('reservationList'), t('editingReservation')])

    const handleSubmit = async () => {

        const changedFields = returnChangedFields()

        if (changedFields && Object.keys(changedFields).length > 0) {
            if (!validateTimes())
                return
            try {
                const response = await appointmentService.updateAppointment(id, changedFields)
                if (response && response.success) {
                    dispatch(showSuccess({ message: t("successMessage") }))
                    navigate(ROUTES.DAILY_VISIT_TRACKING)
                }
            }
            catch (e) {
                console.log(e)
            }
        }
        else {
            dispatch(showError({ message: t("errorMessage") }))
            console.log('no changes')
            // TODO: show popup for no changes
        }

    }

    const returnChangedFields = () => {
        return (objectCleaner({
            firstName: firstName !== request?.visit?.firstName ? firstName : null,
            lastName: lastName !== request?.visit?.lastName ? lastName : null,
            phoneNumber: phoneNumber !== request?.visit?.phoneNumber ? phoneNumber : null,
            plate: plate !== request?.plate ? plate : null,
            appointmentDate: appointmentDate !== dayjs(request?.appointmentDate, 'DD-MM-YYYY') ? appointmentDate.format('YYYY-MM-DD') : null,
            appointmentReason: appointmentReason !== request?.appointmentReason ? appointmentReason : null,
            appointmentTime: appointmentTime !== request?.appointmentTime ? appointmentTime : null,
            arrivalTime: arrivalTime !== request?.arrivalTime ? arrivalTime : null,
            actualExitTime: actualExitTime !== request?.actualExitTime ? actualExitTime : null,
            description: description !== request?.description ? description : null,
        }))
    }

    const validateTimes = () => {
        if (arrivalTime > actualExitTime || actualExitTime < appointmentTime) {
            dispatch(showError({ message: t('timeErrorMessage') }))
            return false
        }
        else
            return true
    }

    return (

        <MainCard isSingleCard={true}>
            {/* ********************************************************************** */}
            {/* NAVIGASYON PANEL */}
            {/* ********************************************************************** */}
            <Grid container item sx={{ ...styles.subTitle }} xs={12}>
                <NavigationPanel panels={panels}></NavigationPanel>

            </Grid>


            {/* ********************************************************************** */}
            {/* INPUT FIELD */}
            {/* ********************************************************************** */}
            <Grid container xs={12} sx={{ mt: '2.5rem', justifyContent: "center" }}>

                <FormGroup sx={{ width: 'stretch' }}>


                    <Grid container xs={12} direction="column" justifyContent="space-between" >

                        {/* ********************************************************************** */}
                        {/* 1st ROW OF INPUT FIELD */}
                        {/* ********************************************************************** */}
                        <Grid item container justifyContent="space-between">
                            <Grid item xs={3.9}>
                                <CustomTextField
                                    label={t('firstName2')} disabled={true} value={firstName} setValue={setFirstName} placeholder={request?.name} />
                            </Grid>
                            <Grid item xs={3.9}>
                                <CustomTextField
                                    label={t('lastName')} disabled={true} value={lastName} setValue={setLastName} placeholder={request?.surname} />
                            </Grid>
                            <Grid item xs={3.9}>
                                <CustomTextField
                                    label={t('phoneNumberLabel')} value={phoneNumber} type="tel" setValue={setPhoneNumber} placeholder={request?.phone} />
                            </Grid>
                        </Grid>
                        {/* ********************************************************************** */}
                        {/* 2nd ROW OF INPUT FIELD */}
                        {/* ********************************************************************** */}
                        <Grid item container justifyContent="space-between" sx={{ mt: '2rem' }}>

                            <Grid item xs={3.9}>
                                <CustomTextField
                                    value={appointmentReason}
                                    label={t('authInputField4')}
                                    setValue={setAppointmentReason}
                                />
                            </Grid>

                            <Grid item xs={3.9}>
                                <CustomDatePicker
                                    label={t('authInputField6')}
                                    value={appointmentDate}
                                    setValue={setAppointmentDate}
                                />
                            </Grid>
                            <Grid item xs={3.9}>
                                <CustomTextField
                                    label={t('authInputField7')}
                                    type="time"
                                    value={appointmentTime}
                                    setValue={setAppointmentTime}


                                />
                            </Grid>
                        </Grid>

                        {/* 3RD ROW OF INPUT FIELD */}
                        <Grid item container sx={{ mt: '2rem', justifyContent: 'space-between' }}>

                            <Grid item xs={user?.type === ROLES.SECURITY_GUARD ? 3.9 : 5.9}>
                                <CustomTextField
                                    label={t('entranceTime')}
                                    type="time"
                                    value={arrivalTime}
                                    setValue={setArrivalTime}
                                />
                            </Grid>

                            <Grid item xs={user?.type === ROLES.SECURITY_GUARD ? 3.9 : 5.9}>
                                <CustomTextField
                                    label={t('exitTime')}
                                    type="time"
                                    value={actualExitTime}
                                    setValue={setActualExitTime}
                                />
                            </Grid>

                            {user?.type === ROLES.SECURITY_GUARD &&
                                <Grid item xs={3.9}>
                                    <CustomTextField
                                        value={modifyPlate(plate)}
                                        label={t('plateLabel')}
                                        setValue={setPlate}
                                        placeholder={t('plateLabel')}
                                    />
                                </Grid>}

                        </Grid>

                        {/* ********************************************************************** */}
                        {/* 4th ROW OF INPUT FIELD */}
                        {/* ********************************************************************** */}
                        <Grid item container sx={{ mt: '2rem', justifyContent: 'center' }}>
                            <CustomTextField
                                style={{
                                    [`& fieldset`]: {
                                        borderRadius: 5,
                                    },
                                }}
                                label={t('authInputField5')}
                                multiline
                                rows={6}
                                value={description}
                                setValue={setDescription}
                                placeholder={request?.description}
                            />
                        </Grid>

                        {/* ********************************************************************** */}
                        {/* BUTTON ROW*/}
                        {/* ********************************************************************** */}
                        <Grid item container sx={{ mt: '2rem' }} >
                            <Grid item xs={4} >
                                <YellowButton
                                    type="submit"
                                    onClick={() => handleSubmit()}
                                >
                                    {t('commonEdit')}
                                </YellowButton>
                            </Grid>

                        </Grid>
                    </Grid>

                </FormGroup>
            </Grid>


        </MainCard>
    )

}


export default ReservationEdit;