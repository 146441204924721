import car from '../images/noPageCar.jpg';
//REACT
import { useTranslation } from 'react-i18next';
//MUI
import { Typography, Grid } from '@mui/material';
import { DEFAULT_THEME } from '../utils/useThemes';
//LIBRARY


const styles = {

    container: {
        maxWidth: '100%',
        display: 'flex',
    },

    advertisements: {
        backgroundColor: 'white',
        color: 'white',
        maxWidth: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },

    title1: {
        display: 'inline-block',
        backgroundColor: 'black',
        color: 'white',
        fontWeight: '800',
        fontSize: '23px',
        fontFamily: "'NouvelR', sans-serif",
        fontStyle: 'normal',
        lineHeight: '171.15%',
        margin: '0px',
    },

    logo: {
        marginLeft: '5%',
        marginTop: '5%',
    },

    title2: {
        display: 'inline-block',
        backgroundColor: DEFAULT_THEME.palette.super_primary,
        color: 'black',
        fontWeight: '800',
        fontSize: '23px',
        fontFamily: "'NouvelR', sans-serif",
        fontStyle: 'normal',
        lineHeight: '171.15%',
        margin: '0px',
    },

    text: {
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '30px',
        /* or 60px */
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        color: 'black',
    },
    text2: {
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '20px',
        /* or 60px */
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        color: 'black',
        mt: '1.5rem'
    },


    carousel: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },

    car: {
        maxWidth: '45%',
    }

}

const NoPage = () => {

    const { t } = useTranslation();

    return (

        <Grid container sx={{ ...styles.container }}>
            <Grid container sx={{ ...styles.advertisements }}>

                {/* ************************************************************************/}
                {/*YPDM LOGO*/}
                {/* ************************************************************************/}
                <Grid item container sx={{ ...styles.logo }}>
                    <Grid item >
                        <Typography sx={{ ...styles.title1 }}>{t('logo1')}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography sx={{ ...styles.title2 }}>{t('logo2')}</Typography>
                    </Grid>
                </Grid>

                {/* ************************************************************************/}
                {/*CAR IMAGE AND*/}
                {/* ************************************************************************/}
                <Grid container sx={{ ...styles.carousel }}>
                    <img
                        style={styles.car}
                        src={car}
                        alt={'White Car'}
                        loading="lazy"
                    />
                    <Typography sx={{ ...styles.text }}>
                       {t('noPage1')}
                    </Typography>
                    <Typography sx={{ ...styles.text2 }}>
                        {t('noPage2')}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>


    )
}

export default NoPage;