//REACT
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";

//MUI
import { Grid, FormGroup } from '@mui/material';

//LIBRARY
import MainCard from '../../UI/MainCard';
import { DEFAULT_THEME } from '../../utils/useThemes';
import CustomTextField from '../../UI/CustomTextField';
import YellowButton from '../../UI/YellowButton';
import CustomDatePicker from '../../UI/CustomDatePicker';
import dayjs from 'dayjs';

// Services
import { modifyPlate } from "../../utils/modifyPlate";
import NavigationPanel from '../../UI/NavigationPanel';
import TrackingService from '../../services/TrackingService';
import { useDispatch } from 'react-redux';
import { showError } from '../../redux/snackbar';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../utils/constants';

const styles = {
    subTitle: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    strongText: {
        fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
        fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
        fontSize: DEFAULT_THEME.typography.fontSize.regular
    },
    lightText: {
        fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
        fontWeight: DEFAULT_THEME.typography.fontWeight.regular,
        fontSize: DEFAULT_THEME.typography.fontSize.light
    },
}

const ServiceCarAddOrEdit = () => {
    // initialize hooks
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    // initialize services
    const trackingService = new TrackingService()

    // variables coming from previous page
    const { request, isUpdate } = location?.state || {}

    // initialize states
    const [panels, setPanels] = useState([t('adminPanel'), t('serviceCars'), isUpdate ? "Servis Aracı Düzenle" : t('serviceCarAdd')])

    const [id, setId] = useState("");
    const [entrancePlate, setEntrancePlate] = useState("");
    const [exitPlate, setExitPlate] = useState("");
    const [entranceTime, setEntranceTime] = useState("");
    const [exitTime, setExitTime] = useState("");
    const [trackingDate, setTrackingDate] = useState(!isUpdate ? dayjs() : "");
    const [route, setRoute] = useState("");
    const [description, setDescription] = useState("");
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        if (isUpdate)
            getInfo();
    }, [request])

    useEffect(() => {
        if (isUpdate)
            setDisabled(
                !isAnyFieldChanged()
            )
    }, [entrancePlate, entranceTime, trackingDate, route, exitPlate, exitTime, description])

    useEffect(() => {
        if (!isUpdate)
            checkInputs()
    }, [entrancePlate, entranceTime, trackingDate, route])

    const getInfo = () => {
        setId(request?.id)
        setEntranceTime(request?.entranceTime)
        setEntrancePlate(request?.serviceTracking?.entrancePlate)
        setExitPlate(request?.serviceTracking?.exitPlate)
        setRoute(request?.serviceTracking?.route)
        setExitTime(request?.exitTime)
        setTrackingDate(dayjs(request?.trackingDate, 'DD-MM-YYYY'))
        setDescription(request?.description)
    }

    const checkInputs = () => {
        setDisabled(!(entrancePlate && entranceTime && trackingDate && route))
    }

    const isAnyFieldChanged = () => {
        return isUpdate &&
            (
                request?.serviceTracking?.entrancePlate !== entrancePlate ||
                request?.serviceTracking?.exitPlate !== exitPlate ||
                request?.serviceTracking?.route !== route ||
                request?.exitTime !== exitTime ||
                request?.entranceTime !== entranceTime ||
                request?.trackingDate !== trackingDate.format('DD-MM-YYYY') ||
                request?.description !== description
            )
    }

    const onSubmitAppointment = async () => {
        const month = 'JAN' // get month from date

        const trackingType = "SERVICE"
        const trackingObj = {
            entrancePlate,
            exitPlate,
            entranceTime,
            exitTime,
            trackingDate: trackingDate.format('YYYY-MM-DD'),
            description,
            route,
            month,
            trackingType
        }
        if (isUpdate) {
            await updateTrackingAppointment(trackingObj)
        }
        else {
            await createTrackingAppointment(trackingObj)

        }


    }
    // action function 
    const createTrackingAppointment = async (tracking) => {

        const result = await trackingService.createTracking(tracking)

        if (result) {
            navigate(ROUTES.SERVICE_CARS_TRACKING)
        }
        else
            dispatch(showError({ message: t("errorMessageTitle") }));
    }

    const updateTrackingAppointment = async (tracking) => {

        const result = await trackingService.updateTracking(id, tracking)

        if (result) {
            navigate(ROUTES.SERVICE_CARS_TRACKING)
        }
        else
            dispatch(showError({ message: t("errorMessageTitle") }));
    }

    return (

        <MainCard isSingleCard={true}>
            {/* ********************************************************************** */}
            {/* NAVIGASYON PANEL */}
            {/* ********************************************************************** */}
            <Grid container item sx={{ ...styles.subTitle }} xs={12}>
                <NavigationPanel panels={panels}></NavigationPanel>
            </Grid>


            {/* ********************************************************************** */}
            {/* INPUT FIELD */}
            {/* ********************************************************************** */}
            <Grid container xs={12} sx={{ mt: '2.5rem', justifyContent: "center" }}>

                <FormGroup sx={{ width: 'stretch' }} onChange={() => checkInputs()} >

                    {/* ********************************************************************** */}
                    {/* 1st ROW OF INPUT FIELD */}
                    {/* ********************************************************************** */}
                    <Grid container xs={12} direction="column" justifyContent="space-between" >

                        <Grid item container justifyContent="space-between">
                            <Grid item xs={3.9}>
                                <Grid item xs={12}>
                                    <CustomDatePicker
                                        label="Tarih"
                                        value={trackingDate}
                                        setValue={setTrackingDate}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={3.9}>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        placeholder="Güzergah"
                                        label="Güzergah"
                                        value={route}
                                        setValue={setRoute}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item xs={3.9}>
                                <CustomTextField
                                    placeholder={t('entranceCarPlate')} label={t('entranceCarPlate')} value={modifyPlate(entrancePlate)} setValue={setEntrancePlate} />
                            </Grid>


                        </Grid>

                        {/* ********************************************************************** */}
                        {/* 2nd ROW OF INPUT FIELD */}
                        {/* ********************************************************************** */}
                        <Grid item container justifyContent="space-between" sx={{ mt: '2rem' }}>
                            <Grid item xs={3.9}>
                                <CustomTextField
                                    placeholder={t('morningEntryTime')} type="time" label={t('morningEntryTime')} value={entranceTime} setValue={setEntranceTime} />
                            </Grid>
                            <Grid item xs={3.9}>
                                <CustomTextField
                                    placeholder={t('exitCarPlate')} label={t('exitCarPlate')} value={modifyPlate(exitPlate)} setValue={setExitPlate} />
                            </Grid>

                            <Grid item xs={3.9}>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        placeholder={t('eveningExitTime')}
                                        label={t('eveningExitTime')}
                                        value={exitTime}
                                        setValue={setExitTime}
                                        type="time"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* ********************************************************************** */}
                        {/* 3rd ROW OF INPUT FIELD */}
                        {/* ********************************************************************** */}
                        <Grid item container justifyContent="space-between" sx={{ mt: '2rem' }}>
                            <CustomTextField
                                placeholder={t('description')} isDescriptionTF={true} multiline rows={5} label={t('description')} value={description} setValue={setDescription} />
                        </Grid>


                        {/* ********************************************************************** */}
                        {/* BUTTON */}
                        {/* ********************************************************************** */}
                        <Grid item sx={{ mt: '2rem' }}>
                            <Grid item xs={4}>

                                <YellowButton
                                    disabled={disabled}
                                    type="submit"
                                    onClick={() => onSubmitAppointment()}
                                >
                                    {isUpdate ? "Güncelle" : t('commonAddButton')}
                                </YellowButton>
                            </Grid>


                        </Grid>
                    </Grid>

                </FormGroup>
            </Grid >


        </MainCard >
    )
}
export default ServiceCarAddOrEdit;
