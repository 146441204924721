//REACT
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

//MUI
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Grid, IconButton, Typography, useMediaQuery } from '@mui/material';

//OUR LIBRARY
import { APPOINTMENT_STATUSES, ROUTES, RESPONSE_TYPES, ROLES } from '../../utils/constants'
import MainCard from '../../UI/MainCard';
import { DEFAULT_THEME, useThemes } from '../../utils/useThemes';
import YellowButton from '../../UI/YellowButton';
import CustomDataGrid from '../../UI/CustomDataGrid';
import AppointmentService from '../../services/AppointmentService'
import { APPOINTMENT_TYPES } from '../../utils/constants';
import { showError } from '../../redux/snackbar';
import NavigationPanel from "../../UI/NavigationPanel";
import { hidePopup, setMessageAndButtonText } from "../../redux/popup";
import { displayPhone, displayPlate } from "../../utils/helper";
import dayjs from "dayjs";

// UTILS
import clsx from 'clsx';

const LoadReservationTable = (props) => {


    const darkMode = useSelector(state => state.darkMode)
    const currentUser = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const getTheme = useThemes()
    const isPhone = useMediaQuery(getTheme().breakpoints.down('phone'));

    const navigate = useNavigate();
    const { t } = useTranslation();
    const [appointments, setAppointments] = useState([])

    const [totalCount, setTotalCount] = useState(0)
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(8);
    const [params, setParams] = useState({
        page,
        limit,
        appointmentType: APPOINTMENT_TYPES.OPERATION,
        appointmentDate: currentUser.type === ROLES.SECURITY_GUARD || currentUser.type === ROLES.OPERATION_RESPONSIBLE ? dayjs().format('YYYY-MM-DD') : {}
    })

    const [isEmpty, setIsEmpty] = useState(false)
    const [panels, setPanels] = useState([t('adminPanel'), t('freightOperations'), t('freightAppointments')])

    const appointmentStatusOptions = Object.keys(APPOINTMENT_STATUSES).map((status) => {
        return { value: status, label: t(status) }
    })

    // initialize services
    const appointmentService = new AppointmentService()

    // service call is made 
    const fetchAppointments = async (params) => {
        try {
            const fetchedAppointments = await appointmentService.getAppointments(params)
            if (fetchedAppointments) {
                setAppointments(fetchedAppointments.data)
                setTotalCount(fetchedAppointments.count)
            }
            return fetchedAppointments.data
        }
        catch (err) {
            console.log(err)
        }
    }

    const onDownloadAll = async () => {
        try {
            const fetchedAppointments = await appointmentService.getAppointments({ ...params, limit: totalCount })
            if (fetchedAppointments) {
                setAppointments(fetchedAppointments.data)
                setTotalCount(fetchedAppointments.count)
            }
            return fetchedAppointments.data
        }
        catch (err) {
            console.log(err)
        }
    }

    const styles = {
        subtitle: {
            backgroundColor: darkMode ? 'black' : 'white',
            backdropFilter: 'blur(12px)',
            alignItems: 'center',
            borderStyle: 'solid',
            borderColor: '#EAEEF7',
            height: isPhone ? '120px' : '85px',
            borderRadius: 10,
            p: 2,
        },
        topGrid: {
            p: 4,
            borderWidth: '1px',
            borderRadius: '40px',
            borderStyle: 'solid',
            borderColor: 'white',
            backgroundColor: darkMode ? 'black' : 'white'
        },
        bottomGrid: {
            p: 4,
            borderWidth: '1px',
            borderRadius: '40px',
            borderStyle: 'solid',
            borderColor: 'white',
            backgroundColor: darkMode ? 'black' : 'white',
            mt: '2rem'
        },
        strongText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
            fontSize: DEFAULT_THEME.typography.fontSize.regular
        },
        lightText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.regular,
            fontSize: DEFAULT_THEME.typography.fontSize.light
        },
        // Color classes for different appointment statuses
        [APPOINTMENT_STATUSES.PENDING]: {
            color: 'rgba(255, 255, 255, 0.8)',
            backgroundColor: 'rgba(128, 128, 128, 1)',
            borderRadius: 5
        },
        [APPOINTMENT_STATUSES.APPROVED]: {
            color: 'rgba(255, 255, 255, 0.8)',
            backgroundColor: '#1E8449',
            borderRadius: 5
        },
        [APPOINTMENT_STATUSES.REJECTED]: {
            color: '#000000',
            backgroundColor: '#ff0000',
            borderRadius: 5
        },
        [APPOINTMENT_STATUSES.COMPLETED]: {
            color: 'rgba(255, 255, 255, 0.8)',
            backgroundColor: '#1e6091',
            borderRadius: 5
        },
        [APPOINTMENT_STATUSES.CANCELLED]: {
            color: 'rgba(255, 255, 255, 0.8)',
            backgroundColor: '#1e6091',
            borderRadius: 5
        },
        [APPOINTMENT_STATUSES.IN_PROGRESS]: {
            color: 'rgba(255, 255, 255, 0.8)',
            backgroundColor: '#ff8c00',
            borderRadius: 5
        },
    }

    const actionsButtonsDisabled = (object) => {
        return object.appointmentStatus === APPOINTMENT_STATUSES.CANCELLED || object.appointmentStatus === APPOINTMENT_STATUSES.REJECTED || object.appointmentStatus === APPOINTMENT_STATUSES.COMPLETED ||
            (currentUser.role === ROLES.PARTNER_COMPANY_AUTHORIZED_USER && object.appointmentStatus === APPOINTMENT_STATUSES.APPROVED)
    }

    const getActionColumn = (object) => {
        return (
            <YellowButton
                style={{ height: '30px', width: '110px', borderRadius: 5, fontSize: 11 }}
                onClick={() => dispatch(setMessageAndButtonText({
                    'title': object.freight?.plate, 'noButtonText': t("commonHideButton"), noButtonAction: () => { dispatch(hidePopup()) }, 'text': object.description, "isErrorMessage": true
                }))}>
                {t('commonShowDescription')}
            </YellowButton>
        );
    };


    {/* function to allow us to do a operation for particular row in this case is a delete and edit operation */ }
    const getActionColumnDetail = (object) => {
        return (
            <Grid container item justifyContent={"space-evenly"}>
                <Grid item>
                    <IconButton
                        style={{
                            color: actionsButtonsDisabled(object) ? 'primary' : '#2980b9'
                        }}
                        onClick={() => navigate(ROUTES.LOAD_RESERVATION_EDIT, { state: { request: object } })}
                        disabled={actionsButtonsDisabled(object)}
                    >
                        <EditIcon />
                    </IconButton>
                </Grid>
                <Grid item>
                    <IconButton aria-label="delete"
                        style={{
                            color: actionsButtonsDisabled(object) ? 'primary' : '#E45B5B'
                        }}
                        onClick={() => dispatch(setMessageAndButtonText({
                            'title': t("deleteTitle"),
                            'text': t("deleteText"),
                            'noButtonText': t("commonCancelButton"),
                            noButtonAction: () => {
                                dispatch(hidePopup())
                            },
                            'yesButtonText': t("commonDelete"),
                            yesButtonAction: () => {
                                deleteRow(object);
                                dispatch(hidePopup())
                            },
                            "isErrorMessage": false
                        }))}
                        disabled={actionsButtonsDisabled(object)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Grid>

            </Grid>

        );
    };



    {/*Delete the one row which was clicked*/ }
    const deleteRow = async (object) => {
        const body = { appointmentStatus: APPOINTMENT_STATUSES.CANCELLED }


        // valid appointment update object
        if (object.id && body) {

            // cancel appointment
            const result = await appointmentService.updateAppointment(object.id, body)

            if (result.status === RESPONSE_TYPES.success) {
                // update appointment list by changing appointment status
                const updatedAppointments = appointments.map((appointment) => {
                    if (appointment.id === object.id) {
                        appointment.appointmentStatus = result?.data?.appointmentStatus
                    }
                    return appointment
                })
                setAppointments(updatedAppointments)
            }

            else {
                dispatch(showError({ message: t("errorMessageTitle") }))
            }
        }
    };

    const columnsForBoth = [
        {
            // field: 'operation',
            // headerName: 'İşlem ',
            minWidth: 100,
            renderCell: (params) => getActionColumnDetail(params.row),
            filterable: false,
        },
        {
            field: 'company',
            headerName: 'Şirket',
            minWidth: 170,
            filterable: false,
            renderCell: (params) => (
                <Grid container>
                    <Typography>{params.row.company?.name}</Typography>
                </Grid>
            ),
        },
        {
            field: 'plate',
            headerName: t('towTruckPlate'),
            minWidth: 200,
            renderCell: (params) => displayPlate(params.row.freight?.plate),
        },
        {
            field: 'trailerNumber',
            headerName: t('truckPlateLabel'),
            minWidth: 180,
            renderCell: (params) => displayPlate(params.row.freight?.trailerNumber)
        },
        {
            field: 'phone',
            headerName: t('phoneNumberLabel'),
            width: 220,
            filterable: false,
            renderCell: (params) => displayPhone(params.row.company?.phone)
        },
        {
            field: 'appointmentDate',
            headerName: t('appointmentDate'),
            minWidth: 180,
            // filterable: false,
            type: 'date',
            filterOperator: ['=', '>=', '<='],
            renderCell: (params) => dayjs(params.row.appointmentDate, 'DD-MM-YYYY').format('DD/MM/YYYY')
        },
        {
            field: 'appointmentTime',
            headerName: t('appointmentTime'),
            minWidth: 180,
            filterable: false,
            renderCell: (params) => params.row.appointmentTime?.slice(0, 5)
        },
        {
            field: 'appointmentStatus',
            headerName: 'Randevu Durumu',
            minWidth: 200,
            // filterable: false,
            type: 'singleSelect',
            valueOptions: appointmentStatusOptions,
            cellClassName: (params) => {
                return clsx(params.row.appointmentStatus)
            },

            renderCell: (params) => t(`${params.row.appointmentStatus}`),
        },
        {
            field: 'freightArea',
            headerName: t('freightArea'),
            minWidth: 270,
            filterable: false,
            renderCell: (params) => t(`${params.row.freight?.freightArea}`)
        },
        {
            field: 'gateNumber',
            headerName: t('gateNumber'),
            minWidth: 150,
            filterable: false,
            renderCell: (params) => params.row.freight?.gateNumber
        },
        {
            field: 'route2',
            headerName: t('route2'),
            minWidth: 150,
            filterable: false,
            renderCell: (params) => params.row.freight?.route
        },
        {
            field: 'arrivalTime',
            headerName: 'Varış Saati',
            minWidth: 180,
            filterable: false,
            renderCell: (params) => params.row.arrivalTime?.slice(0, 5)
        }, {
            field: 'entranceTime',
            headerName: 'Giriş Saati',
            minWidth: 180,
            filterable: false,
            renderCell: (params) => params.row.entranceTime?.slice(0, 5)
        },
        {
            field: 'actualExitTime',
            headerName: 'Çıkış Saati',
            minWidth: 180,
            filterable: false,
            renderCell: (params) => params.row.actualExitTime?.slice(0, 5)
        },
        {
            field: 'description',
            headerName: t('description'),
            minWidth: 150,
            filterable: false,
            renderCell: (params) => getActionColumn(params.row)
        },

    ];


    return (
        <MainCard isSingleCard={false}>

            <Grid container item sx={{ ...styles.subtitle }}>
                <NavigationPanel panels={panels}></NavigationPanel>
            </Grid>

            <Grid container sx={{
                ...styles.bottomGrid,
                '& .PENDING': styles[APPOINTMENT_STATUSES.PENDING],
                '& .APPROVED': styles[APPOINTMENT_STATUSES.APPROVED],
                '& .REJECTED': styles[APPOINTMENT_STATUSES.REJECTED],
                '& .COMPLETED': styles[APPOINTMENT_STATUSES.COMPLETED],
                '& .CANCELLED': styles[APPOINTMENT_STATUSES.CANCELLED],
                '& .IN_PROGRESS': styles[APPOINTMENT_STATUSES.IN_PROGRESS],
            }}>
                <Grid item xs={12}>
                    <CustomDataGrid
                        rows={appointments}
                        getFunction={fetchAppointments}
                        columns={columnsForBoth}
                        showFilterBar={true}
                        titleOfTable={t('freightAppointments')}
                        params={params}
                        totalCount={totalCount}
                        onDownloadAll={onDownloadAll}

                    />
                </Grid>
            </Grid>



        </MainCard >
    )

}


export default LoadReservationTable;