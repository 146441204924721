
import * as React from 'react';
import { Divider } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
// import NotificationBell from './NotificationBell';
//REACT
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
//MUI
import { Typography, Grid, Link, Box, Badge } from '@mui/material';
import { DEFAULT_THEME } from '../utils/useThemes';
import { useNavigate } from 'react-router-dom';
import { COOKIES, ROLES, ROUTES } from '../utils/constants';

//REDUX
import { setDarkMode } from '../redux/darkMode';
import { useDispatch, useSelector } from "react-redux";
import { setUser } from '../redux/user';

//LIBRARY
import cookie from "react-cookies"
import UserService from '../services/UserService';
import CustomLinearProgress from './CustomLinearProgress';

import wareManageLogo from '../images/wm.png'



const NavBar = (props) => {
    const { isGuest = false } = props

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const dispatch = useDispatch();
    const userService = new UserService();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const currentMode = useSelector(state => state.darkMode)
    const user = useSelector(state => state.user)
    const loading = useSelector(state => state.loading)

    const [darkModeChecked, setDarkModeChecked] = useState(currentMode);


    const styles = {

        menuItem: {
            color: 'black',
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '5%',
            marginTop: '5%',
            borderColor: 'rgba(231, 231, 231, 0.35)',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderRadius: '15px',
            alignItems: 'center',
            boxShadow: '0px 0px 23px 2px rgba(0, 0, 0, 0.05);',
            padding: '5% 15% 5% 15%',

        },
        title1: {
            backgroundColor: 'black',
            borderBottomLeftRadius: 3,
            borderTopLeftRadius: 3,
            color: 'white',
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
            fontSize: DEFAULT_THEME.typography.fontSize.extra_large,
            padding: '2px 4px 2px 4px',
        },

        title2: {
            backgroundColor: DEFAULT_THEME.palette.super_primary,
            color: 'black',
            borderBottomRightRadius: 3,
            borderTopRightRadius: 3,
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
            fontSize: DEFAULT_THEME.typography.fontSize.extra_large,
            padding: '2px 4px 2px 4px',
        },

        navItem: {

            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.bold,
            fontSize: DEFAULT_THEME.typography.fontSize.large,
            //color: 'black',
            paddingLeft: '3%',
            textDecoration: 'none',
            cursor: 'pointer'
        },

        navBar: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            background: 'linear-gradient(to right, #2980b9, #1f6693)',
            borderColor: currentMode ? 'white' : 'rgba(245, 245, 245, 0.199)',
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.bold,
            fontSize: DEFAULT_THEME.typography.fontSize.large,
            lineHeight: '171.15%',
            height: '70px',
        },

        notifications: {
            '&:hover': {
                backgroundColor: '#EAEEF7',
                borderRadius: '15px',
                cursor: 'pointer',
            },
            width: "45px",
            height: "45px",
        },

        wareManageLogo: {
            height: '4rem',
            width: 'auto',
            marginLeft: '1rem',
            cursor: 'pointer'
        }

    }

    const logOut = async () => {
        dispatch(setUser(""))
        const result = await userService.logOut()
        navigate(ROUTES.INDEX)
    }

    return (<>
        <Grid container>
            <CustomLinearProgress loading={loading} />
        </Grid>

        <Box sx={{ ...styles.navBar, justifyContent: 'space-between' }} xs={12}>

            <img
                src={wareManageLogo}
                alt='ware-manage'
                style={isGuest ? { ...styles.wareManageLogo, ...styles.navItem } : styles.wareManageLogo}
                onClick={isGuest ? () => navigate(ROUTES.INDEX) : () => navigate(ROUTES.USER_MENU)}
            />

            {/* ************************************************************************/}
            {/*ACCOUNT SETTINGS*/}
            {/* ************************************************************************/}
            {isGuest ? <Link sx={{ ...styles.navItem, color: 'white', fontWeight: 'bold', mr: '7.5rem' }} onClick={() => navigate(ROUTES.CONTACT_US)}> Bize Ulaşın</Link> : null}

            {isGuest ? null :
                <>
                    <Tooltip title="Account settings">
                        <IconButton
                            onClick={handleClick}
                            size="small"
                            sx={{ width: '50px', height: '50px', mr: '1rem' }}
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <Avatar sx={{ width: 42, height: 42 }}>{user?.firstName?.slice(0, 1) + user?.lastName?.slice(0, 1)}</Avatar>
                        </IconButton>
                    </Tooltip>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={(handleClose)}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >

                        <MenuItem onClick={() => navigate(ROUTES.USER_EDIT, { state: { request: user, self: true } })}>
                            <Avatar> {user?.firstName?.slice(0, 1) + user?.lastName?.slice(0, 1)} </Avatar>
                            Profile Git
                        </MenuItem>

                        <Divider />

                        {(user.type === ROLES.SUPER_ADMIN || user.type === ROLES.AUTHORIZED_USER) &&
                            <>
                                <MenuItem onClick={() => navigate(ROUTES.CREATE_USER_INTERNAL)}>
                                    <ListItemIcon>
                                        <PersonAdd fontSize="small" />
                                    </ListItemIcon>
                                    Yeni Bir Kullanıcı Oluştur
                                </MenuItem>

                                <MenuItem onClick={() => navigate(ROUTES.USERS_TABLE)}>
                                    <ListItemIcon>
                                        <Settings fontSize="small" />
                                    </ListItemIcon>
                                    Kullanıcı Ayarları
                                </MenuItem>
                            </>
                        }

                        <MenuItem onClick={() => logOut()}>
                            <ListItemIcon >
                                <Logout fontSize="small" />
                            </ListItemIcon>
                            Oturumu Kapat
                        </MenuItem>
                    </Menu>
                </>
            }
        </Box>

    </>
    );
}

export default NavBar;