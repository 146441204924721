import { ENDPOINTS } from "../utils/constants"
import axios from "../utils/interceptor"

export default class ContactRequestService {

    constructor() {
        this.endpoint = ENDPOINTS.CONTACT_REQUEST
    }
    createContactRequest = async (contactRequest) => {
        try {
            // Request
            let result = await axios.post(ENDPOINTS.CONTACT_REQUEST.BASE, contactRequest)

            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            //Return
            return result.data

        } catch (e) {
            return Promise.reject(e)
        }
    }
}