import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/system';
import { DROP_DOWN_TYPES } from '../utils/constants';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  backgroundColor: '#87CEFA',

}));

const GroupItems = styled('ul')({
  padding: 0,
});

export default function CustomDropdown(props) {
  const { style, data, disabled = false, defaultValue, label, value, setValue, setSearchParams, setSearchParamsLength, type = DROP_DOWN_TYPES.AUTO_COMPLETE } = props
  const styles = {
    textfield: {
      [`& fieldset`]: {
        borderRadius: 30,
      },
      ...style
    },
  }

  const { t } = useTranslation();

  const options = type === DROP_DOWN_TYPES.AUTO_COMPLETE ? data?.map((option) => {
    const firstLetter = option?.title[0]?.toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  }
  ) : data

  return (
    <>
      {type === DROP_DOWN_TYPES.AUTO_COMPLETE ?
        <Autocomplete
          id="grouped-demo"
          options={options?.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
          groupBy={(option) => option?.firstLetter}
          getOptionLabel={(option) => option?.title}
          disabled={disabled}
          defaultValue={defaultValue}
          renderInput={(params) => <TextField {...params} size="small" sx={{ ...styles.textfield }} label={label} />}
          renderGroup={(params) => (
            <li>
              <GroupHeader>{params.group}</GroupHeader>
              <GroupItems>{params.children}</GroupItems>
            </li>
          )}
          value={value}
          onChange={(event, newValue) => {
            if (setValue) {
              setValue(newValue)
            }
          }}
          onInputChange={(event, newInputValue) => {
            if (setSearchParams && setSearchParamsLength) {
              setSearchParamsLength(newInputValue.length)
              setSearchParams(newInputValue)
            }
          }}
        />
        :
        <FormControl fullWidth>
          <InputLabel id="freight-area-label">
            {label}
          </InputLabel>
          <Select
            value={value}
            defaultValue={defaultValue}
            label={label}
            onChange={(e) => {
              if (setValue) {
                setValue(e.target.value)
              }
            }
            }
            sx={{ ...styles.textfield, height: 40 }}
            disabled={disabled}
          >
            {options?.map((option, index) => {
              return (
                <MenuItem key={index} value={option?.value}>
                  {option?.title}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      }
    </>
  );
}
