//REACT
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from "react";
//MUI
import Grid from '@mui/material/Grid';
import { Typography, useMediaQuery } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';


//LIBRARY
import YellowButton from '../../UI/YellowButton'
import BlackButton from '../../UI/BlackButton';
import { APPOINTMENT_STATUSES, APPOINTMENT_TYPES, RESPONSE_TYPES, ROUTES } from '../../utils/constants'
import MainCard from '../../UI/MainCard';
import { DEFAULT_THEME, useThemes } from '../../utils/useThemes';
import CustomDataGrid from '../../UI/CustomDataGrid';
import { setMessageAndButtonText, hidePopup, } from "../../redux/popup"
import { showSuccess, showError } from '../../redux/snackbar';
// SERVICE
import AppointmentService from '../../services/AppointmentService';
import NavigationPanel from '../../UI/NavigationPanel';
import { useEffect } from 'react';



const LoadSaveInOut = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const darkMode = useSelector(state => state.darkMode)
    const getTheme = useThemes()
    const isPhone = useMediaQuery(getTheme().breakpoints.down('phone'));
    // initialize services 
    const appointmentService = new AppointmentService()


    // states
    const [totalCount, setTotalCount] = useState("")

    const [page, setPage] = useState(0)
    const [limit, setLimit] = useState(8)
    const [params, setParams] = useState({ page, limit, appointmentType: [APPOINTMENT_TYPES.LOAD, APPOINTMENT_TYPES.UNLOAD], appointmentStatus: APPOINTMENT_STATUSES.APPROVED })
    const [appointments, setAppointments] = useState([])
    const [appointmentId, setAppointmentId] = useState(0);
    const [panels, setPanels] = useState([t('adminPanel'), t('visitProcedures'), t('visitInOutDaily')])
    const styles = {
        topGrid: {
            p: 4,
            backgroundColor: darkMode ? 'black' : 'white',
            borderWidth: '1px',
            borderRadius: '30px',
            borderStyle: 'solid',
            backdropFilter: 'blur(12px)',
            justifyContent: 'space-around',
            width: 'stretch',
            //height: '300px',
            borderColor: '#E3E5EA',
            //boxShadow: "1px 2px #84868B"

        },
        bottomGrid: {
            p: 4,
            backgroundColor: darkMode ? 'black' : 'white',
            borderWidth: '1px',
            borderRadius: '30px',
            borderStyle: 'solid',
            backdropFilter: 'blur(12px)',
            justifyContent: 'space-around',
            width: 'stretch',
            borderColor: '#E3E5EA',
            mt: '20px',
        },
        subtitle: {
            backgroundColor: darkMode ? 'black' : 'white',
            backdropFilter: 'blur(12px)',
            alignItems: 'center',
            borderStyle: 'solid',
            borderColor: '#EAEEF7',
            height: isPhone ? '120px' : '85px',
            borderRadius: 10,
            p: 2,
        },
        strongText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: isPhone ? DEFAULT_THEME.typography.fontWeight.bold : DEFAULT_THEME.typography.fontWeight.extra_bold,
            fontSize: isPhone ? DEFAULT_THEME.typography.fontSize.light : DEFAULT_THEME.typography.fontSize.regular
        },
        lightText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.regular,
            fontSize: isPhone ? DEFAULT_THEME.typography.fontSize.very_light : DEFAULT_THEME.typography.fontSize.light
        },
    }



    useEffect(() => {
        getAppointments(params);

    }, [params])

    const getAppointments = async ({ params, pageOptions }) => {
        const result = await appointmentService.getAppointments({ ...params, page: pageOptions.page, limit: pageOptions.limit })
        if (result && result.data)
            setAppointments(result.data)
        setTotalCount(result.count)
    }

    const removeRow = async (object) => {
        setAppointmentId(object.id)
        const body = { appointmentStatus: "CANCELLED" }
        // valid appointment update object
        if (object.id && body) {
            const result = await appointmentService.updateAppointment(object.id, body)
            if (result.status === RESPONSE_TYPES.success) {
                const list = appointments.filter(item => item.id !== object.id)
                setAppointments(list);
                dispatch(showSuccess({ message: t("successMessageForDelete") }))

            }
            else {
                console.log(result)
                dispatch(showError({ message: t("errorMessageTitle") }))
            }
        }
    }

    {/* function to allow us to do a operation for particular row in this case is a delete and edit operation */ }
    const getActionColumnDetail = (object) => {
        return (
            <Grid container item justifyContent="space-between">
                <Grid item>
                    <BlackButton
                        icon={<EditIcon></EditIcon>}
                        style={{
                            height: '30px', width: '110px', borderRadius: 5
                        }}
                        onClick={() => navigate(ROUTES.LOAD_RESERVATION_EDIT, { state: { request: object } })}
                    >
                        {console.log(object)}
                        {t('commonEdit')}
                    </BlackButton>
                </Grid>
                <Grid item>
                    <BlackButton
                        icon={<DeleteOutlineIcon></DeleteOutlineIcon>}

                        style={{
                            height: '30px', width: '50px', borderRadius: 5, backgroundColor: "#E70909",
                        }}
                        onClick={() => removeRow(object)}
                    >
                        {t("commonDelete")}
                    </BlackButton>
                </Grid>
            </Grid>

        );
    };

    const getActionColumn = (object) => {
        return (
            <Grid item>
                <YellowButton

                    style={{
                        height: '30px', width: '110px', borderRadius: 5, fontSize: 11
                    }}
                    onClick={() => {
                        dispatch(setMessageAndButtonText({
                            'title': object.visit?.firstName + " " + object.visit?.lastName + " " + object.appointmentTime, 'noButtonText': t("commonHideButton"), noButtonAction: () => { dispatch(hidePopup()) }, 'text': object.description, "isErrorMessage": true
                        }))
                    }}>
                    {t('commonShowDescription')}
                </YellowButton>

            </Grid>
        );
    };
    //örnek column oluşturma
    const columns = [
        {
            field: 'operation',
            headerName: 'İşlem ',
            minWidth: 210,
            renderCell: (params) => getActionColumnDetail(params.row)
        },
        {
            field: "id",
            headerName: "ID",
            minWidth: 20,
            headerAlign: "center",
            //renderCell: (params) => getActionColumn(params.row)
        },
        {
            field: 'company',
            headerName: 'Şirket',
            minWidth: 170,
            renderCell: (params) => (
                <Grid container>
                    <Typography>{params.row.company?.name}</Typography>
                </Grid>
            ),
        },
        {
            field: 'phone',
            headerName: t('phoneNumberLabel'),
            width: 220,
            renderCell: (params) => params.row.company?.phone
        },
        {
            field: 'appointmentStatus',
            headerName: 'Randevu Durumu',
            minWidth: 200,
            renderCell: (params) => t(`${params.row.appointmentStatus}`)
        },
        {
            field: 'trailerNumber',
            headerName: t('truckPlateLabel'),
            minWidth: 180,
            renderCell: (params) => params.row.freight?.trailerNumber
        },
        {
            field: 'appointmentDate',
            headerName: 'Ziyaret Tarihi',
            minWidth: 180,
        },
        {
            field: 'freightArea',
            headerName: 'Görüşülecek Kısım',
            minWidth: 150,
            renderCell: (params) => params.row.freight?.freightArea
        },
        {
            field: 'peronNumber',
            headerName: 'Peron',
            minWidth: 80,
            renderCell: (params) => params.row.freight?.peronNumber
        },
        {
            field: 'appointmentTime',
            headerName: 'Ziyaret Saati',
            minWidth: 180,
        },
        {
            field: 'estimatedExitTime',
            headerName: 'Tahmini Çıkış Saati',
            minWidth: 180,
        }, {
            field: 'arrivalTime',
            headerName: 'Varış Saati',
            minWidth: 180,
        }, {
            field: 'entranceTime',
            headerName: 'Giriş Saati',
            minWidth: 180,
        }, {
            field: 'actualExitTime',
            headerName: 'Gerçek Çıkış Saati',
            minWidth: 180,
        },

    ];
    return (

        <MainCard isSingleCard={false}>

            {/* ********************************************************************** */}
            {/* NAVİGE PANEL*/}
            {/* ********************************************************************** */}
            <Grid container item xs={12} sx={{ ...styles.subtitle }}>
                <NavigationPanel panels={panels}></NavigationPanel>
            </Grid>


            {/* ********************************************************************** */}
            {/* 2nd CARD DATA GRID*/}
            {/* ********************************************************************** */}
            <Grid item container sx={{ ...styles.bottomGrid }} justifyContent='space-between'>

                {/* ********************************************************************** */}
                {/* DATAGRID*/}
                {/* ********************************************************************** */}
                <Grid container item sx={{ mt: isPhone ? '1.5rem' : '' }}>
                    <CustomDataGrid
                        rows={appointments}
                        columns={columns}
                        showFilterBar={true}
                        titleOfTable={t('visitInOutDaily')}
                        getFunction={getAppointments}
                        isAddButton={false}
                        totalCount={totalCount}
                        params={params}

                    />
                </Grid>
            </Grid>
        </MainCard >


    );
}

export default LoadSaveInOut