//REACT
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

//MUI
import { Grid, IconButton, useMediaQuery } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

//LIBRARY
import MainCard from '../../UI/MainCard';
import CustomDataGrid from "../../UI/CustomDataGrid";
import { DEFAULT_THEME, useThemes } from '../../utils/useThemes';
import { setMessageAndButtonText, hidePopup } from "../../redux/popup"
import { useState } from 'react';
import NavigationPanel from '../../UI/NavigationPanel';
import TrackingService from '../../services/TrackingService';
import { ROUTES, TRACKING_TYPES } from '../../utils/constants';
import YellowButton from '../../UI/YellowButton';
import EditIcon from '@mui/icons-material/Edit';
import dayjs from 'dayjs';
import BlackButton from '../../UI/BlackButton';
import { displayPlate } from '../../utils/helper';



const ServiceCarsTracking = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const getTheme = useThemes()

    const trackingService = new TrackingService()

    const darkMode = useSelector(state => state.darkMode)
    const isPhone = useMediaQuery(getTheme().breakpoints.down('phone'));

    const [rows, setRows] = useState([]);
    const [panels, setPanels] = useState([t('adminPanel'), t('serviceCars')])
    const [params, setParams] = useState({
        trackingType: t(TRACKING_TYPES.SERVICE),
        trackingDate: dayjs().format('YYYY-MM-DD'),
    });
    const [totalCount, setTotalCount] = useState(0)

    const styles = {
        strongText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
            fontSize: isPhone ? DEFAULT_THEME.typography.fontSize.light : DEFAULT_THEME.typography.fontSize.regular
        },
        lightText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.regular,
            fontSize: isPhone ? DEFAULT_THEME.typography.fontSize.very_light : DEFAULT_THEME.typography.fontSize.light
        },
        subtitle: {
            backgroundColor: darkMode ? 'black' : 'white',
            backdropFilter: 'blur(12px)',
            alignItems: 'center',
            borderStyle: 'solid',
            borderColor: '#EAEEF7',
            height: isPhone ? '100px' : '60px',
            borderRadius: 10,
            p: 2,
        },
        datagrid: {
            mt: '1rem',
            borderWidth: '1px',
            borderRadius: '40px',
            borderStyle: 'solid',
            borderColor: '#EAEEF7',
            p: 3,
            backgroundColor: darkMode ? 'black' : 'white',
        }
    }

    // service call for appointments with pagination
    const getTrackings = async (params) => {
        const response = await trackingService.getTrackings(params);
        if (response && response.data) {
            setRows(response.data)
            setTotalCount(response.count)
        }
    }

    const createDailyTracking = async () => {
        await trackingService.createTracking({
            trackingType: TRACKING_TYPES.SERVICE,
            bulkDaily: true
        });
    }

    //örnek column oluşturma
    const columns = [
        {
            field: 'operation',
            headerName: 'İşlem',
            minWidth: 100,
            renderCell: (params) => getActionColumnDetail(params.row)
        },
        {
            field: 'trackingDate',
            headerName: 'Tarih',
            width: 180,
            type: 'date',
            filterOperator: ['=', '>=', '<='],
            renderCell: (params) => dayjs(params.row.trackingDate, 'DD-MM-YYYY').format('DD/MM/YYYY')
        },
        {
            field: 'route',
            headerName: 'Güzergah',
            minWidth: 170,
            renderCell: (params) => params.row.serviceTracking?.route
        },
        {
            field: 'entrancePlate',
            headerName: 'Sabah Plakası',
            width: 220,
            renderCell: (params) => displayPlate(params.row.serviceTracking?.entrancePlate)

        },
        {
            field: 'entranceTime',
            headerName: 'Giriş Saati',
            minWidth: 180,
            renderCell: (params) => params.row.entranceTime?.slice(0, 5)
        },
        {
            field: 'exitPlate',
            headerName: 'Akşam Plakası',
            width: 220,
            renderCell: (params) => displayPlate(params.row.serviceTracking?.exitPlate)

        },
        {
            field: 'exitTime',
            headerName: 'Çıkış Saati',
            minWidth: 180,
            renderCell: (params) => params.row.exitTime?.slice(0, 5)
        },
        {
            field: 'details',
            headerName: 'Detay ',
            minWidth: 150,
            renderCell: (params) => getActionColumn(params.row)
        },

    ];


    const deleteRow = async (object) => {
        const result = await trackingService.deleteTracking(object.id);

        if (result) {
            const newRows = rows.filter((row) => row.id !== object.id);
            setRows(newRows);
        }
    };

    {/* function to allow us to do a operation for particular row in this case is a delete operation */ }
    const getActionColumnDetail = (object) => {
        return (
            <Grid container item justifyContent={"space-evenly"}>
                <Grid item>
                    <IconButton
                        title={t('edit')}
                        style={{
                            color: '#2980b9'
                        }}
                        onClick={() => navigate(ROUTES.SERVICE_CAR_ADD_OR_EDIT, { state: { request: object, isUpdate: true } })}
                    >
                        <EditIcon />
                    </IconButton>
                </Grid>
                <Grid item>
                    <IconButton
                        aria-label="delete"
                        title={t('delete')}
                        style={{
                            color: '#E45B5B'
                        }}
                        onClick={() => dispatch(setMessageAndButtonText({
                            'title': t("deleteTitle"),
                            'text': t("deleteText"),
                            'noButtonText': t("commonCancelButton"),
                            noButtonAction: () => {
                                dispatch(hidePopup())
                            },
                            'yesButtonText': t("commonDelete"),
                            yesButtonAction: () => {
                                deleteRow(object);
                                dispatch(hidePopup())
                            },
                            "isErrorMessage": false
                        }))}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Grid>
            </Grid>

        );
    };

    const getActionColumn = (object) => {
        return (
            <Grid item>
                <YellowButton

                    style={{
                        height: '30px', width: '110px', borderRadius: 5, fontSize: 11
                    }}
                    onClick={() => {
                        dispatch(setMessageAndButtonText({
                            'title': 'Açıklama', 'noButtonText': t("commonHideButton"), noButtonAction: () => { dispatch(hidePopup()) }, 'text': object.description, "isErrorMessage": true
                        }))
                    }}>
                    {t('commonShowDescription')}
                </YellowButton>

            </Grid>
        );
    };
    return (
        <MainCard>

            {/* ********************************************************************** */}
            {/* NAVIGASYON PANEL */}
            {/* ********************************************************************** */}
            <Grid container item xs={12} sx={{ ...styles.subtitle }}>
                <NavigationPanel panels={panels}></NavigationPanel>
            </Grid>


            <Grid container item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                {/* Create daily service vehicles */}
                <BlackButton
                    style={{
                        height: '4rem', width: '20rem', borderRadius: 5, fontSize: 15, marginTop: '1rem', backgroundColor: '#2E86C1'
                    }}
                    onClick={() => createDailyTracking()}
                >
                    {t('createServiceCarsDaily')}
                </BlackButton>

            </Grid>


            <Grid container item sx={{ ...styles.datagrid }}>
                <CustomDataGrid
                    rows={rows}
                    columns={columns}
                    showFilterBar={true}
                    titleOfTable={t('serviceCarsOperations')}
                    getFunction={getTrackings}
                    params={params}
                    totalCount={totalCount}

                />
            </Grid>

        </MainCard >

    )


}








export default ServiceCarsTracking;