import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { trTR, enUS } from '@mui/material/locale';
import { trTR as trDataGrid, enUS as usDataGrid } from '@mui/x-data-grid';
//import { trTR as trDatePicker, enUS as usDatePicker } from '@mui/x-date-pickers';



const DEFAULT_THEME = {
    palette: {
        white: "#FFFFFF",
        black: "#000000",
        darkGray: "#3E3F40",
        gray: "#8C8C8B",
        lightGray: "#D9D9D6",
        sideBar: '#F5F5F5',
        sideBarDark: '#3B3939',
        super_primary: "#EFDF00",
        super_primary_hover: "#F8EB4C",
        primary: "#000",
        primary_hover: "#EFDF00",
    },
    typography: {
        fontFamilyRegular: "NouvelR-Regular",
        fontSize: {
            very_light: '12px',
            light: "15px",
            regular: "17px",
            large: "20px",
            extra_large: "22px",
            extra_xlarge: "25px",
        },
        fontWeight: {
            regular: 300,
            bold: 550,
            extra_bold: 700,
            extra_bold2: 1000
        },
    },

};
const useThemes = () => {
    const { i18n } = useTranslation();
    const coreLanguage = i18n.language === "en" ? enUS : trTR
    const dataGridLanguage = i18n.language === "en" ? usDataGrid : trDataGrid
    //   const datePickerLanguage = i18n.language === "en" ? usDatePicker : trDatePicker

    function getTheme(isDark) {
        const theme = createTheme({
            palette: {
                mode: isDark ? 'dark' : 'light',
                primary: {
                    main: isDark ? DEFAULT_THEME.palette.white : DEFAULT_THEME.palette.primary
                },
                secondary: {
                    main: isDark ? DEFAULT_THEME.palette.white : DEFAULT_THEME.palette.primary

                },
                // background: {
                //     main: isDark ? DEFAULT_THEME.palette.mainCardDark : DEFAULT_THEME.palette.mainCard
                // },
                blue: {
                    main: "#1f2532",
                },
                common: {
                    transparent: "#ffffff00",
                },
                shape: {
                    borderRadius: 4,
                },
                success: {
                    main: DEFAULT_THEME.palette.super_primary
                },
            },

            typography: {
                fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            },
            breakpoints: {
                values: {
                    // extra-small
                    xs: 0,
                    phone: 427,
                    // small
                    sm: 600,
                    sm_extra: 772,
                    // medium
                    md: 900,
                    // large
                    lg: 1200,
                    lg_extra: 1267,
                    // extra-large
                    xl: 1536,
                    tablet: 640,
                    laptop: 1024,
                    lg_laptop: 1045,
                    desktop: 1200,
                    large_desktop: 1350,
                }
            },
            components: {

                MuiCheckbox: {
                    defaultProps: {
                        disableRipple: true
                    },
                    styleOverrides: {
                        root: {
                            "&.Mui-checked": {
                                color: DEFAULT_THEME.palette.primary
                            },
                        },
                    }
                },
                MuiListItem: {
                    defaultProps: {
                        disableRipple: true
                    },
                },

                MuiTextField: {
                    styleOverrides: {
                        root: {
                            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                borderColor: !isDark ? DEFAULT_THEME.palette.gray : DEFAULT_THEME.palette.white,
                            },
                            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                borderColor: DEFAULT_THEME.palette.gray,
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                                borderColor: isDark ? DEFAULT_THEME.palette.gray : "#000000",
                                border: "2px solid",
                            },
                            '& .MuiOutlinedInput-root .MuiSvgIcon-root': {
                                color: isDark ? 'white' : 'black',
                            },
                            '& input[type="time"]::-webkit-calendar-picker-indicator': {
                                filter: isDark ?
                                    'invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)' : 'invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)',
                            },
                            '& input[type="date"]::-webkit-calendar-picker-indicator': {
                                filter: isDark ?
                                    'invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)' : 'invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)',
                            },

                        },
                    },
                },
            }

        },
            coreLanguage,
            dataGridLanguage,
            //datePickerLanguage
        );

        // return theme
        return responsiveFontSizes(theme);
    }
    return getTheme
}


export { useThemes, DEFAULT_THEME };
