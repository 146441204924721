// React
import React, { useState, useEffect } from 'react';

// Libraries
import SideBar from "../UI/SideBar";
import NavBar from "../UI/NavBar";
// Utils

// MUI
import { Grid, Paper, useMediaQuery } from "@mui/material"
import { DEFAULT_THEME, useThemes } from "../utils/useThemes";
// Custom Components
import eventBus from '../utils/EventBus';

// Redux
import { useDispatch, useSelector } from "react-redux";
import SideBarMobile from './SidebarMobile';
import MobileNavbar from './MobileNavbar';
import { ROLES } from '../utils/constants';

// Services

const MainCard = (props) => {

    const { style, isSingleCard = false, sideBar = true, navBar = true, isGuest = false } = props
    const getTheme = useThemes()
    const isSmall = useMediaQuery(getTheme().breakpoints.down('sm_extra'));
    const isPhone = useMediaQuery(getTheme().breakpoints.down('phone'));

    const [isDark, setIsDark] = useState(false);
    const darkMode = useSelector(state => state.darkMode)
    const currentUser = useSelector(state => state.user)

    const styles = {
        paper: {
            //backgroundColor: 'white',
            borderWidth: '1px',
            borderRadius: '40px',
            p: 3,
            borderStyle: 'solid',
            justifyContent: 'space-around',
            width: 'stretch',
            borderColor: '#F0F3FB',
            direction: 'column',
        },
        marginTop: {
            marginTop: '1%',
        },
        multiChild: {
            backgroundColor: 'white',
        },
        ...style

    }

    return (
        <Grid container direction={'column'} sx={{ minHeight: '100vh', backgroundColor: darkMode ? '#000000' : '#F5F5F5' }}>
            {navBar ?
                isPhone ?
                    <MobileNavbar /> : <NavBar isGuest={isGuest ? true : false} />
                :
                <Grid></Grid>}
            <Grid container item justifyContent={sideBar ? "space-around" : "center"} sx={{ ...styles.marginTop }}>
                {sideBar ?
                    <Grid item>
                        {isPhone ? <SideBarMobile></SideBarMobile> : <SideBar></SideBar>}
                    </Grid> : <Grid></Grid>}

                <Grid item sx={{ mt: isPhone ? '1rem' : '', mb: '2rem' }} xs={isSmall ? 10 : 10.6}>

                    {isSingleCard ?
                        <Paper sx={{ ...styles.paper }}>
                            <Grid container sx={{ padding: '0px' }}>
                                {props.children}
                            </Grid>
                        </Paper>
                        :
                        <Grid item container>{props.children}</Grid>
                    }
                </Grid>

            </Grid>
        </Grid >
    )
}


export default MainCard