import { Button, Typography, TextField } from "@mui/material";
import { DEFAULT_THEME, } from "../utils/useThemes";
import InputMask from "react-input-mask";




const CustomTextField = (props) => {
    const { style, type = "text", onBlur, required, setValue, value, label, isDescriptionTF = false, isPhoneTF = false, mask, ...rest } = props
    const styles = {
        textfield: {
            [`& fieldset`]: {
                borderRadius: !isDescriptionTF ? 30 : 5,
            },
            ...style
        },
    }

    return (
        type === "tel" ?
            <InputMask
                mask={mask || '(\\90) 999 999 99 99'}
                value={value}
                maskChar=' '
                required={required}
                onChange={(e) => { setValue(e.target.value) }}
                onBlur={onBlur}
            >
                {(inputProps) =>
                    <TextField sx={{ ...styles.textfield }} size="small" value={value}
                        fullWidth type={type} InputLabelProps={{ shrink: true }} label={label} onSelect={(e) => { e.target.value = e.target.value.trim() }} {...rest} {...inputProps} />
                }
            </InputMask> :
            <TextField sx={{ ...styles.textfield }} size="small" value={value} onChange={(e) => { setValue(e.target.value) }}
                fullWidth type={type} InputLabelProps={{ shrink: true }} label={label} autoComplete="new-password" {...rest} />
    )
}

export default CustomTextField;