
import baseAxios from 'axios'
import { COOKIES, HEADERS, PUBLIC_URL_LIST, RESPONSE_STATUSES, ROUTES } from '../utils/constants'
import cookie from 'react-cookies'
import { setupCache } from 'axios-cache-interceptor';
import store from '../redux/store'
import { showError, showSuccess } from '../redux/snackbar';
import { setLoading } from '../redux/loading';

const API_URL = process.env.REACT_APP_API_URL

//Creates axios with configuration
const axios = setupCache(baseAxios.create({
    baseURL: API_URL
}), {
    ttl: 3 * 60 * 1000, // Three minutes
    generateKey: req => req.url + JSON.stringify(req.params),
});

//Clears cache for specific url/s
const clearCache = async (config) => {

    //Find url list to clear
    let clearList = []

    // Checks if urlsToClear is an array
    if (Array.isArray(config.urlsToClear)) {
        config.urlsToClear.map(clearUrl => {
            Object.keys(axios.storage.data).map(url => url.startsWith(clearUrl) && clearList.push(url))
        })
    } else {
        if (config.urlsToClear === 'clearAllCache') {
            Object.keys(axios.storage.data).map(url => clearList.push(url))
        } else {
            Object.keys(axios.storage.data).map(url => url.startsWith(config.urlsToClear) && clearList.push(url))
        }
    }
    //Clears the cache
    clearList.map(async (url) => await axios.storage.remove(url))

}

// Adds token as default header. If you wanna add other headers add it in function
const handleRequest = async (config) => {
    //Clear cache if needed
    if (config.urlsToClear) {
        await clearCache(config)
    }

    store.dispatch(setLoading(true))

    if (PUBLIC_URL_LIST.some(url => url === config.url)) return config

    let token = cookie.load(COOKIES.AUTHORIZATION)
    if (!token) {
        window.location.replace(`${ROUTES.INDEX}`);
        return
    }
    config.headers[HEADERS.AUTHORIZATION] = token
    return config
}

const handleResponse = (res) => {
    if (res.data.status === RESPONSE_STATUSES.success) {
        if (res.data.success) {
            store.dispatch(showSuccess({ message: res.data.success.message }));
        }
    }
    if (res.data.status === RESPONSE_STATUSES.fail) {
        if (res.data.error.code === 1000) {
            cookie.remove(COOKIES.AUTHORIZATION)
            window.location.replace(`${ROUTES.INDEX}`)
        }
        store.dispatch(showError({ message: res.data.error.message }));
    }
    store.dispatch(setLoading(false))
    return res
}

const handleError = (err) => {
    if (err.response.status === 403) {
        store.dispatch(showError({ message: err.response.data.error.message }));
    }
    if (err.code === 'ECONNABORTED') {
        store.dispatch(setLoading(false))
    }
    else {
        setTimeout(() => store.dispatch(setLoading(false)), 1000)
        // store.dispatch(showError({ message: UNKNOWN_ERROR.tr }));
    }
}

axios.interceptors.request.use(handleRequest)
axios.interceptors.response.use(handleResponse, handleError)

export default axios