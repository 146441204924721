import saveAs from 'file-saver'
import Papa from 'papaparse'

// converts +(area code) (phone number) to area code + phone number
const formatPhone = (phone) => {
    if (phone) {
        let editedPhone = phone.replace(/[^0-9]/g, '')
        if (editedPhone.startsWith('0'))
            editedPhone = editedPhone.slice(1)
        // editedPhone = '90' + editedPhone.slice(-10)
        return editedPhone
    }
    else return null
}

// converts area code + phone number to +(area code) (phone number): from: 905554443322 => to: +90 555 444 33 22
const displayPhone = (phone) => {
    if (phone) {
        // 10 digits from the end
        const phoneNumber = phone.slice(-10)
        const areaCode = phone.slice(0, phone.length - 10)

        return `+${areaCode} ${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3, 6)} ${phoneNumber.slice(6, 8)} ${phoneNumber.slice(8, 10)}`
    } else return null
}

// converts the plate to a more readable format: from: 34AB123 => to: 34 AB 123, 34ABC1234 => 34 ABC 123
const displayPlate = (plate) => {
    if (plate) {
        return plate.match(new RegExp('([0-9]+)|([a-zA-Z]+)', 'g'))?.join(' ').toUpperCase()
    } else return null
}


// cleans the object from null, undefined and empty values
const objectCleaner = (obj) => {

    Object.keys(obj).forEach(key => {

        if (typeof obj[key] === 'object' && obj[key] !== null) {
            obj[key] = objectCleaner(obj[key])
        }

        if ((!obj[key] && obj[key] !== false) || obj[key] == null || obj[key] == undefined)
            delete obj[key]
    })

    if (!obj || Object.keys(obj).length === 0) return null

    return obj
}

const downloadCSV = (data, fileName) => {
    let csv;
    if (Array.isArray(data)) {
        csv = Papa.unparse(data, { delimiter: ';' })
    } else {
        csv = data
    }
    const BOM = "\uFEFF"
    const csvData = BOM + csv // add BOM to fix UTF-8 issue in Excel

    const blob = new Blob([csvData], { type: 'text/csv;' })
    saveAs(blob, fileName)
}

// removes all the spaces from a string
const removeSpaces = (str) => {
    return str?.replace(/\s/g, '').toUpperCase()
}

const generateRandomString = (length) => {
    let result = ''
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let charactersLength = characters.length

    for (let i = 0; i < length; i++)
        result += characters.charAt(Math.floor(Math.random() * charactersLength))

    return result
}

export { formatPhone, objectCleaner, removeSpaces, generateRandomString, displayPhone, displayPlate, downloadCSV }