import { Breadcrumbs, Grid, Typography } from "@mui/material";
import { DEFAULT_THEME, useThemes } from "../utils/useThemes";
import React, { useState } from 'react';
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { ROLES, ROUTES } from "../utils/constants";
import { useTranslation } from "react-i18next";



const NavigationPanel = (props) => {
    const { panels, style } = props
    const darkMode = useSelector(state => state.darkMode)
    const user = useSelector(state => state.user)
    const { t } = useTranslation();


    const styles = {
        subTitle: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        strongText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
            fontSize: DEFAULT_THEME.typography.fontSize.regular,
            color: darkMode ? 'white' : 'black'
        },
        lightText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.regular,
            fontSize: DEFAULT_THEME.typography.fontSize.light,
            color: darkMode ? 'white' : 'black'
        },
        ...style
    }

    const handleNavigation = (i) => {

        const title = panels[i];
        if (title === "Yönetim Paneli") {
            return ROUTES.USER_MENU
        }
        else if (title === "Raporlama") {
            return ROUTES.REPORTING_MENU
        }
        else if (title === "Ziyaret İşlemleri") {
            return ROUTES.VISITING_OPERATIONS_MENU
        }

        else if (title === "Güvenlik Menüsü") {
            return ROUTES.SECURITY_MENU
        }
        else if (title === t('freightOperations')) {
            return ROUTES.FREIGHT_HANDLING_MENU
        }
        else if (title === t('userSettings')) {
            return ROUTES.USER_SETTINGS
        }
        else if (title === "Servis Araçları Takibi") {
            return ROUTES.SERVICE_CARS_TRACKING
        }
        else if (title === "Şirket Aracı Takibi") {
            return ROUTES.COMPANY_CARS_TRACKING
        }
    };

    return (
        <Grid container item sx={{ ...styles.subTitle }} xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
                {panels.map((component, i) =>
                    i !== panels.length - 1 ?
                        i === 0 ?
                            <Grid item key={i}>
                                <Link to={handleNavigation(i)} style={{ textDecoration: "none" }}>
                                    <Typography sx={{ ...styles.strongText }}>{component}</Typography>
                                </Link>
                            </Grid>
                            :
                            <Grid item key={i}>
                                <Link to={handleNavigation(i)} style={{ textDecoration: "none" }}>
                                    <Typography sx={{ ...styles.lightText }}>{component}</Typography>
                                </Link>
                            </Grid>
                        :
                        <Grid item key={i}>
                            <Typography sx={{ ...styles.lightText }} >{component}</Typography>
                        </Grid>
                )}
            </Breadcrumbs>
        </Grid>

    )
}

export default NavigationPanel;