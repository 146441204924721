//REACT
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

//MUI
import LocalShippingIconOutlined from '@mui/icons-material/LocalShippingOutlined';
import HandshakeIconOutlined from '@mui/icons-material/HandshakeOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import BadgeIconOutlined from '@mui/icons-material/BadgeOutlined';
import AirportShuttleIconOutlined from '@mui/icons-material/AirportShuttleOutlined';
import DirectionsCarIconOutlined from '@mui/icons-material/DirectionsCarOutlined';
import { useMediaQuery, Grid } from '@mui/material';
import AssessmentIconOutlined from '@mui/icons-material/AssessmentOutlined';

//LIBRARY
import { ROUTES } from '../../utils/constants';
import MainCard from '../../UI/MainCard';
import CommonInfoButton from '../../UI/CommonInfoButton';
import { DEFAULT_THEME, useThemes } from '../../utils/useThemes';
import NavigationPanel from '../../UI/NavigationPanel';
import { useState } from 'react';
import { useSelector } from 'react-redux';




const SecurityMenu = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();

    const getTheme = useThemes()
    const isPhone = useMediaQuery(getTheme().breakpoints.down('phone'));
    const [panels, setPanels] = useState(["Yönetim Paneli", "Güvenlik Menüsü"])
    const user = useSelector((state) => state.user);

    const styles = {
        strongText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
            fontSize: DEFAULT_THEME.typography.fontSize.regular
        },
        lightText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.regular,
            fontSize: DEFAULT_THEME.typography.fontSize.light
        },
        subtitle: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        infoButtonIcon: {
            minHeight: '100%',
            minWidth: '100%'
        },
        card: {
            p: isPhone ? "5px" : "17px",
        }

    }

    let fields = [
        { text: t('loadTruckOperations'), navigate: ROUTES.FREIGHT_HANDLING_MENU, children: <LocalShippingIconOutlined sx={{ ...styles.infoButtonIcon }} /> },
        { text: t('cardlessEmployeeOperations'), navigate: ROUTES.CARDLESS_EMPLOYEE_TRACKING, children: <BadgeIconOutlined sx={{ ...styles.infoButtonIcon }} /> },
        { text: t('visitOperations'), navigate: ROUTES.VISITING_OPERATIONS_MENU, children: <HandshakeIconOutlined sx={{ ...styles.infoButtonIcon }} /> },
        // { text: t('userSettings'), navigate:ROUTES.USERS_TABLE, children:<SettingsIcon sx={{ ...styles.infoButtonIcon }} />},
        { text: t('serviceCarsOperations'), navigate: ROUTES.SERVICE_CARS_TRACKING, children: <AirportShuttleIconOutlined sx={{ ...styles.infoButtonIcon }} /> },
        { text: t('companyCarsOperations'), navigate: ROUTES.COMPANY_CARS_TRACKING, children: <DirectionsCarIconOutlined sx={{ ...styles.infoButtonIcon }} /> },

    ]

    return (
        <MainCard isSingleCard={true}>

            <Grid container item xs={12} sx={{ ...styles.subtitle }} alignItems='center'>
                <NavigationPanel panels={panels}></NavigationPanel>

            </Grid>


            <Grid container>
                {fields.map((f, i) => {
                    return <Grid item key={i} xs={isPhone ? 5.7 : 2.7} sx={{ ...styles.card }}>
                        <CommonInfoButton text={f.text} navigate={f.navigate} children={f.children} />
                    </Grid>

                })}
            </Grid>

        </MainCard>
    );
}

export default SecurityMenu;