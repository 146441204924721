import { ENDPOINTS } from "../utils/constants"
import axios from "../utils/interceptor"

export default class ConfigurationService {
    constructor() {
        this.endpoint = ENDPOINTS.CONFIGURATION
    }

    getConfiguration = async () => {

        try {
            // Request
            let result = await axios.get(this.endpoint.BASE)

            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            //Return
            return result.data

        } catch (e) {
            return Promise.reject(e)
        }
    }
    updateConfiguration = async (configuration) => {

        let url = this.endpoint.BASE

        try {
            // Request
            let result = await axios.patch(url, configuration, { urlsToClear: this.endpoint.BASE })

            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            //Return
            return result.data

        } catch (e) {
            return Promise.reject(e)
        }
    }
}
