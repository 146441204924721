//React
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

//MUI
import EventNoteIconOutlined from '@mui/icons-material/EventNoteOutlined';
import CalendarMonthIconOutlined from '@mui/icons-material/CalendarMonthOutlined';
import BusinessIconOutlined from '@mui/icons-material/BusinessOutlined';
import { Grid, useMediaQuery } from '@mui/material';
//LIB
import { ROUTES } from '../../utils/constants'
import MainCard from '../../UI/MainCard';
import { DEFAULT_THEME, useThemes } from '../../utils/useThemes';
import CommonInfoButton from '../../UI/CommonInfoButton';
import { useState } from 'react';
import NavigationPanel from '../../UI/NavigationPanel';
import Card from '../../UI/Card';
import LocalShippingOutlined from '@mui/icons-material/LocalShippingOutlined';



const ReportingMenu = ({ user }) => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const getTheme = useThemes()
    const isPhone = useMediaQuery(getTheme().breakpoints.down('phone'));
    const [panels, setPanels] = useState(["Yönetim Paneli", "Raporlama"])

    const styles = {
        subTitle: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        strongText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
            fontSize: DEFAULT_THEME.typography.fontSize.regular
        },
        lightText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.regular,
            fontSize: DEFAULT_THEME.typography.fontSize.light
        },
        infoButtonIcon: {
            minHeight: '100%',
            minWidth: '100%'
        },
        card: {
            p: isPhone ? "5px" : "17px",
        }

    }
    let fields = [
        // { text: t('todaysVisit'), navigate: ROUTES.VISIT_DAILY_WEEKLY_FIRM, children: <EventNoteIconOutlined sx={{ ...styles.infoButtonIcon }} /> },
        // { text: t('weeksVisit'), navigate: ROUTES.VISIT_DAILY_WEEKLY_FIRM, children: <CalendarMonthIconOutlined sx={{ ...styles.infoButtonIcon }} /> },
        { text: t('visitAppointments'), navigate: ROUTES.RESERVATION_TABLE, children: <BusinessIconOutlined sx={{ ...styles.infoButtonIcon }} /> },
        { text: t('freightAppointments'), navigate: ROUTES.LOAD_RESERVATION_TABLE, children: <LocalShippingOutlined sx={{ ...styles.infoButtonIcon }} /> },
    ]
    return (
        <MainCard isSingleCard={true}>


            <Grid container justifyContent="space-between" direction="column">
                <Grid item>
                    <Grid container item xs={12} alignItems='center'>
                        <NavigationPanel panels={panels}></NavigationPanel>
                    </Grid>
                </Grid>

                <Grid container>
                    {
                        fields.map((field) =>
                            <Card field={field} styles={styles} />
                        )
                    }
                </Grid>

            </Grid>
        </MainCard>


    );
}

export default ReportingMenu;