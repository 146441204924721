// React
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';

// Libraries
import { useSelector, useDispatch } from "react-redux";


// Utils
import { DEFAULT_THEME, useThemes } from '../utils/useThemes';


// MUI
import { Grid, Typography, Button, Fab, Backdrop, useMediaQuery } from "@mui/material";
import { alpha } from '@mui/system';


// Custom Components

// Redux
import { hidePopup } from '../redux/popup';


// Services


const CustomPopUp = (props) => {

    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [isErrorMessage, setIsErrorMessage] = useState(false);
    const [isSuccessMessage, setIsSuccessMessage] = useState(false);

    const [title, setTitle] = useState("Default Title");
    const [text, setText] = useState("Defalt text");
    const [yesButtonText, setYesButtonText] = useState("");
    const [successButtonText, setSuccessButtonText] = useState("Başarılı");

    const [noButtonText, setNoButtonText] = useState(t("commonOkButton"));
    const dispatch = useDispatch();
    const getTheme = useThemes()
    const isPhone = useMediaQuery(getTheme().breakpoints.down('phone'));
    const popup = useSelector((state) => state.popup);

    const styles = {
        background: {
            background: alpha("#636363", 0.8),
        },
        popup: {
            background: {
                border: '1px solid ',
                borderColor: '#E6E6E6',
                borderRadius: '10px',
                backgroundColor: '#FFFFFF',
                width: isPhone ? '300px' : "524px",
                maxHeight: isPhone ? '250px' : "294px",
                textAlign: 'start',

                pt: isPhone ? '20px' : '32px',
                pb: '32px',
                pl: '35px',
                pr: '35px',
            },
            buttonsGrid: {
                marginTop: '25px',
                direction: 'row',
                justifyContent: 'space-around',
                display: 'flex'
            },
            buttonSuccess: {
                width: isPhone ? '100px' : '214px',
                height: isPhone ? '30px' : '40px',
                backgroundColor: '#4D9726',
                borderRadius: '10px',
                color: '#ffffff',
                fontSize: isPhone ? '12px' : "15px",
                fontFamily: DEFAULT_THEME.typography.fontFamilyBold,
                '&:hover': {
                    backgroundColor: '#155827',
                    color: '#ffffff',
                }
            },
            buttonYes: {
                width: isPhone ? '100px' : '214px',
                height: isPhone ? '30px' : '40px',
                backgroundColor: '#000000',
                borderRadius: '10px',
                color: '#ffffff',
                fontSize: isPhone ? '12px' : "15px",
                fontFamily: DEFAULT_THEME.typography.fontFamilyBold,
                '&:hover': {
                    backgroundColor: '#000000',
                    color: '#ffffff',
                }
            }, buttonNo: {
                width: isPhone ? '100px' : '214px',
                height: isPhone ? '30px' : '40px',
                backgroundColor: '#E45B5B',
                color: '#ffffff',
                borderRadius: '10px',
                fontSize: isPhone ? '12px' : "15px",
                fontFamily: DEFAULT_THEME.typography.fontFamilyBold,
                '&:hover': {
                    backgroundColor: '#E1DDDD',
                    color: '#6F6B6B',
                }
            },
            textTitle: {
                color: '#000000',
                fontSize: isPhone ? '20px' : "25px",

                fontFamily: DEFAULT_THEME.typography.fontFamilyBold,
            },
            text: {
                color: '#1C1C1C',
                fontSize: isPhone ? '13px' : "15px",
                fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
                mt: '10px'
            },
            fab: {
                backgroundColor: DEFAULT_THEME.palette.primary,
                color: '#ffffff',
                position: 'relative',
                left: '18px',
                top: '20px',
                '&:hover': {
                    backgroundColor: '#DFD155',
                    color: '#ffffff',
                },
                text: {
                    color: '#fffff',
                    fontSize: "15px",
                    fontFamily: DEFAULT_THEME.typography.fontWeight.extra_bold2,
                }
            }
        }
    }
    useEffect(() => {
        setTitle(popup?.title)
        setText(popup?.text)
        setYesButtonText(popup?.yesButtonText)
        setSuccessButtonText(popup?.successButtonText)
        setNoButtonText(popup?.noButtonText)
        setOpen(popup?.isOpen)
        setIsErrorMessage(popup?.isErrorMessage)
        setIsSuccessMessage(popup?.isSuccessMessage)

    }, [popup])

    const closeButtonClick = () => {
        dispatch(hidePopup())
        setOpen(false)
    }

    return (
        <Backdrop sx={{ ...styles.background, zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} transitionDuration={500}>
            <Grid textAlign='end'>

                {/* {<Fab onClick={closeButtonClick} size='small' sx={{ ...styles.popup.fab }}>
                    <Typography sx={{ ...styles.popup.fab.text }}>
                        x
                    </Typography>
                </Fab>} */}

                <Grid item sx={{ ...styles.popup.background }}>
                    <Typography sx={{ ...styles.popup.textTitle }}>{title}</Typography>
                    <Typography sx={{ ...styles.popup.text }}>{text}</Typography>
                    <Grid container sx={{ ...styles.popup.buttonsGrid }}>

                        {
                            !isErrorMessage && !isSuccessMessage &&
                                noButtonText ? <Button onClick={popup.noButtonAction} sx={{ ...styles.popup.buttonYes }}>{noButtonText}</Button> : null
                        }
                        {
                            isErrorMessage === true ? <Button onClick={popup.noButtonAction} sx={{ ...styles.popup.buttonNo }}>{noButtonText}</Button>
                                : isSuccessMessage === true ?
                                    <Button onClick={popup.successButtonAction} sx={{ ...styles.popup.buttonSuccess }}>
                                        {successButtonText}
                                    </Button> :
                                    <Button onClick={popup.yesButtonAction} sx={{ ...styles.popup.buttonNo }}>
                                        {yesButtonText}
                                    </Button>
                        }

                    </Grid>
                </Grid>

            </Grid>
        </Backdrop>
    )
}

export default CustomPopUp