
const { createSlice } = require("@reduxjs/toolkit");


export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: [],
    reducers: {
        setNotifications: (state, action) => {
            return action.payload

        },
    }
})

export const { setNotifications } = notificationsSlice.actions

export default notificationsSlice.reducer