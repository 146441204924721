//REACT
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
//MUI
import RuleIcon from '@mui/icons-material/Rule';
// import CalendarMonthIconOutlined from '@mui/icons-material/CalendarMonthOutlined';
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';
import { Grid, useMediaQuery } from '@mui/material';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

//LIB
import { ROLES, ROUTES } from '../../utils/constants'
import MainCard from '../../UI/MainCard';
import { DEFAULT_THEME, useThemes } from '../../utils/useThemes';
import CommonInfoButton from '../../UI/CommonInfoButton';
import { useSelector } from 'react-redux';
import NavigationPanel from '../../UI/NavigationPanel';
import { useState } from 'react';
import Card from '../../UI/Card';




const VisitingOperationsMenu = ({ user }) => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const getTheme = useThemes()
    const isPhone = useMediaQuery(getTheme().breakpoints.down('phone'));
    const currentUser = useSelector((state) => state.user);
    const [panels, setPanels] = useState([t('adminPanel'), t('reservationOperations')])

    const styles = {
        subTitle: {
            flexDirection: 'row',
            alignItems: 'center',
            mb: '1rem'
        },
        strongText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
            fontSize: DEFAULT_THEME.typography.fontSize.regular
        },
        lightText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.regular,
            fontSize: DEFAULT_THEME.typography.fontSize.light
        },
        infoButtonIcon: {
            minHeight: '100%',
            minWidth: '100%'
        },
        card: {
            p: isPhone ? "5px" : "17px",
        }
    }

    const fields = [
        {
            text: t('createVisit'),
            navigate: ROUTES.AUTH_USER_RESERVATION,
            children: <SaveAsOutlinedIcon sx={{ ...styles.infoButtonIcon }} />,
            roles: [ROLES.SECURITY_GUARD, ROLES.STANDARD_USER, ROLES.AUTHORIZED_USER, ROLES.SUPER_ADMIN, ROLES.PARTNER_COMPANY_AUTHORIZED_USER]
        },
        {
            text: t('viewAppointments'),
            navigate: ROUTES.VIEW_APPOINTMENTS,
            children: <FormatListBulletedIcon sx={{ ...styles.infoButtonIcon }} />,
            roles: [ROLES.SUPER_ADMIN, ROLES.AUTHORIZED_USER, ROLES.STANDARD_USER, ROLES.OPERATION_RESPONSIBLE, ROLES.PARTNER_COMPANY_AUTHORIZED_USER]
        },
        {
            text: t('commonDecisionRes'),
            navigate: ROUTES.RESERVATION_APPROVAL,
            children: <RuleIcon sx={{ ...styles.infoButtonIcon }} />,
            roles: [ROLES.SUPER_ADMIN, ROLES.AUTHORIZED_USER, ROLES.STANDARD_USER, ROLES.OPERATION_RESPONSIBLE]
        },
        {
            text: t('visitInOut'),
            navigate: ROUTES.DAILY_VISIT_TRACKING,
            children: <RvHookupIcon sx={{ ...styles.infoButtonIcon }} />,
            roles: [ROLES.SECURITY_GUARD, ROLES.SUPER_ADMIN]
        },
        {
            text: t('visitCalendar'),
            navigate: ROUTES.VISIT_CALENDAR,
            children: <PermContactCalendarIcon sx={{ ...styles.infoButtonIcon }} />,
            roles: [ROLES.AUTHORIZED_USER, ROLES.STANDARD_USER, ROLES.SUPER_ADMIN]
        }
    ]

    const filteredFields = fields.filter(f => f.roles.includes(currentUser?.type))

    return (
        <MainCard isSingleCard={true}>

            <Grid container item xs={12} sx={{ ...styles.subTitle }}>
                <NavigationPanel panels={panels}></NavigationPanel>
            </Grid>

            <Grid container>
                {
                    filteredFields.map((field) =>
                        <Card field={field} styles={styles} />
                    )
                }
            </Grid>
        </MainCard>
    )
}

export default VisitingOperationsMenu
