// MUI
import { LinearProgress } from '@mui/material';

const styles = {
    linearProgress: {
        width: '100%',
        height: "5px",
        backgroundColor: '#1976d2',
        position: 'absolute',
    }
}

function CustomLinearProgress(props) {

    const { loading, ...rest } = props

    return (
        loading && <LinearProgress style={styles.linearProgress} {...rest} />
    )
}

// Export
export default CustomLinearProgress