/*
    Desc: Authorised user verification page.
*/
//React
import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

//MUI
import { Grid, TextField, Typography, useMediaQuery } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
//import firebase from '../../services/firebase';

//Libraries
import WhiteButton from "../../UI/WhiteButton";
import YellowButton from "../../UI/YellowButton";
import { RESPONSE_STATUSES, ROLES, ROUTES } from '../../utils/constants';
import { DEFAULT_THEME, useThemes } from "../../utils/useThemes";
//Images
import profilePhoto from '../../images/profile_photo.png';

//Services
import UserService from '../../services/UserService';
//Translation
import { useTranslation } from 'react-i18next';
import { setUser } from '../../redux/user';

import { hidePopup, setMessageAndButtonText } from "../../redux/popup";
import CarouselImage from "../../UI/Carousel";

const Login = (props) => {

    // initialize services
    const userService = new UserService();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const getTheme = useThemes()
    const isPhone = useMediaQuery(getTheme().breakpoints.down('phone'));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const styles = {
        root: {
            border: '1px solid #dadee6',
            alignItems: "center",
            borderRadius: '20px',
            mt: '80px'
        },
        rootMobile: {
            border: '1px solid #dadee6',
            alignItems: "center",
            borderRadius: '20px',
            mt: '10px'
        },
        title1: {
            backgroundColor: '#000000',
            color: '#FFFFFF',
            fontWeight: 800,
            borderBottomLeftRadius: 3,
            borderTopLeftRadius: 3,
            fontSize: '23px',
            fontFamily: DEFAULT_THEME.typography.fontFamilyExtraBold,

        },
        title2: {
            borderBottomRightRadius: 3,
            borderTopRightRadius: 3,
            color: '#000000',
            backgroundColor: "yellow",
            fontWeight: 800,
            fontSize: '23px',
            fontFamily: DEFAULT_THEME.typography.fontFamilyBold,
        }
        ,
        text: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyBold,
            fontWeight: 200,
            fontSize: '12px',
            /* or 60px */
        },
        hug: {
            width: '80px',
            justifyContent: "space-between",
            mb: '20px'
        },
        notchedOutline: {
            borderWidth: "1px",
            borderColor: "red !important"
        },
        img2: {
            height: "120px"
        },
        welcomeTextForm: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyExtraBold,
            fontWeight: 100,
            fontSize: '20px',
        },
        welcomeTextForm2: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyExtraBold,
            fontWeight: 800,
            fontSize: '20px',
            mb: '45px'
        },
        form: {
            width: "100%",
            height: "100%"
        },
        containerLeft: {
            color: 'blue',
            height: 'stretch',
            width: 'stretch',

        },
        logoContainer: {
            alignItems: "center", ml: '65px', mt: '35px'
        },

        title3: {
            color: 'white', fontSize: '25px', fontWeight: "bold", mb: '15px'
        },
        title4: {
            color: 'white', fontSize: '12px', mb: '65px'
        },
        textField: {
            '& .MuiInputBase-input': {
                borderRadius: `90px !important`,
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderRadius: `90px`,
                },
            }
        },
        img: {
            height: '90%',
            width: '90%',
        },
        resetPassword: {
            cursor: 'pointer',
        }

    }
    const handleSubmit = async () => {
        //e.preventDefault()
        if (!email || !password) {
            warningInput()
        }
        else {
            const result = await userService.login({ password, email })

            if (result.status === RESPONSE_STATUSES.success) {
                // console.log("LOGIN SUCCESSFUL: ", userResp)
                dispatch(setUser(result.data))
            } else {
                errorInput(result?.error?.message)
            }
        }
    }

    const navigateToGuest = () => {
        //dispatch(setUser(user))
        navigate(ROUTES.GUEST)
    }

    //If one of the fields is empty
    const errorInput = (message) => {
        dispatch(setMessageAndButtonText({
            'title': "Hatalı Giriş", 'text': `${message}`, "noButtonText": "Tamam", noButtonAction: () => { dispatch(hidePopup()) }, "isErrorMessage": true
        }))
    }
    //If one of the fields is empty
    const warningInput = () => {
        dispatch(setMessageAndButtonText({
            'title': t("errorMessageTitle"), 'text': t("errorMessage"), 'noButtonText': t("commonOkButton"), noButtonAction: () => { dispatch(hidePopup()) }, "isErrorMessage": true
        }))
    }
    const MobileDesign = () => {
        return <Grid container sx={{ ...styles.rootMobile }} justifyContent="center">

            {/* ************************************************************************/}
            {/* LOGIN FORM */}
            {/* ************************************************************************/}
            <Grid item container xs={12} justifyContent="center" sx={{ padding: '20px' }}>
                <form style={styles.form}>

                    {/* ************************************************************************/}
                    {/* PROFILE PHOTO AND WELCOME TEXT */}
                    {/* ************************************************************************/}
                    <Grid item container direction="column" alignItems='center'>

                        <img
                            style={styles.img2}
                            src={profilePhoto}
                            alt={'name'}
                            loading="lazy"
                        />
                        <Grid item>
                            <Typography sx={{ ...styles.welcomeTextForm }}>{t('welcomeText1')}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography sx={{ ...styles.welcomeTextForm2 }}>{t('welcomeText2')}</Typography>
                        </Grid>
                    </Grid>

                    <Grid item sx={{ maxHeight: '50px', mb: '15px' }}>
                        <TextField
                            fullWidth sx={{ ...styles.textField }} size='small' label="Email Adresi" type="email" value={email} onChange={(e) => setEmail(e.target.value)}>
                        </TextField>
                    </Grid>
                    <Grid item sx={{ maxHeight: '50px', mb: '5px' }}>
                        <TextField
                            fullWidth sx={{ ...styles.textField }} size='small' label="Şifre" type="password" value={password} onChange={(e) => setPassword(e.target.value)}></TextField>
                    </Grid>

                    <Grid item sx={{ mb: '5px' }}>
                        <FormControlLabel control={<Checkbox defaultChecked />} label="Beni Hatırla" />
                    </Grid>
                    <Grid item sx={{ mb: '15px' }}>
                        <YellowButton onClick={(e) => handleSubmit(e)}>{t('login')}</YellowButton>
                    </Grid>

                    <Grid item sx={{ mb: '10px' }}>
                        <WhiteButton onClick={(e) => navigateToGuest()}>{t('loginAsGuest')}</WhiteButton>
                    </Grid>

                    <Grid item>
                        <WhiteButton onClick={(e) => navigate(ROUTES.RESET_PASSWORD)}>{t('newPassword')}</WhiteButton>
                    </Grid>

                </form>
            </Grid>
        </Grid>
    }

    return <Grid container justifyContent="center">

        {isPhone ? MobileDesign() : <Grid container item xs={11} sx={{ ...styles.root }} justifyContent="center">

            {/* ************************************************************************/}
            {/* DARK COMPONENT WITH IMAGE*/}
            {/* ************************************************************************/}
            <Grid container item xs={7} justifyContent="center" sx={{ height: '100%', width: '100%' }}>
                <CarouselImage></CarouselImage>
            </Grid>

            {/* ************************************************************************/}
            {/* LOGIN FORM */}
            {/* ************************************************************************/}
            <Grid item container xs={5} justifyContent="center" sx={{ padding: '20px' }}>
                <form style={styles.form}>

                    {/* ************************************************************************/}
                    {/* PROFILE PHOTO AND WELCOME TEXT */}
                    {/* ************************************************************************/}
                    <Grid item container direction="column" alignItems='center'>
                        <img
                            style={styles.img2}
                            src={profilePhoto}
                            alt={'name'}
                            loading="lazy"
                        />
                        <Grid item>
                            <Typography sx={{ ...styles.welcomeTextForm }}>{t('welcomeText1')}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography sx={{ ...styles.welcomeTextForm2 }}>{t('welcomeText2')}</Typography>
                        </Grid>
                    </Grid>

                    <Grid item sx={{ maxHeight: '50px', mb: '15px' }}>
                        <TextField
                            fullWidth sx={{ ...styles.textField }} size='small' label="Email Adresi" type="email" value={email} onChange={(e) => setEmail(e.target.value)}>
                        </TextField>
                    </Grid>
                    <Grid item sx={{ maxHeight: '50px', mb: '5px' }}>
                        <TextField
                            fullWidth sx={{ ...styles.textField }} size='small' label="Şifre" type="password" value={password} onChange={(e) => setPassword(e.target.value)}></TextField>
                    </Grid>

                    <Grid item sx={{ mb: '5px' }}>
                        <FormControlLabel control={<Checkbox defaultChecked />} label="Beni Hatırla" />
                    </Grid>
                    <Grid item sx={{ mb: '15px' }}>
                        <YellowButton onClick={(e) => handleSubmit(e)}>{t('login')}</YellowButton>
                    </Grid>

                    <Grid item sx={{ mb: '10px' }}>
                        <WhiteButton onClick={(e) => navigateToGuest(e)} >{t('loginAsGuest')}</WhiteButton>
                    </Grid>

                    <Grid item container justifyContent={"space-between"}>
                        <Grid item>
                            <Link onClick={() => navigate(ROUTES.REGISTER)} sx={{ ...styles.resetPassword }}>{t('register')}</Link>
                        </Grid>

                        <Grid item>
                            <Link onClick={() => navigate(ROUTES.RESET_PASSWORD)} sx={{ ...styles.resetPassword }}>{t('resetPassword')}</Link>
                        </Grid>
                    </Grid>

                </form>
            </Grid>
        </Grid>}
    </Grid>
}

export default Login;