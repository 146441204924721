//REACT
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

//MUI
import { Grid, FormGroup } from '@mui/material';

//LIBRARY
import MainCard from '../../UI/MainCard';
import { DEFAULT_THEME } from '../../utils/useThemes';
import CustomTextField from '../../UI/CustomTextField';
import YellowButton from '../../UI/YellowButton';
import CustomDatePicker from '../../UI/CustomDatePicker';
import dayjs from "dayjs";

// Services
import { showError } from '../../redux/snackbar';
import TrackingService from "../../services/TrackingService";
import NavigationPanel from "../../UI/NavigationPanel";
import { RESPONSE_STATUSES, ROUTES } from "../../utils/constants";

const styles = {
    subTitle: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    strongText: {
        fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
        fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
        fontSize: DEFAULT_THEME.typography.fontSize.regular
    },
    lightText: {
        fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
        fontWeight: DEFAULT_THEME.typography.fontWeight.regular,
        fontSize: DEFAULT_THEME.typography.fontSize.light
    },
}

const CardlessEmployeeAddOrEdit = (props) => {
    const dispatch = useDispatch;
    const { t } = useTranslation();
    const navigate = useNavigate()
    const location = useLocation()

    const trackingService = new TrackingService();

    const { request, isUpdate } = location?.state || {}
    const [id, setId] = useState("");
    const [fullName, setFullName] = useState("");
    const [cardNumber, setCardNumber] = useState("");
    const [entranceTime, setEntranceTime] = useState("");
    const [exitTime, setExitTime] = useState("");
    const [trackingDate, setTrackingDate] = useState(!isUpdate ? dayjs() : "");
    const [description, setDescription] = useState("");
    const [panels, setPanels] = useState([t('adminPanel'), !isUpdate ? "Kartsız Personel Ekleme" : "Kartsız Personel Düzenleme"]);
    const [disabled, setDisabled] = useState(isUpdate ? false : true);


    useEffect(() => {
        if (isUpdate) getInfo();
    }, [request])

    useEffect(() => {
        if (!isUpdate)
            checkInputs()
    }, [fullName, cardNumber, entranceTime, trackingDate])

    useEffect(() => {
        if (isUpdate)
            setDisabled(
                !isAnyFieldChanged()
            )
    }, [fullName, cardNumber, entranceTime, trackingDate, exitTime, description])

    const getInfo = () => {
        setId(request?.id)
        setFullName(request?.employeeTracking?.fullName)
        setEntranceTime(request?.entranceTime)
        setExitTime(request?.exitTime)
        setCardNumber(request?.employeeTracking.cardNumber)
        setTrackingDate(dayjs(request?.trackingDate, 'DD-MM-YYYY'))
        setDescription(request?.description)
    }

    const checkInputs = () => {
        setDisabled(!(fullName && cardNumber && entranceTime && trackingDate))
    }

    const isAnyFieldChanged = () => {
        return (
            request?.employeeTracking?.fullName !== fullName ||
            request?.employeeTracking?.cardNumber !== cardNumber ||
            request?.entranceTime !== entranceTime ||
            request?.trackingDate !== trackingDate.format('DD-MM-YYYY') ||
            request?.exitTime !== exitTime ||
            request?.description !== description
        )
    }

    const onSubmitAppointment = async () => {
        const trackingType = "EMPLOYEE"
        const trackingObj = {
            trackingType,
            fullName,
            cardNumber,
            entranceTime,
            trackingDate: dayjs(trackingDate).format('YYYY-MM-DD'),
            exitTime,
            description,
        }
        if (isUpdate) {
            await updateTrackingAppointment(trackingObj)
        }
        else {
            await createTrackingAppointment(trackingObj)
        }

    }


    // action function 
    const createTrackingAppointment = async (tracking) => {

        const result = await trackingService.createTracking(tracking)

        if (result.data && result.success) {
            navigate(ROUTES.CARDLESS_EMPLOYEE_TRACKING)
        } else {
            dispatch(showError({ message: t("errorMessageTitle") }));
        }
    }

    const updateTrackingAppointment = async (tracking) => {

        const result = await trackingService.updateTracking(id, tracking)

        if (result.status === RESPONSE_STATUSES.success) {
            navigate(ROUTES.CARDLESS_EMPLOYEE_TRACKING)
        }
        else
            dispatch(showError({ message: t("errorMessageTitle") }));
    }


    return (

        <MainCard isSingleCard={true}>
            {/* ********************************************************************** */}
            {/* NAVIGASYON PANEL */}
            {/* ********************************************************************** */}
            <Grid container item sx={{ ...styles.subTitle }} xs={12}>
                <NavigationPanel panels={panels}></NavigationPanel>
            </Grid>


            {/* ********************************************************************** */}
            {/* INPUT FIELD */}
            {/* ********************************************************************** */}
            <Grid container xs={12} sx={{ mt: '2.5rem', justifyContent: "center" }}>

                <FormGroup sx={{ width: 'stretch' }} onChange={() => { checkInputs() }} >

                    {/* ********************************************************************** */}
                    {/* 1st ROW OF INPUT FIELD */}
                    {/* ********************************************************************** */}
                    <Grid container xs={12} justifyContent="space-between" >

                        <Grid item container justifyContent="space-between">
                            <Grid item xs={6.9}>
                                <CustomTextField
                                    placeholder="Tam Ad" label="Tam Ad" value={fullName} setValue={setFullName} />
                            </Grid>
                            <Grid item xs={4.7}>
                                <CustomDatePicker
                                    placeholder={t('commonDate')}
                                    label={t('commonDate')}
                                    value={trackingDate}
                                    setValue={setTrackingDate} />
                            </Grid>
                        </Grid>

                        {/* ********************************************************************** */}
                        {/* 2nd ROW OF INPUT FIELD */}
                        {/* ********************************************************************** */}
                        <Grid item container justifyContent="space-between" sx={{ mt: '2rem' }}>
                            <Grid item xs={3.9}>
                                <Grid item xs={12}>
                                    <CustomTextField
                                        label="Giriş Saati"
                                        value={entranceTime}
                                        setValue={setEntranceTime}
                                        type="time"
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={3.9}>
                                <CustomTextField
                                    type="time" label="Çıkış Saati" value={exitTime} setValue={setExitTime} />
                            </Grid>

                            <Grid item xs={3.9}>
                                <CustomTextField
                                    placeholder="Kart No" label="Kart No" value={cardNumber} setValue={setCardNumber} /></Grid>

                        </Grid>

                        <Grid item container justifyContent="space-between" sx={{ mt: '2rem' }}>
                            <CustomTextField
                                placeholder={t('description')} isDescriptionTF={true} multiline rows={5} label={t('description')} value={description} setValue={setDescription} />
                        </Grid>



                        {/* ********************************************************************** */}
                        {/* BUTTON */}
                        {/* ********************************************************************** */}
                        <Grid item sx={{ mt: '2rem' }} xs={4}>
                            <YellowButton
                                disabled={disabled}
                                type="submit"
                                onClick={() => onSubmitAppointment()}
                            >
                                {isUpdate ? "Güncelle" : t('commonAddButton')}
                            </YellowButton>


                        </Grid>
                    </Grid>

                </FormGroup>
            </Grid>


        </MainCard>
    )
}
export default CardlessEmployeeAddOrEdit;