// React
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Libraries
// Utils

// MUI
import { Checkbox, FormControlLabel, FormGroup, Grid, Typography } from "@mui/material"
import { DEFAULT_THEME, useThemes } from "../utils/useThemes";
// Custom Components

// Redux

import MainCard from '../UI/MainCard';
import CustomTextField from '../UI/CustomTextField';
import YellowButton from '../UI/YellowButton';
import { useSelector } from 'react-redux';
import { CLARIFICATION_TEXT, RESPONSE_STATUSES, ROUTES } from '../utils/constants';
import { formatPhone } from '../utils/helper';

// Services
import ContactRequestService from '../services/ContactRequestService';

const ContactUs = (props) => {

    const { style } = props

    const styles = {
        paper: {
            //backgroundColor: 'white',
            borderWidth: '1px',
            borderRadius: '40px',
            p: 3,
            borderStyle: 'solid',
            justifyContent: 'space-around',
            width: 'stretch',
            borderColor: '#F0F3FB',
            direction: 'column',
        },
        marginTop: {
            marginTop: '1%',
        },
        multiChild: {
            backgroundColor: 'white',
        },
        disabled: {
            backgroundColor: '#F0F3FB',
            color: '#B3B8CD',
        },
        ...style

    }
    const navigate = useNavigate()
    const contactRequestService = new ContactRequestService();

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [subject, setSubject] = useState("")
    const [message, setMessage] = useState("")

    const [checked, setChecked] = useState(false)
    const [disabled, setDisabled] = useState(true)

    const darkMode = useSelector(state => state.darkMode)

    useEffect(() => {
        checkInputs()
    }, [firstName, lastName, email, phone, message, checked])

    const checkInputs = () => {
        const emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
        const phoneRegex = /^[0-9]{12}$/;

        const allInputsValid = (firstName && lastName && emailRegex.test(email) && phoneRegex.test(formatPhone(phone)) && message && checked)
        setDisabled(!allInputsValid)
        return allInputsValid
    }

    const onSubmitRequest = async () => {
        const contactRequest = {
            firstName,
            lastName,
            email,
            phone: formatPhone(phone),
            subject,
            message
        }

        if (checkInputs()) {
            try {
                const result = await contactRequestService.createContactRequest(contactRequest)

                if (result && result.status === RESPONSE_STATUSES.success) {
                    alert('Mesajınız başarıyla gönderildi.')
                    navigate(ROUTES.INDEX)
                }
            } catch (error) {
                console.log(error)
            }

        } else {
            alert('Lütfen tüm alanları doldurunuz.')
        }
    }

    const handleClick = () => {
        window.open(CLARIFICATION_TEXT)
    };
    return (
        <MainCard isSingleCard={true} sideBar={false} navBar={true} isGuest={true}>

            <Grid container item sx={{ ...styles.subTitle }} xs={12}>
                <Typography sx={{ fontSize: DEFAULT_THEME.typography.fontSize.extra_large, fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold2 }}>Bize Ulaşın</Typography>

            </Grid>

            <Grid container item sx={{ border: darkMode ? '0.5px solid white' : '0.5px solid black', color: darkMode ? 'white' : 'black', height: '1px' }} xs={4}>

            </Grid>


            <Grid container xs={12} sx={{ mt: '3rem', justifyContent: "center" }}>

                <FormGroup sx={{ width: 'stretch' }} onChange={() => checkInputs()} >

                    {/* ********************************************************************** */}
                    {/* 1st ROW OF INPUT FIELD */}
                    {/* ********************************************************************** */}
                    <Grid container xs={12} direction="column" justifyContent="space-between" >

                        <Grid item container justifyContent="space-between" sx={{ mb: '2rem' }}>
                            <Grid item xs={5.9}>
                                <CustomTextField
                                    placeholder="Ad" label="Ad" value={firstName} setValue={setFirstName} />
                            </Grid>

                            <Grid item xs={5.9}>
                                <CustomTextField
                                    placeholder="Soyad" label="Soyad" value={lastName} setValue={setLastName} />
                            </Grid>
                        </Grid>

                        <Grid item container justifyContent="space-between" sx={{ mb: '2rem' }}>
                            <Grid item xs={5.9}>
                                <CustomTextField
                                    placeholder="Telefon" label="Telefon" value={phone} setValue={setPhone} type="tel" />
                            </Grid>

                            <Grid item xs={5.9}>
                                <CustomTextField
                                    placeholder="E-posta" label="E-posta" value={email} setValue={setEmail} />
                            </Grid>
                        </Grid>

                        <Grid item container justifyContent="space-between" sx={{ mb: '1rem' }}>
                            <Grid item xs={12}>
                                <CustomTextField
                                    placeholder="Konu" label="Konu" value={subject} setValue={setSubject} />
                            </Grid>
                        </Grid>



                        {/* ********************************************************************** */}
                        {/* 4th ROW OF INPUT FIELD */}
                        {/* ********************************************************************** */}
                        <Grid item container sx={{ mt: '2rem', justifyContent: 'center' }}>
                            <CustomTextField
                                label="Mesajınız"
                                multiline
                                rows={6}
                                sx={{
                                    [`& fieldset`]: {
                                        borderRadius: 2,
                                    },
                                }}
                                value={message}
                                setValue={setMessage}
                            />
                        </Grid>
                        <Grid item container alignItems="center" alignContent="center" justifyContent="flex-start">
                            <Grid item alignItems="center" alignContent="center" sx={{ width: '1.8rem' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checked}
                                            onClick={(e) => setChecked(e.target.checked)}
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item alignItems="center" alignContent="center" sx={{ mr: '2rem', mb: '0.2rem' }}>
                                <div>
                                    <span onClick={() => handleClick()} style={{ cursor: 'pointer', textDecoration: 'underline' }}>Aydınlatma Metnini</span>
                                    <span> okudum ve kabul ediyorum.</span>
                                </div>

                            </Grid>
                        </Grid>

                        {/* ********************************************************************** */}
                        {/* BUTTON */}
                        {/* ********************************************************************** */}
                        <Grid item container sx={{ mt: '2rem' }}>
                            <Grid item xs={3}>

                                <YellowButton
                                    disabled={disabled}
                                    type="submit"
                                    // style={{ color: darkMode ? 'black' : 'white' }}
                                    style={disabled ? styles.disabled : {}}

                                    onClick={() => onSubmitRequest()}
                                >
                                    Gönder
                                </YellowButton>

                            </Grid>



                        </Grid>
                    </Grid>

                </FormGroup>
            </Grid>
        </MainCard>

    )
}


export default ContactUs