//REACT
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import * as React from 'react';
import { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
//MUI
import { Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

//LIBRARY
import MainCard from '../../UI/MainCard';
import CustomDataGrid from '../../UI/CustomDataGrid';
import { RESPONSE_STATUSES, RESPONSE_TYPES, ROUTES } from '../../utils/constants'
import BlackButton from '../../UI/BlackButton';
import { DEFAULT_THEME, useThemes } from '../../utils/useThemes';
import UserService from '../../services/UserService';
import NavigationPanel from '../../UI/NavigationPanel';
import { useEffect } from 'react';
import CommonEditButton from '../../UI/CommonEditButton';
import { displayPhone } from '../../utils/helper';
import { showError, showSuccess } from '../../redux/snackbar';
import { hidePopup, setMessageAndButtonText } from '../../redux/popup';

const UserApproval = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const userService = new UserService()
    const [users, setUsers] = useState([])
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(8);
    const [params, setParams] = useState({ page, limit });
    const darkMode = useSelector(state => state.darkMode)
    const getTheme = useThemes()
    const isPhone = useMediaQuery(getTheme().breakpoints.down('phone'));
    const { t } = useTranslation();
    const [totalCount, setTotalCount] = useState(0);
    const [panels, setPanels] = useState([t('adminPanel'), t('userSettings'), t('userApproval')])


    const styles = {
        strongText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
            fontSize: DEFAULT_THEME.typography.fontSize.regular
        },
        lightText: {
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.regular,
            fontSize: DEFAULT_THEME.typography.fontSize.light
        },
        subtitle: {
            backgroundColor: darkMode ? 'black' : 'white',
            backdropFilter: 'blur(12px)',
            alignItems: 'center',
            borderStyle: 'solid',
            borderColor: '#EAEEF7',
            height: isPhone ? '120px' : '85px',
            borderRadius: 10,
            p: 2,
        },
        bottomGrid: {
            p: 4,
            borderWidth: '1px',
            backgroundColor: darkMode ? 'black' : 'white',
            borderRadius: '30px',
            borderStyle: 'solid',
            backdropFilter: 'blur(12px)',
            justifyContent: 'space-around',
            width: 'stretch',
            borderColor: '#E3E5EA',
            mt: '20px',
        },
    }

    const fetchUsers = async (params) => {
        const result = await userService.getUsers({ ...params, isVerified: false })

        if (result) {
            setUsers(result.data)
            setTotalCount(result.count)
        }
    }


    /* approve a reservation which clicked in datagrid table */
    const approveReservation = async (object) => {
        const body = { isVerified: true }

        // valid appointment update object
        if (object.id && body) {
            const result = await userService.updateUser(object.id, body)
            if (result.status === RESPONSE_STATUSES.success) {
                const list = users.filter(item => item.id !== object.id)
                setUsers(list);

                dispatch(showSuccess({ message: t("successMessage") }))
            }
            else
                dispatch(showError({ message: t("errorMessageTitle") }))
        }
    };

    /* reject a reservation which clicked in datagrid table */
    const declineReservation = async (object) => {

        const body = { isVerified: false }

        // valid appointment update object
        if (object.id && body) {
            const result = await userService.updateUser(object.id, body)
            if (result.status === RESPONSE_TYPES.success) {

                const list = users.filter(item => item.id !== object.id)
                setUsers(list);
                dispatch(showSuccess({ message: t("successMessageForDelete") }))
            }
            else
                dispatch(showError({ message: t("errorMessageTitle") }))
        }
    };

    /* function to allow us to do a operation for particular row, in this case approve and reject operation */
    const getActionColumnDetail = (object) => {
        return (
            <Grid container item justifyContent="space-around">
                <Grid item>
                    <IconButton
                        onClick={() => (approveReservation(object))}>
                        <DoneIcon sx={{ color: '#469D89' }} />
                    </IconButton>
                </Grid>
                <Grid item>
                    <IconButton
                        onClick={() => dispatch(setMessageAndButtonText({
                            'title': t("rejectUserRegistrationTitle"),
                            'text': t("rejectUserRegistrationText"),
                            'noButtonText': t("commonCancelButton"),
                            noButtonAction: () => {
                                dispatch(hidePopup())
                            },
                            'yesButtonText': t("reject"),
                            yesButtonAction: () => {
                                declineReservation(object);
                                dispatch(hidePopup())
                            },
                            "isErrorMessage": false
                        }))}>
                        <CloseIcon sx={{ color: 'red' }} />
                    </IconButton>
                </Grid>
            </Grid>

        );
    };

    //örnek column oluşturma
    const columns = [
        {
            width: 150,
            renderCell: (params) => getActionColumnDetail(params.row)
        },
        {
            field: 'firstName',
            headerName: t('firstName2'),
            minWidth: 150,
            //renderCell: (params) => params.row.company.name
        },
        {
            field: 'lastName',
            headerName: t('lastName'),
            minWidth: 150,
            //renderCell: (params) => params.row.company.name
        },
        {
            field: 'phone',
            headerName: t('phoneNumberLabel'),
            width: 220,
            renderCell: (params) => displayPhone(params.row?.phone)
        },
        {
            field: 'email',
            headerName: 'Email',
            minWidth: 220,
        },
        {
            field: 'type',
            headerName: 'Kullanıcı Tipi',
            minWidth: 220,
            renderCell: (params) => t(`${params.row.type}`)
        },
        {
            field: 'company',
            headerName: 'Şirket',
            minWidth: 170,
            renderCell: (params) => (
                <Grid container>
                    <Typography>{params.row.company?.name}</Typography>
                </Grid>
            ),

        },

    ];

    return (
        <MainCard>
            <Grid container item xs={12} sx={{ ...styles.subtitle }}>
                <NavigationPanel panels={panels}></NavigationPanel>
            </Grid>

            <Grid item container sx={{ ...styles.bottomGrid }} justifyContent='space-between'>
                <Grid item xs={12}>
                    <CustomDataGrid
                        rows={users}
                        columns={columns}
                        showFilterBar={true}
                        titleOfTable={t('userList')}
                        getFunction={fetchUsers}
                        totalCount={totalCount}
                        params={params}

                    />
                </Grid>
            </Grid>
        </MainCard>

    );
}

export default UserApproval;