import { Button, Typography } from "@mui/material";
import { DEFAULT_THEME, } from "../utils/useThemes";
const WhiteButton = (props) => {
    const { style } = props
    const styles = {
        button: {
            width: "stretch",
            height: '46px',

            backgroundColor: "white",
            color: "black",
            textAlign: "center",
            borderRadius: "8px",
            border: "1px dashed #000000",
            fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
            fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
            fontSize: DEFAULT_THEME.typography.fontSize.light,
            '&:hover': {
                backgroundColor: '#E1E1DC',
                color: 'black',
                cursor: 'pointer',
            },
            ...style


        },
    }

    return (
        <Button variant="contained" sx={{ ...styles.button }} {...props} />

    )
}

export default WhiteButton;