import { createSlice } from '@reduxjs/toolkit'
export const visitSlice = createSlice({

    name: 'visit',
    initialState: null,
    reducers: {
        addVisit: (state, action) => {

            state = action.payload;
            return state;
        },

        editVisit: (state, action) => {

            return {
                ...state,
                ...action.payload


            }
        }

    }
})
export const { editVisit, addVisit } = visitSlice.actions
export default visitSlice.reducer