
//REACT
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

//MUI
import Grid from '@mui/material/Grid';

//LIBRARY
import { hidePopup, setMessageAndButtonText } from "../../redux/popup"

// SERVICE 
import UserService from '../../services/UserService';

import { Typography } from '@mui/material';
import { RESPONSE_STATUSES, ROUTES } from '../../utils/constants';
import CustomTextField from '../../UI/CustomTextField';
import MainCard from '../../UI/MainCard';
import { DEFAULT_THEME } from '../../utils/useThemes';
import YellowButton from '../../UI/YellowButton';

const styles = {
    strongText: {
        fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
        fontWeight: DEFAULT_THEME.typography.fontWeight.extra_bold,
        fontSize: DEFAULT_THEME.typography.fontSize.regular
    },
    lightText: {
        fontFamily: DEFAULT_THEME.typography.fontFamilyRegular,
        fontWeight: DEFAULT_THEME.typography.fontWeight.regular,
        fontSize: DEFAULT_THEME.typography.fontSize.light
    },
    textfield: {
        [`& fieldset`]: {
            borderRadius: 30
        },

    },
    textfieldDescription: {
        [`& fieldset`]: {
            borderRadius: 5
        }
    }
}

const RenewPassword = (props) => {

    const { t } = useTranslation();

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [disabled, setDisabled] = useState(true)
    const [password, setPassword] = useState('')
    const [passwordRepeat, setPasswordRepeat] = useState('')

    const [passwordResetToken, setPasswordResetToken] = useState(props?.token)
    const [validToken, setValidToken] = useState(false)

    // initialize services
    const userService = new UserService();

    useEffect(() => {
        validateRenewPasswordToken()
    }, [passwordResetToken])

    useEffect(() => {
        validatePassword()
    }, [password, passwordRepeat])

    const validatePassword = () => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
        if (passwordRegex.test(password) && passwordRegex.test(passwordRepeat)) {
            setDisabled(false)
            return true
        } else {
            setDisabled(true)
        }
        return false
    }

    const resetPassword = async () => {

        if (validatePassword(password, passwordRepeat)) {
            const result = await userService.resetPassword(passwordResetToken, password)
            // success case 
            if (result.data && result.success) {

                dispatch(setMessageAndButtonText({
                    title: 'Şifreniz Başarıyla Değiştirildi!',
                    text: `Yeni şifreniz ile giriş yapabilirsiniz.`,
                    successButtonText: "Tamam",//t("commonOkButton"),
                    successButtonAction: () => { dispatch(hidePopup()); navigate(ROUTES.INDEX) },
                    isSuccessMessage: true
                }))
            }

            // error case 
            if (result && result.error) {
                dispatch(setMessageAndButtonText({
                    title: 'Şifre Değiştirilemedi!',
                    text: `${result.error.message}`,
                    noButtonText: t("commonOkButton"),
                    noButtonAction: () => { dispatch(hidePopup()) },
                    isErrorMessage: true
                }))
            }
        } else {
            console.log(password, passwordRepeat)
            dispatch(setMessageAndButtonText({
                title: 'Şifre Değiştirilemedi!',
                text: `Lütfen şifrenizi kontrol edin. \n Şifreniz en az 8 karakterden oluşmalıdır ve en az bir büyük harf, bir küçük harf ve bir rakam içermelidir.`,
                noButtonText: t("commonOkButton"),
                noButtonAction: () => { dispatch(hidePopup()) },
                isErrorMessage: true
            }))
        }
    }

    const validateRenewPasswordToken = async () => {
        const result = await userService.loginWithToken(passwordResetToken)

        if (result.data.data && result.data.status === RESPONSE_STATUSES.success) {
            setValidToken(true)
        } else {
            dispatch(setMessageAndButtonText({
                title: t('popup.invalidToken.title'),
                text: t('popup.invalidToken.text'),
                noButtonText: t('popup.invalidToken.button'),
                noButtonAction: () => { dispatch(hidePopup()); navigate(ROUTES.INDEX) },
                isErrorMessage: true
            }))
        }
        return false
    }

    return (

        validToken ?
            <MainCard isSingleCard={true} navBar={true} sideBar={false} isGuest={true}>
                {/* NAVIGASYON PANEL */}
                {/* ********************************************************************** */}
                <Grid container item alignItems='center' xs={12}>
                    <Typography sx={{ ...styles.strongText }}>{t('newPassword')}</Typography>
                </Grid>

                {/* ********************************************************************** */}
                {/* INPUT FIELD */}
                {/* ********************************************************************** */}
                <Grid container item xs={12} sx={{ mt: '2.5rem', width: 'stretch' }}>
                    <Grid container justifyContent="space-between"></Grid>
                    <Grid item container justifyContent="space-center" alignItems="center" xs={12} sx={{ mb: '2rem' }}>
                        <Grid item xs={3.9}>
                            <Typography sx={{ ...styles.lightText }}>
                                Şifreniz en az 8 karakterden oluşmalıdır ve en az bir büyük harf, bir küçük harf ve bir rakam içermelidir.
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item container justifyContent="space-center" alignItems="center" xs={12} sx={{ mb: '2rem' }}>
                        <Grid item xs={3.9} sx={{ mr: '2rem' }}>
                            <CustomTextField sx={{ ...styles.textfield }}
                                label={t('password')} placeholder={t('password')} value={password} setValue={setPassword} type="password" />
                        </Grid>

                        <Grid item xs={3.9} sx={{ mr: '2rem' }}>
                            <CustomTextField sx={{ ...styles.textfield }}
                                label={t('passwordRepeat')} placeholder={t('passwordRepeat')} value={passwordRepeat} setValue={setPasswordRepeat} type="password" />
                        </Grid>

                        <Grid item xs={1.5} sx={{ ml: '1rem' }}>
                            <YellowButton
                                type="submit"
                                onClick={() => resetPassword()}
                                disabled={disabled}
                            >
                                {t('send')}
                            </YellowButton>


                        </Grid>
                    </Grid>
                </Grid>
            </MainCard >
            :
            <></>
    )
}



export default RenewPassword;

