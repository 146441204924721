import React from 'react';
import { Grid, Typography } from '@mui/material';

import car from '../images/reno3.jpg';
import car3 from '../images/reno2.jpeg';

import { useTranslation } from 'react-i18next';
//import { Carousel } from 'react-responsive-carousel';
import Carousel from 'framer-motion-carousel'

const images = [
    {
        src: car, content: "TEST1",
    },
    { src: car3 },
];
const styles = {
    grid: {
        width: "100%", height: "100%", backgroundColor: 'black', overflowY: 'hidden',
        overflow: 'hidden'
    },
    img: {
        height: '400px',
        width: '600px'
    },
    title3: {
        color: 'white', fontSize: '25px', fontWeight: 700, mb: '0.5rem'
    },
    title4: {
        color: 'white', fontSize: '12px', ml: '2rem', mr: '2rem'
    },
    arrows: {
        height: '20px', width: '20px'
    },
}
const CarouselImage = () => {
    const { t } = useTranslation();

    return (
        <Grid container sx={{
            overflowY: 'hidden',
            overflow: 'hidden',
            scrollbarWidth: 'none',
            WebkitOverflowScrolling: {}
        }}>
            <Carousel>
                {images.map((item, i) => (
                    <Grid container alignItems="center" alignContent="center" direction="column" key={i} sx={{ ...styles.grid }}>
                        <Grid item>
                            <img
                                src={item.src}
                                style={styles.img}
                                alt={'name'}
                                loading="lazy"
                            />
                        </Grid>
                        <Grid item >
                            <Typography sx={{ ...styles.title3 }}> {t('title')}</Typography>
                        </Grid>
                        <Grid item sx={{ ...styles.title4 }}>
                            <Typography sx={{ ...styles.title4 }}> {t('text1')}</Typography>
                        </Grid>
                    </Grid>
                ))}
            </Carousel>
        </Grid>
    );



}

export default CarouselImage;