import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import Person2Icon from '@mui/icons-material/Person2';
import PeopleIcon from '@mui/icons-material/People';
import GridViewIcon from '@mui/icons-material/GridView';
import LogoutIcon from '@mui/icons-material/Logout';
import { Grid, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROUTES, ROLES, COOKIES } from '../utils/constants'
import { DEFAULT_THEME, useThemes } from "../utils/useThemes";
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import AssessmentIcon from '@mui/icons-material/Assessment';

import { useRef } from 'react';
import { IconButton, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import React, { useState, useEffect } from 'react';
import Sun from '@mui/icons-material/WbSunny';
import Moon from '@mui/icons-material/DarkMode';
import eventBus from '../utils/EventBus';
import { setDarkMode } from '../redux/darkMode';
import { setUser } from '../redux/user';

import { useDispatch, useSelector } from "react-redux";
import Fade from '@mui/material/Fade';
import cookie from "react-cookies"
import UserService from '../services/UserService';
import { LocalShippingOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import SideBarElement from './SideBarElement';

const SideBar = () => {

    const navigate = useNavigate();
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const getTheme = useThemes()
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const isSmall = useMediaQuery(getTheme().breakpoints.down('sm_extra'));
    const isPhone = useMediaQuery(getTheme().breakpoints.down('phone'));
    const userService = new UserService();
    const currentMode = useSelector(state => state.darkMode)
    const [darkModeChecked, setDarkModeChecked] = useState(currentMode);
    const user = useSelector(state => state.user)


    const styles = {
        sidebar: {
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            borderRadius: 7,
            backgroundColor: currentMode ? 'black' : 'white',
            borderStyle: 'solid',
            minWidth: isSmall ? 70 : 90,
            minHeight: user.type === ROLES.SUPER_ADMIN ? windowSize.current[1] / 1.5 : windowSize.current[1] / 1.65,
            borderColor: '#EAEEF7',
            borderWidth: '2px'
        },
        button: {
            minWidth: isSmall ? 45 : 55,
            minHeight: isSmall ? 45 : 55,
            backgroundColor: currentMode ? DEFAULT_THEME.palette.sideBarDark : DEFAULT_THEME.palette.sideBar,
            borderRadius: 3,
            '&:hover': {
                backgroundColor: '#ccdbdc',
                color: 'black',
                cursor: 'pointer',
            },
        },
        buttonLogout: {
            minWidth: isSmall ? 25 : 40,
            minHeight: isSmall ? 25 : 40,
            backgroundColor: currentMode ? DEFAULT_THEME.palette.sideBarDark : DEFAULT_THEME.palette.sideBar,
            borderRadius: 3,
            '&:hover': {
                backgroundColor: '#EFDF00',
                color: 'black',
                cursor: 'pointer',
            },
        },
        icon: {
            color: !currentMode ? '#000000' : '',
            height: '30px',
            width: '30px'
        },
        //theme: { transform: [{ rotate: '90deg' }] },
        infoText: {
            fontSize: 8, width: isSmall ? 35 : 40, color: !currentMode ? '#000000' : '',
            mb: '2px', mt: '5px', fontWeight: 'bold'
        },
        themeButton: {
            backgroundColor: currentMode ? DEFAULT_THEME.palette.sideBarDark : DEFAULT_THEME.palette.sideBar,
            color: !currentMode ? 'black' : '',
            borderRadius: 10,
            minHeight: 45,
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            minWidth: 45,
            '&:hover': {
                backgroundColor: '#EFDF00',
                color: 'black'
            },
        },
        tooltip: {
            backgroundColor: currentMode ? DEFAULT_THEME.palette.super_primary : 'black',
        },
        tooltipText: {
            color: currentMode ? 'black' : 'white',
            fontWeight: 600
        }
    }
    const logOut = async () => {
        const result = await userService.logOut()
        dispatch(setUser(""))
        console.log(result)
        navigate(ROUTES.INDEX)
    }

    const fields = [
        {
            text: t('mainMenu'),
            children: <GridViewIcon sx={{ ...styles.icon }} />,
            route: ROUTES.USER_MENU,
            // all roles can see this 
            roles: Object.values(ROLES)
        },
        {
            text: t('userManagement'),
            children: <PeopleIcon sx={{ ...styles.icon }} />,
            route: ROUTES.USER_SETTINGS,
            roles: [ROLES.SUPER_ADMIN, ROLES.AUTHORIZED_USER]
        },
        {
            text: t('reporting'),
            children: <AssessmentIcon sx={{ ...styles.icon }} />,
            route: ROUTES.REPORTING_MENU,
            roles: [ROLES.SUPER_ADMIN, ROLES.AUTHORIZED_USER]
        },
        {
            text: t('visitingOperations'),
            children: <EventAvailableIcon sx={{ ...styles.icon }} />,
            route: ROUTES.VISITING_OPERATIONS_MENU,
            roles: [ROLES.SUPER_ADMIN, ROLES.AUTHORIZED_USER, ROLES.OPERATION_RESPONSIBLE, ROLES.SECURITY_GUARD, ROLES.STANDARD_USER, ROLES.PARTNER_COMPANY_AUTHORIZED_USER]
        },
        {
            text: t('freightOperations'),
            children: <LocalShippingOutlinedIcon sx={{ ...styles.icon }} />,
            route: ROUTES.FREIGHT_HANDLING_MENU,
            roles: [ROLES.SUPER_ADMIN, ROLES.AUTHORIZED_USER, ROLES.OPERATION_RESPONSIBLE, ROLES.SECURITY_GUARD, ROLES.PARTNER_COMPANY_AUTHORIZED_USER]
        },
        {
            text: t('logout'),
            children: <LogoutIcon sx={{ ...styles.icon }} />,
            route: logOut,
            // all roles can see this 
            roles: Object.values(ROLES)
        },
    ]

    const filteredFields = fields.filter(field => field.roles.includes(user.type))

    return (
        <Grid container item sx={{ ...styles.sidebar }} justifyContent='center' alignItems='center' alignContent='center'>
            {
                filteredFields.map((field, index) => {
                    return (
                        <SideBarElement
                            key={index}
                            field={field}
                            styles={styles}
                            onClick={index === filteredFields.length - 1 ? field.route : () => navigate(field.route)}
                        />
                    )
                })
            }
        </Grid>
    )
}

export default SideBar;

