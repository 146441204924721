import { ENDPOINTS, TRACKING_TYPES } from "../utils/constants"
import axios from "../utils/interceptor"

export default class TrackingService {

    constructor() {
        this.endpoint = ENDPOINTS.FREIGHT_AREAS
    }

    getFreightAreas = async (params) => {

        try {
            // Request
            let result = await axios.get(this.endpoint.BASE, { params }, { urlsToClear: this.endpoint.BASE })

            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            //Return
            return result.data

        } catch (e) {
            return Promise.reject(e)
        }
    }
    updateFreightAreas = async (id, body) => {

        let url = `${this.endpoint.BASE}/${id}`

        try {
            // Request
            let result = await axios.patch(url, body, { urlsToClear: this.endpoint.BASE })

            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            //Return
            return result.data

        } catch (e) {
            return Promise.reject(e)
        }
    }
    createFreightArea = async (body) => {

        let url = this.endpoint.BASE

        try {
            // Request
            let result = await axios.post(url, body, { urlsToClear: this.endpoint.BASE })

            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            //Return
            return result.data

        } catch (e) {
            return Promise.reject(e)
        }
    }

    deleteFreightArea = async (id) => {

        let url = `${this.endpoint.BASE}/${id}`

        try {
            // Request
            let result = await axios.delete(url, { urlsToClear: this.endpoint.BASE })

            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            //Return
            return result.data

        } catch (e) {
            return Promise.reject(e)
        }
    }
}