
//REACT
import { useTranslation } from 'react-i18next';
import RenewPassword from './RenewPassword';
import ResetPassword from './ResetPassword';
import { useDispatch } from 'react-redux';
import { hidePopup, setMessageAndButtonText } from '../../redux/popup';
import { ROUTES } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Grid } from '@mui/material';



const ForgotPassword = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const navigate = useNavigate()

    const isValidJWT = () => {
        const urlParams = new URLSearchParams(window.location.search);

        const token = urlParams.get('token');

        // jwt token validation
        const isValidJWTRegex = /^[a-zA-Z0-9-_]+\.[a-zA-Z0-9-_]+\.[a-zA-Z0-9-_]+$/

        // return isValidJWTRegex.test(token)
        if (token && isValidJWTRegex.test(token)) {
            // setToken(tokenFromParams)
            return { status: true, token }
        } else if (token && !isValidJWTRegex.test(token)) {
            dispatch(setMessageAndButtonText({
                title: t('popup.invalidToken.title'),
                text: t('popup.invalidToken.text'),
                noButtonText: t('popup.invalidToken.button'),
                noButtonAction: () => { dispatch(hidePopup()); navigate(ROUTES.INDEX) },
                isErrorMessage: true
            }))
        }
        return false
    }

    return (
        <>
            {
                isValidJWT().status ?
                    <RenewPassword token={isValidJWT().token} />
                    :
                    <ResetPassword />
            }
        </>
    )
}



export default ForgotPassword;

